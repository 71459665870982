import * as R from "ramda";
import { useEffect, useMemo } from "react";
import { useIntl, useNewNavigation, useUserContext } from "hooks/index.js";
import { useLocalStorageState } from "hooks/index.js";
import messages from "./messages.js";
import { isToday } from "date-fns";
import cookies from "js-cookie";
import { useAlert } from "@cjdev-internal/visual-stack-x/Alert.js";

const isBroken = R.propEq("authenticationStatus", "BROKEN");
const isAnyBroken = R.any(isBroken);

export const ReconnectAuthenticationAlert = ({ properties }) => {
  const { use2022Nav } = useNewNavigation();
  const { user } = useUserContext();
  const { formatMessage } = useIntl();
  const [mount, showAlert, closeAlert] = useAlert();
  const companyId = use2022Nav
    ? user.currentCompany.id
    : cookies.get("jsCompanyId");
  const [lastShownDate, setLastShownDate] = useLocalStorageState(
    `reconnect-authentication-alert-last-date/${companyId}`
  );

  const reconnectAlertProps = useMemo(
    () => ({
      type: "warning",
      closeType: "icon",
      children: formatMessage(messages.message),
      onClose: closeAlert,
    }),
    [closeAlert, formatMessage]
  );

  useEffect(() => {
    if (isAnyBroken(properties) && !isToday(new Date(lastShownDate))) {
      showAlert(reconnectAlertProps);
      setLastShownDate(new Date());
    }
  }, [
    properties,
    showAlert,
    lastShownDate,
    setLastShownDate,
    reconnectAlertProps,
  ]);

  return mount;
};
