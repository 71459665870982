import { useQuery } from "@tanstack/react-query";
import { fetchPubDevCreator } from "api";

export const usePubDevCreators = (influencerToken, enabled = true) => {
  const query = useQuery({
    queryKey: ["pubdev-creators", influencerToken],
    queryFn: () => fetchPubDevCreator(influencerToken),
    enabled,
    refetchOnWindowFocus: false,
  });
  // work around bad design decision in React Query 4
  return {
    ...query,
    isLoading: query.isLoading && enabled,
  };
};
