import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import { InfluencerSignup } from "views/InfluencerSignup";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import messages from "../../translations";
import { Authorization } from "components/Authorization";

export const InfluencerSignupApp = () => {
  const [language, setLanguage] = useState(
    new URLSearchParams(window.location.search).get("locale") || "en"
  );
  const queryClient = new QueryClient();

  return (
    <IntlProvider
      locale={language}
      messages={messages[language]}
      defaultLocale="en"
    >
      <QueryClientProvider client={queryClient}>
        <Authorization>
          <InfluencerSignup
            language={language}
            onLanguageChange={setLanguage}
          />
        </Authorization>
      </QueryClientProvider>
    </IntlProvider>
  );
};
