import { defineMessages } from "react-intl";

export default defineMessages({
  servicesAndToolsLabel: {
    id: "accounts.PropertyTypes.servicesAndToolsLabel",
    defaultMessage: "Services and Tools",
  },
  servicesAndToolsHelp: {
    id: "accounts.PropertyTypes.servicesAndToolsHelp",
    defaultMessage:
      "My customers view my content through some technology that is not a mobile or browser app, such as a desktop app, hardware integration, or some other piece of technology.",
  },
  servicesAndToolsNumberUniqueUsers: {
    id: "accounts.PropertyTypes.servicesAndToolsNumberUniqueUsers",
    defaultMessage: "Number of Unique Users",
  },
  servicesAndToolsNumberUniqueUsersPlaceholder: {
    id: "accounts.PropertyTypes.servicesAndToolsNumberUniqueUsersPlaceholder",
    defaultMessage: "Enter number",
  },
  servicesAndToolsMarketingSiteUrl: {
    id: "accounts.PropertyTypes.servicesAndToolsMarketingSiteUrl",
    defaultMessage:
      "Link to Marketing Site (Must start with http:// or https://)",
  },
  servicesAndToolsMarketingSiteUrlPlaceholder: {
    id: "accounts.PropertyTypes.servicesAndToolsMarketingSiteUrlPlaceholder",
    defaultMessage: "http(s)://",
  },
});
