import { Modal } from "@cjdev-internal/visual-stack-x/Modal.js";
import { Button } from "@cjdev-internal/visual-stack-x/Button.js";
import { Row } from "@cjdev-internal/visual-stack-x/Row.js";
import { Stack } from "@cjdev-internal/visual-stack-x/Stack.js";
import { Text } from "@cjdev-internal/visual-stack-x/Text.js";

import React from "react";
import * as R from "ramda";
import messages from "./messages.js";
import { Link } from "components/Link/index.js";
import { PROPERTY_VERIFICATION } from "paths.js";
import { WebsiteIcon } from "components/WebsiteIcon/index.js";
import { SocialIcon } from "components/SocialIcon/index.js";
import authMessage from "img/auth-message.gif";
import { useIntl } from "hooks/index.js";

export const AuthenticateModal = ({ closeModal, property }) => {
  const { formatMessage } = useIntl();
  const propertyType = property?.propertyTypeDetails?.type;
  const name = property?.name;
  const handle = property?.propertyTypeDetails?.socialMediaHandle;
  const platform = property?.propertyTypeDetails?.socialMediaPlatform;
  const authenticationStatus = property?.authenticationStatus;
  const authenticationDate = property?.authenticationDate;
  const isAuthenticated =
    !R.isNil(authenticationDate) && authenticationStatus === "AUTHENTICATED";

  const headerTitle = isAuthenticated
    ? formatMessage(messages.authenticatedtitle)
    : formatMessage(messages.notAuthenticatedtitle);

  const authenticateModalProps = {
    onBackgroundClick: closeModal,
    onEscapeKeyUp: closeModal,
    headerTitle: headerTitle,
    onCloseIconClick: closeModal,
    footer: (
      <Row gap="medium" justify="end">
        <Button type="tertiary" onClick={closeModal}>
          {formatMessage(messages.cancel)}
        </Button>
        <Link
          to={{
            pathname: `${PROPERTY_VERIFICATION}`,
            state: { publisherId: property.publisherId },
          }}
          rel="noopener noreferrer"
        >
          <Button type="primary">
            {isAuthenticated
              ? formatMessage(messages.editConnection)
              : formatMessage(messages.connectProperty)}
          </Button>
        </Link>
      </Row>
    ),
  };

  return (
    <>
      <Modal {...authenticateModalProps}>
        <Stack gap="xl" paddingBottom="3xl">
          <Row>
            {propertyType === "WEBSITE" && (
              <Text size={16} weight="medium" data-testid="property-name">
                <Row align="end" gap="small" className="social-icon-wrapper">
                  <WebsiteIcon />
                  {name}
                </Row>
              </Text>
            )}
            {propertyType === "SOCIAL_MEDIA" && (
              <Text size={16} weight="medium" data-testid="property-handle">
                <Row align="center" gap="small" className="social-icon-wrapper">
                  <SocialIcon platform={platform} />
                  {handle}
                </Row>
              </Text>
            )}
          </Row>
          <Row>
            <Text>{formatMessage(messages.description)}</Text>
          </Row>
          <Row>
            <a
              href="https://members.cj.com/member/contactSupport.cj?id=kA16O000000oLo7"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text color="link">{formatMessage(messages.learnMore)}</Text>
            </a>
          </Row>
          <Row>
            <img
              src={authMessage}
              width="100%"
              alt={formatMessage(messages.learnMore)}
            />
          </Row>
        </Stack>
      </Modal>
    </>
  );
};
