import { defineMessages } from "react-intl";

export default defineMessages({
  paidDisplayAdsLabel: {
    id: "accounts.PropertyTypes.paidDisplayAdsLabel",
    defaultMessage: "Paid Display Ads",
  },
  paidDisplayAdsHelp: {
    id: "accounts.PropertyTypes.paidDisplayAdsHelp",
    defaultMessage:
      "My customers view display ads I purchase through a third party exchange, such as retargeting or realtime bidding.",
  },
  paidDisplayAdsPlatform: {
    id: "accounts.PropertyTypes.paidDisplayAdsPlatform",
    defaultMessage: "Platform",
  },
  paidDisplayAdsPlatformPlaceholder: {
    id: "accounts.PropertyTypes.paidDisplayAdsPlatformPlaceholder",
    defaultMessage: "Enter text",
  },
  paidDisplayAdsClickThroughRate: {
    id: "accounts.PropertyTypes.paidDisplayAdsClickThroughRate",
    defaultMessage: "Click Through Rate (example, 1 is 1%)",
  },
  paidDisplayAdsClickThroughRatePlaceholder: {
    id: "accounts.PropertyTypes.paidDisplayAdsClickThroughRatePlaceholder",
    defaultMessage: "Enter rate",
  },
  paidDisplayAdsDescription: {
    id: "accounts.PropertyTypes.paidDisplayAdsDescription",
    defaultMessage: "Description",
  },
  paidDisplayAdsDescriptionHelp: {
    id: "accounts.PropertyTypes.paidDisplayAdsDescriptionHelp",
    defaultMessage:
      "Describe the platforms where you purchase ads and what kind of click through rates your ads achieve.",
  },
  paidDisplayAdsDescriptionPlaceholder: {
    id: "accounts.PropertyTypes.paidDisplayAdsDescriptionPlaceholder",
    defaultMessage: "Enter description",
  },
});
