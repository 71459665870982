import "./styles.css";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import {
  FormPageLayout,
  FormPageBlock,
} from "@cjdev-internal/visual-stack-x/FormPageLayout";
import { Button } from "@cjdev-internal/visual-stack-x/Button";
import { ErrorPage } from "components/ErrorPage";
import { Stack } from "@cjdev-internal/visual-stack-x/Stack";
import { Spinner } from "@cjdev-internal/visual-stack-x/Spinner";
import { Row } from "@cjdev-internal/visual-stack-x/Row";
import { useIntl } from "react-intl";
import messages from "./messages.js";
import { useState } from "react";
import { resendEmail } from "./api.js";
import { MDIcon } from "@cjdev-internal/visual-stack-x/MDIcon.js";
import { InfluencerSignupLayout } from "components/InfluencerSignupLayout";
import { InfluencerSignupHeader } from "components/InfluencerSignupHeader";
import { missing } from "utils/util";

const startOverUrl = () => window.history.back(-2);

const badRequest = (message) => {
  return <ErrorPage errorCode={400} messageOverride={message} />;
};

export const EmailConfirmation = () => {
  const [resendStatus, setResendStatus] = useState("");

  const resend = () => {
    setResendStatus("sending");
    resendEmail(emailAddress, token)
      .then((res) => {
        // prettier-ignore
        const status = res.ok  ? "sendSuccess"
          : res.status === 400 ? "sendTokenExpired"
          : "sendError";

        setResendStatus(status);
      })
      .catch(() => setResendStatus("sendError"));
  };
  const { formatMessage } = useIntl();

  const queryParams = new URLSearchParams(window.location.search);
  const advertiserId = queryParams.get("advertiserId");
  const token = queryParams.get("token");
  const influencerToken = queryParams.get("influencerToken");
  const emailAddress = decodeURIComponent(queryParams.get("email"));

  if (missing(emailAddress)) {
    return badRequest("No email address");
  }

  // prettier-ignore
  const resendStatusMessage =
    resendStatus === "" ? ( "" ) 
    : resendStatus === "sending" ? (<Spinner size="small" />)
    : (formatMessage(messages[resendStatus], { emailAddress })
    );

  return (
    <InfluencerSignupLayout
      advertiserId={advertiserId}
      influencerToken={influencerToken}
    >
      <Stack gap="xl" className="email-confirmation-page">
        <InfluencerSignupHeader
          title={formatMessage(messages.start)}
          subtitle={formatMessage(messages.easy)}
        />
        <FormPageLayout>
          <FormPageBlock>
            <Row gap="small" align="center" justify="center">
              <MDIcon size={25} icon="email-fast-outline"></MDIcon>
              &nbsp;
              <Text size="18">{formatMessage(messages.sent)}</Text>
            </Row>
          </FormPageBlock>
          <FormPageBlock>
            <Stack align="center" gap="medium">
              <Text>
                {formatMessage(messages.justSent)}{" "}
                <Text weight="bold">{emailAddress}</Text>.
              </Text>
              <Text>{formatMessage(messages.confirm)}</Text>
            </Stack>
          </FormPageBlock>
          <FormPageBlock>
            <Stack align="center" gap="medium">
              <Text>{formatMessage(messages.notReceived)}</Text>
              <Button id="resend-btn" onClick={resend} type="primary">
                {formatMessage(messages.resend)}
              </Button>
              <Text id="sendStatus">{resendStatusMessage}</Text>
            </Stack>
          </FormPageBlock>
          <FormPageBlock>
            <Stack align="center" gap="medium">
              <Text>{formatMessage(messages.wrong)}</Text>
              <Button onClick={startOverUrl} type="link">
                {formatMessage(messages.restart)}
              </Button>
            </Stack>
          </FormPageBlock>
        </FormPageLayout>
      </Stack>
    </InfluencerSignupLayout>
  );
};
