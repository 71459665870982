import React from "react";
import { ErrorBoundary } from "components/ErrorBoundary";
import {
  ApolloProvider,
  ErrorNotificationProvider,
  NotificationProvider,
  UserProvider,
  NavigationProvider,
  AuthenticationProvider,
} from "../context";
import { HistoryProvider } from "context/HistoryContext";
import { useNavigate } from "@platform/cj-platform-navigation";

export const NavigationViewProvider = ({ children, user }) => {
  const history = useNavigate();

  return (
    <NavigationProvider use2022Nav={true}>
      <UserProvider user={user}>
        <AuthenticationProvider>
          <ApolloProvider>
            <NotificationProvider>
              <ErrorNotificationProvider>
                <HistoryProvider history={history}>
                  <ErrorBoundary>{children}</ErrorBoundary>
                </HistoryProvider>
              </ErrorNotificationProvider>
            </NotificationProvider>
          </ApolloProvider>
        </AuthenticationProvider>
      </UserProvider>
    </NavigationProvider>
  );
};
