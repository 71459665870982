import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "accounts.ViewPromotionalProperties.title",
    defaultMessage: "Promotional Property Manager",
  },
  create: {
    id: "accounts.ViewPromotionalProperties.create",
    defaultMessage: "Create Property",
  },
  tableTitle: {
    id: "accounts.ViewPromotionalProperties.tableTitle",
    defaultMessage: "Promotional Properties",
  },
  propertyName: {
    id: "accounts.ViewPromotionalProperties.propertyName",
    defaultMessage: "Property Name",
  },
  propertyId: {
    id: "accounts.ViewPromotionalProperties.propertyId",
    defaultMessage: "Property ID",
  },
  status: {
    id: "accounts.ViewPromotionalProperties.status",
    defaultMessage: "Status",
  },
  type: {
    id: "accounts.ViewPromotionalProperties.type",
    defaultMessage: "Type",
  },
  authentication: {
    id: "accounts.ViewPromotionalProperties.authentication",
    defaultMessage: "Authentication",
  },
  primaryModel: {
    id: "accounts.ViewPromotionalProperties.primaryModel",
    defaultMessage: "Primary Model",
  },
  numberOfModels: {
    id: "accounts.ViewPromotionalProperties.numberOfModels",
    defaultMessage: "# of Models",
  },
  lastUpdated: {
    id: "accounts.ViewPromotionalProperties.lastUpdated",
    defaultMessage: "Last Updated",
  },
  actions: {
    id: "accounts.ViewPromotionalProperties.actions",
    defaultMessage: "Actions",
  },
  STATUS_ALL: {
    id: "accounts.ViewPromotionalProperties.STATUS_ALL",
    defaultMessage: "All",
  },
  STATUS_ACTIVE: {
    id: "accounts.ViewPromotionalProperties.STATUS_ACTIVE",
    defaultMessage: "Active",
  },
  STATUS_ARCHIVED: {
    id: "accounts.ViewPromotionalProperties.STATUS_ARCHIVED",
    defaultMessage: "Archived",
  },
  error: {
    id: "accounts.ViewPromotionalProperties.error",
    defaultMessage: "Something went wrong",
  },
  placeholder: {
    id: "accounts.ViewPromotionalProperties.filerPlaceholder",
    defaultMessage: "Search in Results",
  },
  LOADING_PROMOTIONAL_PROPERTIES: {
    id: "accounts.ViewPromotionalProperties.loadingPromotionalProperties",
    defaultMessage: "Loading...",
  },
  NO_DATA_AVAILABLE: {
    id: "accounts.ViewPromotionalProperties.noDataAvailable",
    defaultMessage: "No data available.",
  },
  rowsPerPageTemplate: {
    id: "accounts.ViewPromotionalProperties.rowsPerPageTemplate",
    defaultMessage: "{0} per page",
  },
  totalRecordsTemplate: {
    id: "accounts.ViewPromotionalProperties.totalRecordsTemplate",
    defaultMessage: "{0} total records",
  },
  learnMoreApi: {
    id: "accounts.ViewPromotionalProperties.learnMoreApi",
    defaultMessage: "Learn more about the Bulk PID Management API",
  },
  guideMe: {
    id: "accounts.ViewPromotionalProperties.guideMe",
    defaultMessage: "Guide Me",
  },
  authenticationStatusTitle: {
    id: "accounts.ViewPromotionalProperties.authenticationStatusTitle",
    defaultMessage: "Authentication status: Pending",
  },
  authenticationStatusBody: {
    id: "accounts.ViewPromotionalProperties.authenticationStatusBody",
    defaultMessage:
      "The property authentication sync may take a few minutes to complete. If you do not see an updated authentication status for your property, refresh the page or check the status in a few minutes.",
  },
});
