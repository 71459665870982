export const isCiqPlatform = (socialPlatform) =>
  [
    "INSTAGRAM",
    "FACEBOOK",
    "YOUTUBE",
    "TWITTER",
    "PINTEREST",
    "TWITCH",
    "TIKTOK",
  ].includes(socialPlatform);
