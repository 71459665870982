import { defineMessages } from "react-intl";

export default defineMessages({
  program: {
    id: "accounts.EmailConfirmation.program",
    defaultMessage: "Affiliate Program",
  },
  sent: {
    id: "accounts.EmailConfirmation.sent",
    defaultMessage: "Email Sent",
  },
  justSent: {
    id: "accounts.EmailConfirmation.justSent",
    defaultMessage: "We just sent a verification email to",
  },
  confirm: {
    id: "accounts.EmailConfirmation.confirm",
    defaultMessage:
      "Click the link in the email to confirm your email address.",
  },
  notReceived: {
    id: "accounts.EmailConfirmation.notReceived",
    defaultMessage: "Didn't get the confirmation email?",
  },
  resend: {
    id: "accounts.EmailConfirmation.resend",
    defaultMessage: "Resend Email",
  },
  wrong: {
    id: "accounts.EmailConfirmation.wrong",
    defaultMessage: "Wrong email address?",
  },
  restart: {
    id: "accounts.EmailConfirmation.restart",
    defaultMessage: "Start over",
  },
  learn: {
    id: "accounts.EmailConfirmation.learn",
    defaultMessage: "Learn more",
  },
  joining: {
    id: "accounts.EmailConfirmation.joining",
    defaultMessage: "By joining the {programName} Affiliate Program",
  },
  byJoining: {
    id: "accounts.EmailConfirmation.byJoining",
    defaultMessage: "By joining the {0} Affiliate Program",
  },
  signing: {
    id: "accounts.EmailConfirmation.signing",
    defaultMessage: "You are signing up for a CJ Affiliate Publisher Account",
  },
  privacy: {
    id: "accounts.EmailConfirmation.privacy",
    defaultMessage: "Privacy Policy",
  },
  support: {
    id: "accounts.EmailConfirmation.support",
    defaultMessage: "Support Center",
  },
  sending: {
    id: "accounts.EmailConfirmation.resend.sending",
    defaultMessage: "Resending to {emailAddress}...",
  },
  sendSuccess: {
    id: "accounts.EmailConfirmation.resend.success",
    defaultMessage: "Sent email to {emailAddress}.",
  },
  sendError: {
    id: "accounts.EmailConfirmation.resend.error",
    defaultMessage: "Could not send email to {emailAddress} due to an error.",
  },
  sendTokenExpired: {
    id: "accounts.EmailConfirmation.resend.tokenExpired",
    defaultMessage:
      "Could not send email to {emailAddress} because the token has expired.",
  },
  start: {
    id: "accounts.EmailConfirmation.resend.start",
    defaultMessage: "Start your free account now!",
  },
  easy: {
    id: "accounts.EmailConfirmation.resend.easy",
    defaultMessage:
      "You are just a few easy steps from joining the thousands of amazing creators like you!",
  },
});
