import { useQuery } from "@tanstack/react-query";
import { decryptInfluencerToken } from "api";

export const useDecryptInfluencerToken = (influencerToken, advertiserId) =>
  useQuery({
    queryKey: ["decrypt-influencer-token", influencerToken],
    queryFn: () => decryptInfluencerToken(influencerToken),
    enabled: !!influencerToken && !advertiserId,
    refetchOnWindowFocus: false,
  });
