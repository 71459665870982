import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import CopyPromotionalProperty from "../../views/CopyPromotionalProperty";
import CreatePromotionalProperty from "../../views/CreatePromotionalProperty";
import EditPromotionalPropertyView from "../../views/EditPromotionalProperty";
import OauthCallbackComponent from "../OauthCallbackComponent";
import Pendo from "../Pendo";
import ViewPromotionalProperties from "../../views/ViewPromotionalProperties";
import {
  INFLUENCER_DESCRIPTION,
  INFLUENCER_ONBOARDING,
  INFLUENCER_USER_COMPANY,
  PROMOTIONAL_PROPERTIES,
} from "../../paths";
import { PropertyVerificationPage } from "../../views/PropertyVerificationPage";
import "./styles.css";
import { LegacyViewProvider } from "context/LegacyViewProvider";
import { PropertyImportWrapperLegacyNav } from "../../views/InfluencerWizard/PropertyImport/index.js";
import { InfluencerDescriptionWrapper } from "views/InfluencerWizard/InfluencerDescription";
import { InfluencerUserCompanyWrapper } from "views/InfluencerWizard/InfluencerUserCompanyPage";

export const App = () => {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to={PROMOTIONAL_PROPERTIES} />}
        />
        <Route
          exact
          path="/oauth-callback"
          render={() => <OauthCallbackComponent />}
        />
        <Route
          path={PROMOTIONAL_PROPERTIES}
          render={({ match: { url } }) => (
            <LegacyViewProvider>
              <Pendo />
              <Route
                exact
                path={`${url}`}
                render={() => <ViewPromotionalProperties />}
              />

              <Route
                exact
                path={`${url}/create`}
                render={() => <CreatePromotionalProperty />}
              />
              <Route
                exact
                path={`${url}/edit/:id`}
                render={({ match }) => (
                  <EditPromotionalPropertyView id={parseInt(match.params.id)} />
                )}
              />
              <Route
                exact
                path={`${url}/copy/:id`}
                render={({ match }) => (
                  <CopyPromotionalProperty id={parseInt(match.params.id)} />
                )}
              />
              <Route
                exact
                path={`${url}/property-verification`}
                render={() => <PropertyVerificationPage />}
              />
            </LegacyViewProvider>
          )}
        />
        <Route
          path={INFLUENCER_ONBOARDING}
          exact
          render={() => {
            const queryParams = new URLSearchParams(window.location.search);
            const influencerToken = queryParams.get("influencerToken");

            return (
              <LegacyViewProvider>
                <PropertyImportWrapperLegacyNav
                  influencerToken={influencerToken}
                />
              </LegacyViewProvider>
            );
          }}
        />
        <Route
          path={INFLUENCER_DESCRIPTION}
          exact
          render={() => {
            const queryParams = new URLSearchParams(window.location.search);
            const influencerToken = queryParams.get("influencerToken");

            return (
              <LegacyViewProvider>
                <InfluencerDescriptionWrapper
                  influencerToken={influencerToken}
                />
              </LegacyViewProvider>
            );
          }}
        />
        <Route
          path={INFLUENCER_USER_COMPANY}
          exact
          render={() => {
            const queryParams = new URLSearchParams(window.location.search);
            const influencerToken = queryParams.get("influencerToken");

            return (
              <LegacyViewProvider>
                <InfluencerUserCompanyWrapper
                  influencerToken={influencerToken}
                />
              </LegacyViewProvider>
            );
          }}
        />
      </Switch>
    </Router>
  );
};
