import React, { createContext } from "react";

export const HistoryContext = createContext({
  historyContext: {},
  providerInitialized: false,
});

export const HistoryProvider = ({ children, history }) => {
  return (
    <HistoryContext.Provider
      value={{
        historyContext: history,
        providerInitialized: true,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};
