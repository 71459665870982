import FacebookIcon from "img/icon-facebook.svg";
import PinterestIcon from "img/icon-pinterest.svg";
import SnapchatIcon from "img/icon-snapchat.svg";
import TwitchIcon from "img/icon-twitch.svg";
import TwitterIcon from "img/icon-twitter.svg";
import YoutubeIcon from "img/icon-youtube.svg";
import InstagramIcon from "img/icon-instagram.svg";
import ReactSVG from "react-svg";
import React from "react";
import "./style.css";

const platformToIconMap = {
  FACEBOOK: FacebookIcon,
  INSTAGRAM: InstagramIcon,
  PINTEREST: PinterestIcon,
  SNAPCHAT: SnapchatIcon,
  TWITCH: TwitchIcon,
  TWITTER: TwitterIcon,
  YOUTUBE: YoutubeIcon,
};

export const SocialIcon = ({ platform }) => {
  const svgIcon = platformToIconMap[platform];

  return <span>{svgIcon ? <ReactSVG src={svgIcon} /> : null}</span>;
};
