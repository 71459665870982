import { defineMessages } from "react-intl";

export default defineMessages({
  emailLabel: {
    id: "accounts.PropertyTypes.emailLabel",
    defaultMessage: "Email",
  },
  emailHelp: {
    id: "accounts.PropertyTypes.emailHelp",
    defaultMessage:
      "My customers view content I send to them in email campaigns or newsletters.",
  },
  email: {
    id: "accounts.PropertyTypes.email",
    defaultMessage: "Send-From Email Address",
  },
  emailPlaceholder: {
    id: "accounts.PropertyTypes.emailPlaceholder",
    defaultMessage: "user@example.com",
  },
  emailMailingListSize: {
    id: "accounts.PropertyTypes.emailMailingListSize",
    defaultMessage: "Size of Mailing List",
  },
  emailMailingListSizePlaceholder: {
    id: "accounts.PropertyTypes.emailMailingListSizePlaceholder",
    defaultMessage: "Enter size",
  },
  emailOpenRatePlaceholder: {
    id: "accounts.PropertyTypes.emailOpenRatePlaceholder",
    defaultMessage: "Enter rate",
  },
  emailOpenRate: {
    id: "accounts.PropertyTypes.emailOpenRate",
    defaultMessage: "Open Rate (example, 1 is 1%)",
  },
});
