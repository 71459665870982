import React from "react";
import {
  BlankSlate,
  Description,
} from "@cjdev-internal/visual-stack-x/BlankSlate.js";
import { useIntl } from "hooks/index.js";
import BlankSlateLogo from "./BlankSlateLogo.js";
import * as R from "ramda";
import messages from "./messages.js";

export default ({ renderAction }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="view-promotional-properties">
      <BlankSlate
        data-testid="blank-slate"
        title={formatMessage(messages.BLANK_SLATE_TITLE)}
        headerGraphic={<BlankSlateLogo />}
      >
        <Description>
          {formatMessage(messages.BLANK_SLATE_DESCRIPTION)}
        </Description>
        {!R.isNil(renderAction) && renderAction}
      </BlankSlate>
    </div>
  );
};
