import { defineMessages } from "react-intl";

export default defineMessages({
  promotionalProperties: {
    id: "accounts.promotionalProperties",
    defaultMessage: "Promotional Properties",
  },
  createPromotionalProperty: {
    id: "accounts.createPromotionalProperty",
    defaultMessage: "Create Promotional Property",
  },
  editPromotionalProperty: {
    id: "accounts.editPromotionalProperty",
    defaultMessage: "Edit Promotional Property",
  },
  copyPromotionalProperty: {
    id: "accounts.copyPromotionalProperty",
    defaultMessage: "Copy Promotional Property",
  },
  properyAuthentication: {
    id: "accounts.propertyAuthentication",
    defaultMessage: "Property Authentication",
  },
  onboardingChecklist: {
    id: "accounts.onboardingChecklist",
    defaultMessage: "Onboarding Checklist",
  },
});
