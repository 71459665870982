import { defineMessages } from "react-intl";

export default defineMessages({
  requiredFields: {
    id: "accounts.global.requiredFields",
    defaultMessage: "required fields",
  },
  back: {
    id: "accounts.global.back",
    defaultMessage: "Back",
  },
  continue: {
    id: "accounts.global.continue",
    defaultMessage: "Continue",
  },
  finish: {
    id: "accounts.global.finish",
    defaultMessage: "Finish",
  },
  selectPlaceholder: {
    id: "accounts.global.selectPlaceholder",
    defaultMessage: "Select",
  },
  requiredError: {
    id: "accounts.global.requiredError",
    defaultMessage: "This field is required.",
  },
  minLengthError: {
    id: "accounts.global.minLengthError",
    defaultMessage: "A minimum of {0} characters is required.",
  },
  maxLengthError: {
    id: "accounts.global.maxLengthError",
    defaultMessage: "A maximum of {0} characters is required.",
  },
  numbersError: {
    id: "accounts.global.numbersError",
    defaultMessage: "This field must contain only numbers.",
  },
  phoneNumberError: {
    id: "accounts.global.phoneNumberError",
    defaultMessage: "A valid phone number is required.",
  },
  influencerWizardTitle: {
    id: "accounts.global.influencerWizardTitle",
    defaultMessage: "Get started by completing 3 easy steps",
  },
});
