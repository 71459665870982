import React from "react";

export default () => {
  return (
    <svg
      width="296px"
      height="303px"
      viewBox="0 0 296 303"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group 4 Copy</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Layouts"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-4-Copy" transform="translate(-1.000000, -1.000000)">
          <g
            id="Group-3"
            transform="translate(148.968659, 152.728187) rotate(20.000000) translate(-148.968659, -152.728187) translate(34.468659, 32.728187)"
          >
            <rect
              id="Rectangle"
              strokeOpacity="0.16"
              stroke="#BABBBD"
              strokeWidth="2"
              fill="#FFFFFF"
              x="1"
              y="1"
              width="226.27044"
              height="237.779874"
              rx="3"
            ></rect>
            <g id="Group-2" transform="translate(21.100629, 21.100629)">
              <rect
                id="Rectangle"
                stroke="#D8DCDF"
                strokeWidth="2"
                fillOpacity="0"
                fill="#D8D8D8"
                x="1"
                y="1"
                width="187.90566"
                height="51.7106918"
              ></rect>
              <rect
                id="Rectangle"
                fill="#D8DCDF"
                x="0"
                y="69.0566038"
                width="189.90566"
                height="23.0188679"
              ></rect>
              <rect
                id="Rectangle"
                fill="#D8DCDF"
                x="0"
                y="107.421384"
                width="189.90566"
                height="23.0188679"
              ></rect>
              <rect
                id="Rectangle"
                fill="#D8DCDF"
                x="0"
                y="145.786164"
                width="189.90566"
                height="23.0188679"
              ></rect>
            </g>
          </g>
          <g
            id="Group-3"
            transform="translate(149.003648, 152.671734) rotate(10.000000) translate(-149.003648, -152.671734) translate(34.503648, 32.671734)"
          >
            <rect
              id="Rectangle"
              strokeOpacity="0.64"
              stroke="#BABBBD"
              strokeWidth="2"
              fill="#FFFFFF"
              x="1"
              y="1"
              width="226.27044"
              height="237.779874"
              rx="3"
            ></rect>
            <g id="Group-2" transform="translate(21.100629, 21.100629)">
              <rect
                id="Rectangle"
                stroke="#D8DCDF"
                strokeWidth="2"
                fillOpacity="0"
                fill="#D8D8D8"
                x="1"
                y="1"
                width="187.90566"
                height="51.7106918"
              ></rect>
              <rect
                id="Rectangle"
                fill="#D8DCDF"
                x="0"
                y="69.0566038"
                width="189.90566"
                height="23.0188679"
              ></rect>
              <rect
                id="Rectangle"
                fill="#D8DCDF"
                x="0"
                y="107.421384"
                width="189.90566"
                height="23.0188679"
              ></rect>
              <rect
                id="Rectangle"
                fill="#D8DCDF"
                x="0"
                y="145.786164"
                width="189.90566"
                height="23.0188679"
              ></rect>
            </g>
          </g>
          <g id="Group-3" transform="translate(34.528302, 32.610063)">
            <rect
              id="Rectangle"
              stroke="#BABBBD"
              strokeWidth="2"
              fill="#FFFFFF"
              x="1"
              y="1"
              width="226.27044"
              height="237.779874"
              rx="3"
            ></rect>
            <g id="Group-2" transform="translate(21.100629, 21.100629)">
              <rect
                id="Rectangle"
                stroke="#D8DCDF"
                strokeWidth="2"
                fillOpacity="0"
                fill="#D8D8D8"
                x="1"
                y="1"
                width="187.90566"
                height="51.7106918"
              ></rect>
              <g
                id="Icons/Content/Dollar"
                transform="translate(69.056604, 0.000000)"
                fill="#BABBBD"
              >
                <path
                  d="M27.2954752,25.4193309 C24.3320624,24.6491046 23.3790706,23.852769 23.3790706,22.6125742 C23.3790706,21.1896139 24.6975935,20.1974581 26.9038347,20.1974581 C29.2275681,20.1974581 30.0891771,21.307106 30.1675052,22.9389413 L33.05259,22.9389413 C32.9612072,20.693536 31.5904656,18.6308962 28.862037,17.9651074 L28.862037,15.1061321 L24.9456324,15.1061321 L24.9456324,17.9259434 C22.4130241,18.47424 20.3764937,20.11913 20.3764937,22.6386836 C20.3764937,25.6543152 22.869938,27.1556036 26.5121943,28.0302673 C29.7758648,28.8135482 30.4285989,29.9623602 30.4285989,31.1764457 C30.4285989,32.0772187 29.7889195,33.5132338 26.9038347,33.5132338 C24.2145702,33.5132338 23.157141,32.312203 23.0135395,30.7717505 L20.1415094,30.7717505 C20.2981656,33.6307259 22.4391335,35.2364518 24.9456324,35.7716937 L24.9456324,38.6045597 L28.862037,38.6045597 L28.862037,35.7978031 C31.4077,35.3147799 33.4311758,33.8396008 33.4311758,31.163391 C33.4311758,27.4558613 30.258888,26.1895571 27.2954752,25.4193309 Z"
                  id="Color"
                ></path>
              </g>
              <rect
                id="Rectangle"
                fill="#D8DCDF"
                x="0"
                y="69.0566038"
                width="189.90566"
                height="23.0188679"
              ></rect>
              <rect
                id="Rectangle"
                fill="#D8DCDF"
                x="0"
                y="107.421384"
                width="189.90566"
                height="23.0188679"
              ></rect>
              <rect
                id="Rectangle"
                fill="#D8DCDF"
                x="0"
                y="145.786164"
                width="189.90566"
                height="23.0188679"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
