import { defineMessages } from "react-intl";

export default defineMessages({
  websiteLabel: {
    id: "accounts.PropertyTypes.websiteLabel",
    defaultMessage: "Website",
  },
  websiteHelp: {
    id: "accounts.PropertyTypes.websiteHelp",
    defaultMessage:
      "My customers view my content on a website at a URL that can be viewed on a desktop or mobile browser.",
  },
  websiteUrl: {
    id: "accounts.PropertyTypes.websiteUrl",
    defaultMessage: "Website URL (Must start with http:// or https://)",
  },
});
