import { defineMessages } from "react-intl";

export default defineMessages({
  clone: {
    id: "accounts.PromotionalPropertyRow.clone",
    defaultMessage: "Clone",
  },
  primary: {
    id: "accounts.PromotionalPropertyRow.primary",
    defaultMessage: "Primary",
  },
  edit: {
    id: "accounts.PromotionalPropertyRow.edit",
    defaultMessage: "Edit",
  },
  connect: {
    id: "accounts.PromotionalPropertyRow.connect",
    defaultMessage: "Connect",
  },
  reconnect: {
    id: "accounts.PromotionalPropertyRow.reconnect",
    defaultMessage: "Reconnect",
  },
  connected: {
    id: "accounts.PromotionalPropertyRow.connected",
    defaultMessage: "Connected",
  },
  authenticatedAt: {
    id: "accounts.PromotionalPropertyRow.authenticatedAt",
    defaultMessage: "Authenticated at {date}",
  },
  handleNotFound: {
    id: "accounts.PromotionalPropertyRow.handleNotFound",
    defaultMessage:
      "Your property was not found. Check that you have entered it correctly and try again.",
  },
  STATUS_ACTIVE: {
    id: "accounts.PromotionalPropertyRow.STATUS_ACTIVE",
    defaultMessage: "Active",
  },
  STATUS_ARCHIVED: {
    id: "accounts.PromotionalPropertyRow.STATUS_ARCHIVED",
    defaultMessage: "Archived",
  },
  STATUS_TERMINATED: {
    id: "accounts.PromotionalPropertyRow.STATUS_TERMINATED",
    defaultMessage: "Terminated",
  },
  WEBSITE: {
    id: "accounts.PromotionalPropertyRow.WEBSITE",
    defaultMessage: "Website",
  },
  SOCIAL_MEDIA: {
    id: "accounts.PromotionalPropertyRow.SOCIAL_MEDIA",
    defaultMessage: "Social",
  },
  EMAIL: {
    id: "accounts.PromotionalPropertyRow.EMAIL",
    defaultMessage: "Email",
  },
  MOBILE_APP: {
    id: "accounts.PromotionalPropertyRow.MOBILE_APP",
    defaultMessage: "Mobile App",
  },
  BROWSER_EXTENSION: {
    id: "accounts.PromotionalPropertyRow.BROWSER_EXTENSION",
    defaultMessage: "Browser Extension",
  },
  SERVICES_AND_TOOLS: {
    id: "accounts.PromotionalPropertyRow.SERVICES_AND_TOOLS",
    defaultMessage: "Services and Tools",
  },
  PAID_SEARCH_ENGINE: {
    id: "accounts.PromotionalPropertyRow.PAID_SEARCH_ENGINE",
    defaultMessage: "Paid Search Engine Marketing (direct)",
  },
  PAID_DISPLAY_ADS: {
    id: "accounts.PromotionalPropertyRow.PAID_DISPLAY_ADS",
    defaultMessage: "Paid Display Ads",
  },
  CONTENT_BLOG_MEDIA: {
    id: "accounts.PromotionalPropertyRow.CONTENT_BLOG_MEDIA",
    defaultMessage: "Content/Blog/Media",
  },
  COUPON_DEAL: {
    id: "accounts.PromotionalPropertyRow.COUPON_DEAL",
    defaultMessage: "Coupon/Voucher/Deal",
  },
  LOYALTY_CASH_BACK: {
    id: "accounts.PromotionalPropertyRow.LOYALTY_CASH_BACK",
    defaultMessage: "Loyalty - Cash Back",
  },
  LOYALTY_NON_CASH_REWARDS: {
    id: "accounts.PromotionalPropertyRow.LOYALTY_NON_CASH_REWARDS",
    defaultMessage: "Loyalty - Non Cash Rewards",
  },
  LOYALTY_CLOSED_USER_GROUP: {
    id: "accounts.PromotionalPropertyRow.LOYALTY_CLOSED_USER_GROUP",
    defaultMessage: "Loyalty - Closed User Group",
  },
  TRADEMARK_BIDDING: {
    id: "accounts.PromotionalPropertyRow.TRADEMARK_BIDDING",
    defaultMessage: "Trademark Bidding",
  },
  INFLUENCER: {
    id: "accounts.PromotionalPropertyRow.INFLUENCER",
    defaultMessage: "Influencer",
  },
  PAY_PER_CALL: {
    id: "accounts.PromotionalPropertyRow.PAY_PER_CALL",
    defaultMessage: "Pay Per Call",
  },
  PRODUCT_COMPARISON_REVIEWS_DISCOVERY: {
    id: "accounts.PromotionalPropertyRow.PRODUCT_COMPARISON_REVIEWS_DISCOVERY",
    defaultMessage: "Product Comparison, Reviews, or Discovery",
  },
  OTHER: {
    id: "accounts.PromotionalPropertyRow.OTHER",
    defaultMessage: "Other",
  },
  manageAuthentication: {
    id: "accounts.PromotionalPropertyRow.manageAuthentication",
    defaultMessage: "Manage authentication",
  },
});
