import {
  FormPageBlock,
  FormPageLayout,
} from "@cjdev-internal/visual-stack-x/FormPageLayout";
import { Row } from "@cjdev-internal/visual-stack-x/Row";
import { HorizontalLine } from "components/HorizontalLine";
import {
  CJField,
  CJForm,
  useCJForm,
  SocialInput,
  CJFormSubheading,
} from "@cjdev-internal/visual-stack-x/CJForm";
import { Button, IconButton } from "@cjdev-internal/visual-stack-x/Button";
import { InfluencerSignupHeader } from "components/InfluencerSignupHeader";
import messages from "./messages";
import appMessages from "../../../components/App/messages";
import socialMessages from "../../../components/promotional_properties/PropertyTypes/SocialPropertyTypeWithCiq/messages";
import { socialMediaPlatformHelpTextParams } from "../../../components/promotional_properties/PropertyTypes/SocialPropertyTypeWithCiq/platformHelpTextParams.js";
import formMessages from "../../../components/promotional_properties/PromotionalPropertyForm/messages";
import websiteMessages from "../../../components/promotional_properties/PropertyTypes/WebsitePropertyType/messages.js";
import "./styles.css";
import { PropertyButtonWithDropdown } from "components/PropertyButtonWithDropdown";
import { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useToast } from "@cjdev-internal/visual-stack-x/Toast";
import { useFocus } from "./hooks/useFocus.js";
import {
  useCheckSocialAccount,
  FOUND,
  NOT_FOUND,
} from "./hooks/useCheckSocialAccount.js";
import {
  STATE_EDITED,
  STATE_CHECKING,
  STATE_NEW,
  STATE_DELETED,
  STATE_VALIDATED,
} from "../editingStates";
import { useSavePromotionalProperties } from "./hooks/useSavePromotionalProperties.js";
import { INFLUENCER_DESCRIPTION } from "paths";
import { Tip } from "components/Tip";
import { useCurrentCompany, useHistory, useIntl, useUserContext } from "hooks";
import { Stack } from "@cjdev-internal/visual-stack-x/Stack";
import globalMessages from "messages/globalMessages";
import { PropertyImportWrapper } from "./PropertyImportWrapper.js";
import { ErrorPage } from "../../../components/ErrorPage/index";
import authMessages from "../../../components/Authorization/messages";

export const isSocial = (property) => property.type === "Social";
export const isWebsite = (property) => property.type === "Website";

const isSocialAutoValidated = (property) =>
  ["whatsapp", "snapchat", "other"].includes(property.socialMediaPlatform);

const requiresCheckUrlButton = (property) => {
  return (
    (property.state === STATE_CHECKING || property.state === STATE_EDITED) &&
    isSocial(property) &&
    property.socialMediaHandle.trim().length > 0 &&
    !isSocialAutoValidated(property)
  );
};

const sortBySocialPlatforms = (propertiesForSignup) => {
  const websiteProperties = propertiesForSignup?.filter(isWebsite);
  const socialProperties = propertiesForSignup?.filter(isSocial);

  socialProperties?.sort((a, b) => {
    if (a.socialMediaPlatform !== b.socialMediaPlatform) {
      return a.socialMediaPlatform.localeCompare(b.socialMediaPlatform);
    } else {
      return a.socialMediaHandle.localeCompare(b.socialMediaHandle);
    }
  });

  websiteProperties?.sort((a, b) => {
    if (a.url.toUpperCase() < b.url.toUpperCase()) return -1;
    if (a.url.toUpperCase() > b.url.toUpperCase()) return 1;
    else return 0;
  });

  return socialProperties?.concat(websiteProperties);
};

export const PropertyImportWrapperLegacyNav = ({ influencerToken }) => {
  const { user } = useUserContext();
  const { formatMessage } = useIntl();
  user.currentCompany = useCurrentCompany();
  document.title = formatMessage(appMessages.onboardingChecklist);
  if (!influencerToken) {
    return (
      <ErrorPage
        errorCode={400}
        messageOverride={formatMessage(authMessages.missingToken)}
      />
    );
  }
  return (
    <PropertyImportWrapper influencerToken={influencerToken} user={user} />
  );
};

export const PropertyImport = ({
  influencerToken,
  propertiesForSignup,
  publisherId,
}) => {
  const { formatMessage } = useIntl();
  const { navigateTo } = useHistory();
  const [toastMount, show] = useToast();
  const checkSocialAccount = useCheckSocialAccount();
  const [saveProperties, isSaving] = useSavePromotionalProperties();
  const [focusId, setFocusId] = useFocus();

  const [promotionalProperties, setPromotionalProperties] = useState(
    () => sortBySocialPlatforms(propertiesForSignup) || []
  );

  const values = useRef({});
  propertiesForSignup?.forEach(
    (pp) => (values.current[pp.uuid] = pp.socialMediaHandle || pp.url)
  );

  const config = useCJForm({ values: values.current });

  const validateUrl = (s) => {
    const errorMessage = () => formatMessage(messages.invalidUrl);
    try {
      const url = new URL(s);
      const numHostParts = url.host
        .split(".")
        .filter((s) => s.trim().length > 0).length;
      const isValid =
        numHostParts >= 2 &&
        (url.protocol === "http:" || url.protocol === "https:");
      return isValid || errorMessage();
    } catch (e) {
      return errorMessage();
    }
  };

  const addSocialProperty = (platform) => {
    const newSocial = {
      type: "Social",
      socialMediaPlatform: platform,
      socialMediaHandle: "",
      uuid: uuidv4(),
      state: STATE_NEW,
    };

    setPromotionalProperties([...promotionalProperties, newSocial]);
    setFocusId(newSocial.uuid);
  };

  const addWebsiteProperty = () => {
    const newWebsite = {
      type: "Website",
      url: "",
      uuid: uuidv4(),
      state: STATE_NEW,
    };

    setPromotionalProperties([...promotionalProperties, newWebsite]);
    setFocusId(newWebsite.uuid);
  };

  const uncheckedUrlsExist = () => {
    const unchecked = promotionalProperties.filter(requiresCheckUrlButton);
    unchecked.forEach((pp) => {
      config.setError(pp.uuid, {
        message: formatMessage(formMessages.checkUrlRequired),
      });
    });
    return unchecked.length > 0;
  };

  const atLeastOneSocialPropertyExists = () => {
    const hasOneSocialProperty =
      promotionalProperties.filter(
        (p) => isSocial(p) && p.state !== STATE_DELETED
      ).length > 0;

    if (!hasOneSocialProperty) {
      show({
        message: formatMessage(messages.atLeastOneAccount),
        type: "warning",
        contentStyle: { textAlign: "center" },
      });
    }

    return hasOneSocialProperty;
  };

  const blankSocialMediaHandlesExist = () => {
    const hasBlankHandle = (p) =>
      isSocial(p) &&
      (p.state === STATE_EDITED || p.state === STATE_NEW) &&
      p.socialMediaHandle.trim().length === 0;
    const propertiesWithBlankHandles = promotionalProperties.filter(
      hasBlankHandle
    );
    propertiesWithBlankHandles.forEach((pp) => {
      config.setError(pp.uuid, {
        message: formatMessage(globalMessages.requiredError),
      });
    });
    return propertiesWithBlankHandles.length > 0;
  };

  const savePromotionalProperties = () => {
    // avoid short-circuit evaluation
    const hasUncheckedUrls = uncheckedUrlsExist();
    const hasBlankHandles = blankSocialMediaHandlesExist();

    if (hasBlankHandles || hasUncheckedUrls) {
      return false;
    }

    if (!atLeastOneSocialPropertyExists()) {
      return false;
    }

    saveProperties(promotionalProperties, publisherId).then((result) => {
      if (result === "ERROR") {
        show({
          message: formatMessage(messages.accountsSaveError),
          type: "warning",
          contentStyle: { textAlign: "center" },
        });
      }

      if (result === "SAVED" || result === "NOT_SAVED") {
        navigateTo(
          `${INFLUENCER_DESCRIPTION}?influencerToken=${influencerToken}`
        );
      }
    });
  };

  const updateHandle = (property) => (event) => {
    property.socialMediaHandle = event.target.value;
    modifyEditState(property, event.target.value);
  };

  const updateUrl = (property) => (event) => {
    property.url = event.target.value;
    modifyEditState(property, event.target.value);
  };

  const modifyEditState = (property, value) => {
    const newState =
      value.length === 0
        ? STATE_NEW
        : isSocialAutoValidated(property)
        ? STATE_VALIDATED
        : STATE_EDITED;
    markAs(newState, property);
  };

  const markAs = (newState, property) => {
    if (property.state !== newState) {
      property.state = newState;
      setPromotionalProperties([...promotionalProperties]);
    }
  };

  const markAsDeleted = (property) => markAs(STATE_DELETED, property);
  const markAsChecking = (property) => markAs(STATE_CHECKING, property);
  const markAsValidated = (property) => markAs(STATE_VALIDATED, property);

  const checkUrl = (property) => {
    config.clearErrors(property.uuid);
    markAsChecking(property);
    checkSocialAccount(
      property.socialMediaPlatform,
      property.socialMediaHandle
    ).then((result) => {
      if (result === FOUND) {
        markAsValidated(property);
        show({
          message: formatMessage(messages.handleFound),
          contentStyle: { textAlign: "center" },
          type: "success",
          duration: 7000,
        });
      } else if (result === NOT_FOUND) {
        markAsValidated(property);
        show({
          message: formatMessage(messages.checkUrlError, {
            handle: property.socialMediaHandle,
          }),
          contentStyle: { textAlign: "center" },
          type: "warning",
          duration: 7000,
        });
      } else {
        markAs(property, STATE_EDITED);
      }
    });
  };

  const helpText = (property) => {
    if (property.state === STATE_CHECKING) {
      return formatMessage(socialMessages.checkUrlHelpLoading);
    }
    if (property.uuid === focusId) {
      const helpTextParams = socialMediaPlatformHelpTextParams(
        property.socialMediaPlatform
      );
      if (helpTextParams) {
        return formatMessage(
          socialMessages.checkUrlHelpExample,
          helpTextParams
        );
      }
    }
    return null;
  };

  const checkUrlButton = (property) => (
    <Button
      type="primary"
      onClick={() => checkUrl(property)}
      spinner={property.state === STATE_CHECKING}
    >
      {formatMessage(socialMessages.checkUrl)}
    </Button>
  );

  const RemoveButton = ({ property }) => (
    <IconButton
      data-testid={`remove-${property.uuid}`}
      icon="close"
      round
      tooltip={formatMessage(messages.remove)}
      onClick={() => markAsDeleted(property)}
    />
  );

  return (
    <Stack gap="xl" padding="xl">
      <InfluencerSignupHeader
        title={formatMessage(globalMessages.influencerWizardTitle)}
      />
      <FormPageLayout width="wide">
        <FormPageBlock>
          <CJForm
            {...config}
            heading={formatMessage(messages.step1)}
            showRequiredIndicator
            requiredIndicatorText={formatMessage(globalMessages.requiredFields)}
            onSubmit={savePromotionalProperties}
          >
            <CJFormSubheading
              heading={formatMessage(messages.letsAddProperties)}
            >
              {formatMessage(messages.showOff)}
            </CJFormSubheading>

            <HorizontalLine type="solid" />

            <CJFormSubheading heading={formatMessage(messages.addProperties)}>
              {formatMessage(messages.atLeastOneAccount)}
            </CJFormSubheading>

            {promotionalProperties
              ?.filter(
                (property) =>
                  isSocial(property) && property.state !== STATE_DELETED
              )
              .map((property, i) => {
                return (
                  <CJField
                    label={
                      i === 0 ? formatMessage(messages.socialAccounts) : null
                    }
                    required={i === 0}
                    help={helpText(property)}
                    name={property.uuid}
                    key={`row_${property.uuid}`}
                  >
                    <Row align="center" gap="medium">
                      <SocialInput
                        onFocus={() => setFocusId(property.uuid)}
                        data-testid={property.uuid}
                        placeholder={formatMessage(
                          socialMessages.socialProfile
                        )}
                        maxLength={{
                          message: formatMessage(
                            globalMessages.maxLengthError,
                            [100]
                          ),
                          value: 100,
                        }}
                        onChange={updateHandle(property)}
                        platform={property.socialMediaPlatform}
                        name={property.uuid}
                        className="vsx-cjform-social-input"
                      />
                      {requiresCheckUrlButton(property) &&
                        checkUrlButton(property)}
                      <RemoveButton property={property} />
                    </Row>
                  </CJField>
                );
              })}

            <PropertyButtonWithDropdown addSocialProperty={addSocialProperty} />

            {promotionalProperties
              ?.filter(
                (property) =>
                  isWebsite(property) && property.state !== STATE_DELETED
              )
              .map((property, i) => {
                return (
                  <CJField
                    label={
                      i === 0 ? formatMessage(messages.blogOrWebsite) : null
                    }
                    htmlFor="website-input"
                    name={property.uuid}
                    key={`row_${property.uuid}`}
                  >
                    <Row align="center" gap="medium">
                      <SocialInput
                        onFocus={() => setFocusId(property.uuid)}
                        data-testid={property.uuid}
                        validate={validateUrl}
                        maxLength={{
                          message: formatMessage(
                            globalMessages.maxLengthError,
                            [125]
                          ),
                          value: 125,
                        }}
                        required={formatMessage(globalMessages.requiredError)}
                        placeholder={formatMessage(websiteMessages.websiteUrl)}
                        onChange={updateUrl(property)}
                        platform={"web"}
                        name={property.uuid}
                        className="vsx-cjform-social-input"
                      />
                      <RemoveButton property={property} />
                    </Row>
                  </CJField>
                );
              })}

            <Row>
              <Button
                type="secondary"
                htmlType="button"
                onClick={addWebsiteProperty}
              >
                {formatMessage(messages.addBlogOrWebsite)}
              </Button>
            </Row>

            <HorizontalLine type="solid" />
            <Row justify="space-between" gap="6xl">
              <Tip>{formatMessage(messages.changeLater)}</Tip>
              {toastMount}
              <Button
                type="primary"
                htmlType="submit"
                disabled={isSaving}
                spinner={isSaving}
              >
                {formatMessage(globalMessages.continue)}
              </Button>
            </Row>
          </CJForm>
        </FormPageBlock>
      </FormPageLayout>
    </Stack>
  );
};
