import { Button } from "@cjdev-internal/visual-stack-x/Button.js";
import { useUser as platformUseUser } from "@platform/cj-platform-navigation";
import { PROMOTIONAL_PROPERTIES } from "paths";
import messages from "../../views/ViewPromotionalProperties/messages";
import { useIntl } from "hooks";
import { Link } from "@platform/cj-platform-navigation";
import cookies from "js-cookie";
import { rolesWithManagePermission, userHasPermissions } from "auth";

export const CreateButton = () => {
  const { formatMessage } = useIntl();
  const user = platformUseUser();

  const publisherId =
    user?.currentCompany?.id || parseInt(cookies.get("jsCompanyId"));

  return userHasPermissions(rolesWithManagePermission, user, publisherId) ? (
    <>
      <Link to={`${PROMOTIONAL_PROPERTIES}/create`}>
        <Button type="primary" className="create-property-button">
          {formatMessage(messages.create)}
        </Button>
      </Link>
    </>
  ) : null;
};
