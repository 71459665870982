import * as R from "ramda";
import React, { useEffect } from "react";

import Alert from "../Alert";
import messages from "./messages";
import { useIntl } from "hooks";
import { useErrorNotification } from "hooks/useErrorNotification";

export const ErrorNotification = () => {
  const { formatMessage } = useIntl();
  const { errorNotification, setErrorNotification } = useErrorNotification();

  useEffect(
    () => () => {
      setErrorNotification(null);
    },
    [setErrorNotification]
  );

  const errorCount = R.length(errorNotification);

  return errorNotification ? (
    <Alert
      closeType="icon"
      iconType="warning"
      onHide={() => setErrorNotification(null)}
    >
      {errorCount === 1 &&
        formatMessage(messages.formErrorNotificationSingular)}
      {errorCount > 1 &&
        formatMessage(messages.formErrorNotificationPlural, {
          number: errorCount,
        })}
      <ul>
        {errorNotification.map(({ key, message }) => (
          <li key={key || message}>
            {key && key + ": "}
            {message}
          </li>
        ))}
      </ul>
    </Alert>
  ) : null;
};
