import {
  ChoiceInput,
  Field,
  TextArea,
} from "@cjdev-internal/visual-stack-x/legacy/Form";
import React, { useContext } from "react";

import messages from "./messages.js";
import DetailsPanel from "../../../DetailsPanel/index.js";
import { FormikContext } from "../../PromotionalPropertyForm/index.js";
import { useIntl } from "hooks/index.js";

export default () => {
  const { formatMessage } = useIntl();
  const { handleChange, handleBlur, values, touched, errors } = useContext(
    FormikContext
  );
  const propertyTypeDetailsErrors = errors.propertyTypeDetails || {};
  const propertyTypeDetailsTouched = touched.propertyTypeDetails || {};

  return (
    <>
      <Field help={formatMessage(messages.paidSearchEngineHelp)}>
        <ChoiceInput
          name="propertyTypeDetails.type"
          label={formatMessage(messages.paidSearchEngineLabel)}
          value="PAID_SEARCH_ENGINE"
          checked={values.propertyTypeDetails.type === "PAID_SEARCH_ENGINE"}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Field>
      {values.propertyTypeDetails.type === "PAID_SEARCH_ENGINE" ? (
        <DetailsPanel>
          <div className="property-type-row">
            <Field
              required
              label={formatMessage(
                messages.paidSearchEnginePlatformDescriptionLabel
              )}
              help={formatMessage(
                messages.paidSearchEnginePlatformDescriptionHelp
              )}
              error={
                propertyTypeDetailsTouched.paidSearchEngineDescription &&
                propertyTypeDetailsErrors.paidSearchEngineDescription
              }
            >
              <TextArea
                trimmed
                name="propertyTypeDetails.paidSearchEngineDescription"
                value={values.propertyTypeDetails.paidSearchEngineDescription}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={formatMessage(
                  messages.paidSearchEnginePlatformDescriptionPlaceholder
                )}
                className={
                  propertyTypeDetailsTouched.paidSearchEngineDescription &&
                  propertyTypeDetailsErrors.paidSearchEngineDescription &&
                  "input-error"
                }
              />
            </Field>
          </div>
        </DetailsPanel>
      ) : null}
    </>
  );
};
