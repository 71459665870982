import {
  ChoiceInput,
  Field,
  TextField,
} from "@cjdev-internal/visual-stack-x/legacy/Form";
import React, { useContext } from "react";

import messages from "./messages.js";
import DetailsPanel from "../../../DetailsPanel/index.js";
import { FormikContext } from "../../PromotionalPropertyForm/index.js";
import { useIntl } from "hooks/index.js";

export default () => {
  const { formatMessage } = useIntl();
  const { handleChange, handleBlur, values, touched, errors } = useContext(
    FormikContext
  );
  return (
    <>
      <Field help={formatMessage(messages.otherHelp)}>
        <ChoiceInput
          name="propertyTypeDetails.type"
          label={formatMessage(messages.otherLabel)}
          value="OTHER"
          checked={values.propertyTypeDetails.type === "OTHER"}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Field>
      {values.propertyTypeDetails.type === "OTHER" && (
        <DetailsPanel>
          <TextField
            required
            label={formatMessage(messages.otherDescription)}
            name="propertyTypeDetails.otherDescription"
            placeholder={formatMessage(messages.otherDescriptionPlaceholder)}
            error={
              touched.propertyTypeDetails &&
              touched.propertyTypeDetails.otherDescription &&
              errors.propertyTypeDetails &&
              errors.propertyTypeDetails.otherDescription
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.propertyTypeDetails.otherDescription || ""}
          />
        </DetailsPanel>
      )}
    </>
  );
};
