import {
  ChoiceInput,
  Field,
  TextField,
} from "@cjdev-internal/visual-stack-x/legacy/Form";
import React, { useContext } from "react";
import "./styles.css";
import messages from "./messages.js";
import DetailsPanel from "../../../DetailsPanel/index.js";
import { FormikContext } from "../../PromotionalPropertyForm/index.js";
import { useIntl } from "hooks/index.js";
import MultiPlatforms from "./MultiPlatforms.js";

export default () => {
  const { formatMessage } = useIntl();
  const { handleChange, handleBlur, values, touched, errors } = useContext(
    FormikContext
  );

  return (
    <>
      <Field help={formatMessage(messages.mobileAppHelp)}>
        <ChoiceInput
          name="propertyTypeDetails.type"
          label={formatMessage(messages.mobileAppLabel)}
          value="MOBILE_APP"
          checked={values.propertyTypeDetails.type === "MOBILE_APP"}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Field>
      {values.propertyTypeDetails.type === "MOBILE_APP" ? (
        <DetailsPanel>
          <TextField
            required
            label={formatMessage(messages.mobileAppName)}
            name="propertyTypeDetails.mobileAppName"
            placeholder={formatMessage(messages.mobileAppNamePlaceholder)}
            error={
              touched.propertyTypeDetails &&
              touched.propertyTypeDetails.mobileAppName &&
              errors.propertyTypeDetails &&
              errors.propertyTypeDetails.mobileAppName
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.propertyTypeDetails.mobileAppName || ""}
          />
          <MultiPlatforms />
        </DetailsPanel>
      ) : null}
    </>
  );
};
