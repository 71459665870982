import { defineMessages } from "react-intl";

export default defineMessages({
  start: {
    id: "accounts.InfluencerSignup.start",
    defaultMessage: "Start your free account now!",
  },
  easy: {
    id: "accounts.InfluencerSignup.easy",
    defaultMessage:
      "You are just a few easy steps from joining the thousands of amazing creators like you!",
  },
});
