import { defineMessages } from "react-intl";

export default defineMessages({
  propertyTypeLabel: {
    id: "accounts.PropertyTypes.propertyTypeLabel",
    defaultMessage: "What type of property is this?",
  },
  propertyTypeHelp: {
    id: "accounts.PropertyTypes.propertyTypeHelp",
    defaultMessage:
      "You must choose one property type to describe how you will be promoting advertisers.",
  },
});
