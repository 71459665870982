import { defineMessages } from "react-intl";

export default defineMessages({
  publicPropertyTagsSubHeader: {
    id: "accounts.CreatePromotionalProperty.publicPropertyTagsSubHeader",
    defaultMessage: "Describe your property with public tags.",
  },
  propertyTagsPlaceholder: {
    id: "accounts.CreatePromotionalProperty.propertyTagsPlaceholder",
    defaultMessage: "Enter Tags",
  },
  publicPropertyTagsHelp: {
    id: "accounts.CreatePromotionalProperty.publicPropertyTagsHelp",
    defaultMessage:
      "Tags are searchable by potential advertisers. What words best describe this property? What words might advertisers " +
      "use to search for your property? You may choose multiple, but only choose those that are relevant to this specific " +
      "property. Add multiple keywords by typing “enter” between each one.",
  },
  propertyTagsIdeas1: {
    id: "accounts.CreatePromotionalProperty.propertyTagsIdeas1",
    defaultMessage: "Great ideas for tags:",
  },
  propertyTagsIdeas2: {
    id: "accounts.CreatePromotionalProperty.propertyTagsIdeas2",
    defaultMessage:
      "What types of products or content do you talk about or promote?",
  },
  propertyTagsIdeas3: {
    id: "accounts.CreatePromotionalProperty.propertyTagsIdeas3",
    defaultMessage: "Who is the primary audience?",
  },
  propertyTagsIdeas4: {
    id: "accounts.CreatePromotionalProperty.propertyTagsIdeas4",
    defaultMessage: "What is the audience size?",
  },
  propertyTagsIdeas5: {
    id: "accounts.CreatePromotionalProperty.propertyTagsIdeas5",
    defaultMessage: "What are the unique features of your property?",
  },
  propertyTagsIdeas6: {
    id: "accounts.CreatePromotionalProperty.propertyTagsIdeas6",
    defaultMessage: "Where is your primary audience location?",
  },
  propertyTagsIdeas7: {
    id: "accounts.CreatePromotionalProperty.propertyTagsIdeas7",
    defaultMessage: "Do you offer sponsored content?",
  },
  propertyTagsIdeas8: {
    id: "accounts.CreatePromotionalProperty.propertyTagsIdeas8",
    defaultMessage: "Do you have unique offerings?",
  },
});
