import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "accounts.PropertyVerificationPage.title",
    defaultMessage: "Property Verification Page",
  },
  error: {
    id: "accounts.PropertyVerificationPage.error",
    defaultMessage:
      "There was an issue loading the property verification page. Please try again later.",
  },
});
