import Cookies from "js-cookie";

export const INFLUENCER_WIZARD_COMPLETE =
  "influencer_wizard_complete_:creatorId";

export const setWizardCompleteCookieFor = (creatorId) => {
  const influencerWizardComplete = INFLUENCER_WIZARD_COMPLETE.replace(
    ":creatorId",
    creatorId
  );

  Cookies.set(influencerWizardComplete, true, { secure: true });
  return;
};

export const getWizardCompleteCookie = (creatorId) => {
  const influencerWizardComplete = INFLUENCER_WIZARD_COMPLETE.replace(
    ":creatorId",
    creatorId
  );
  const cookieValue = Cookies.get(influencerWizardComplete);

  // Parsing "true" will return the boolean value
  return cookieValue ? JSON.parse(cookieValue) : false;
};
