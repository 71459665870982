import { Button } from "@cjdev-internal/visual-stack-x/Button";
import {
  CJField,
  CJForm,
  CJFormSubheading,
  TextAreaInput,
  useCJForm,
} from "@cjdev-internal/visual-stack-x/CJForm";
import {
  FormPageBlock,
  FormPageLayout,
} from "@cjdev-internal/visual-stack-x/FormPageLayout";
import { Row } from "@cjdev-internal/visual-stack-x/Row";
import { Stack } from "@cjdev-internal/visual-stack-x/Stack";
import appMessages from "components/App/messages";
import { HorizontalLine } from "components/HorizontalLine";
import { InfluencerSignupHeader } from "components/InfluencerSignupHeader";
import { SpinnerPage } from "components/SpinnerPage";
import { Tip } from "components/Tip";
import {
  useCurrentCompany,
  useErrorToast,
  useHistory,
  useIntl,
  useNewNavigation,
  usePubDevCreators,
  usePublisherProfile,
  useUserContext,
} from "hooks";
import globalMessages from "messages/globalMessages";
import { INFLUENCER_ONBOARDING, INFLUENCER_USER_COMPANY } from "paths";
import { useEffect, useState } from "react";
import { authenticatedFetch, isNullOrEmpty } from "utils/util";
import messages from "./messages";
import authMessages from "components/Authorization/messages";
import { usePubDevCiqPublisherMapping } from "../../../hooks/usePubDevCiqPublisherMapping";
import { ErrorPage } from "../../../components/ErrorPage/index";
import { InfluencerSignupLayout } from "components/InfluencerSignupLayout";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "utils/sessionStorageDao";

export const SAVED_FORM_INPUT_KEY = "influencer-wizard-page2";

export const InfluencerDescriptionWrapper = ({ influencerToken }) => {
  const { user } = useUserContext();
  const { formatMessage } = useIntl();
  const { use2022Nav } = useNewNavigation();
  const currentCompany = useCurrentCompany();
  if (!user?.currentCompany) {
    user.currentCompany = currentCompany;
  }
  if (!influencerToken) {
    return (
      <ErrorPage
        errorCode={400}
        messageOverride={formatMessage(authMessages.missingToken)}
      />
    );
  }
  document.title = formatMessage(appMessages.onboardingChecklist);

  const showHeader = !use2022Nav;
  const savedFormInput = getSessionStorageItem(SAVED_FORM_INPUT_KEY);

  return (
    <InfluencerSignupLayout
      influencerToken={influencerToken}
      showHeader={showHeader}
    >
      <InfluencerDescription
        user={user}
        influencerToken={influencerToken}
        savedFormInput={savedFormInput}
      />
    </InfluencerSignupLayout>
  );
};

const getTrimmedDescriptionLength = (description) =>
  description ? description.trim().length : 0;

export const InfluencerDescription = ({
  user,
  influencerToken,
  savedFormInput,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const { formatMessage } = useIntl();
  const [errorToastMount, showError] = useErrorToast();
  const { navigateTo } = useHistory();
  const enableReactQueries = isNullOrEmpty(savedFormInput);
  const {
    data: profileData,
    isLoading: isProfileLoading,
  } = usePublisherProfile(user?.currentCompany?.id, enableReactQueries);
  const emptyProfileDescription = profileData?.description?.length === 0;
  const { data, isLoading: isPubDevLoading } = usePubDevCreators(
    influencerToken,
    emptyProfileDescription && enableReactQueries
  );
  const {
    data: existingPubDevMapping,
    isLoading: isPubDevMappingLoading,
  } = usePubDevCiqPublisherMapping(influencerToken);

  const isLoading =
    isProfileLoading ||
    (emptyProfileDescription && isPubDevLoading) ||
    isPubDevMappingLoading;

  const maybeDescription =
    savedFormInput?.descriptionInput ||
    profileData?.description ||
    data?.description;

  const config = useCJForm({
    values: {
      descriptionInput: maybeDescription,
    },
  });

  // there's only one field, but this is future-proof
  const watchAllFields = config.watch();
  useEffect(() => {
    setSessionStorageItem(SAVED_FORM_INPUT_KEY, watchAllFields);
  }, [watchAllFields]);

  if (isLoading) {
    return <SpinnerPage />;
  }

  if (existingPubDevMapping?.publisherId !== user?.currentCompany.id) {
    return <ErrorPage errorCode={403} />;
  }

  const onSubmit = async (formData) => {
    const publisherId = user.currentCompany.id;
    const url = `${process.env.REACT_APP_MEMBER_URL}/affapi/publisher/${publisherId}/profile`;
    const description = formData.descriptionInput?.trim();

    setIsSaving(true);

    try {
      const response = await authenticatedFetch(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ publisherId, description }),
      });

      if (!response.ok) {
        throw new Error("Bad Request. Please try again.");
      }

      setIsSaving(false);

      navigateTo(
        `${INFLUENCER_USER_COMPANY}?influencerToken=${influencerToken}`
      );
    } catch (e) {
      setIsSaving(false);
      showError();
    }
  };

  return (
    <Stack gap="xl" padding="xl">
      <InfluencerSignupHeader
        title={formatMessage(globalMessages.influencerWizardTitle)}
      />

      <FormPageLayout width="wide">
        <FormPageBlock>
          <CJForm
            {...config}
            showRequiredIndicator
            requiredIndicatorText={formatMessage(globalMessages.requiredFields)}
            onSubmit={onSubmit}
            heading={formatMessage(messages.formTitle)}
          >
            <CJFormSubheading
              heading={formatMessage(messages.introduceYourself)}
            >
              {formatMessage(messages.thisWillHelp)}
            </CJFormSubheading>
            <HorizontalLine type="solid" />

            <CJField
              fullWidth
              required
              name="descriptionInput"
              label={formatMessage(messages.profileDescription)}
              help={formatMessage(messages.minLengthHelp, [250])}
              secondaryHelp={formatMessage(messages.minLengthSecondaryHelp, [
                getTrimmedDescriptionLength(config.watch("descriptionInput")),
                4000,
              ])}
            >
              <TextAreaInput
                data-testid="description-text"
                name="descriptionInput"
                style={{ height: "150px" }}
                required={formatMessage(globalMessages.requiredError)}
                validate={(description) => {
                  const trimmedDescriptionLength = getTrimmedDescriptionLength(
                    description
                  );
                  if (trimmedDescriptionLength < 250) {
                    return formatMessage(globalMessages.minLengthError, [250]);
                  }

                  if (trimmedDescriptionLength > 4000) {
                    return formatMessage(globalMessages.maxLengthError, [4000]);
                  }
                }}
              />
            </CJField>

            <HorizontalLine type="solid" />
            <Row justify="space-between" gap="6xl">
              <Tip>{formatMessage(messages.changeDescriptionLater)}</Tip>
              {errorToastMount}
              <Row gap="medium">
                <Button
                  type="tertiary"
                  onClick={() =>
                    navigateTo(
                      `${INFLUENCER_ONBOARDING}?influencerToken=${influencerToken}`
                    )
                  }
                >
                  {formatMessage(globalMessages.back)}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isSaving}
                  spinner={isSaving}
                >
                  {formatMessage(globalMessages.continue)}
                </Button>
              </Row>
            </Row>
          </CJForm>
        </FormPageBlock>
      </FormPageLayout>
    </Stack>
  );
};
