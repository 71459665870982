import { defineMessages } from "react-intl";

export default defineMessages({
  "promotionalModels.type": {
    id: "accounts.PromotionalPropertyForm.promotionalModels.type",
    defaultMessage: "Promotional Model Type",
  },
  "promotionalModels.isPrimary": {
    id: "accounts.PromotionalPropertyForm.promotionalModels.isPrimary",
    defaultMessage: "Primary Promotional Model",
  },
  "promotionalModels.description": {
    id: "accounts.PromotionalPropertyForm.promotionalModels.description",
    defaultMessage: "Promotional Model Description",
  },
  promotionalModels: {
    id: "accounts.PromotionalPropertyForm.promotionalModels",
    defaultMessage: "Promotional Models",
  },
  propertyTypeDetails: {
    id: "accounts.PromotionalPropertyForm.propertyTypeDetails",
    defaultMessage: "Property Type",
  },
  "propertyTypeDetails.type": {
    id: "accounts.PromotionalPropertyForm.propertyTypeDetails.type",
    defaultMessage: "Promotional Property Type",
  },
  "propertyTypeDetails.websiteUrl": {
    id: "accounts.PromotionalPropertyForm.propertyTypeDetails.websiteUrl",
    defaultMessage: "Website URL",
  },
  "propertyTypeDetails.browserExtensionName": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.browserExtensionName",
    defaultMessage: "Browser Extension Name",
  },
  "propertyTypeDetails.browserExtensionDescription": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.browserExtensionDescription",
    defaultMessage: "Browser Extension Description",
  },
  "propertyTypeDetails.browserExtensionDownloadLink": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.browserExtensionDownloadLink",
    defaultMessage: "Browser Extension Download Link",
  },
  "propertyTypeDetails.browserExtensionBrowser": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.browserExtensionBrowser",
    defaultMessage: "Browser Extension Platform",
  },
  "propertyTypeDetails.browserExtensionAgreement": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.browserExtensionAgreement",
    defaultMessage: "Browser Extension Agreement",
  },
  "propertyTypeDetails.servicesAndToolsMarketingSiteUrl": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.servicesAndToolsMarketingSiteUrl",
    defaultMessage: "Service and Tools - Link to Marketing Site",
  },
  "propertyTypeDetails.socialMediaHandle": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.socialMediaHandle",
    defaultMessage: "Social Media Handle",
  },
  "propertyTypeDetails.socialMediaPlatform": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.socialMediaPlatform",
    defaultMessage: "Social Media Platform",
  },
  "propertyTypeDetails.socialMediaCheck": {
    id: "accounts.PromotionalPropertyForm.propertyTypeDetails.socialMediaCheck",
    defaultMessage: "Social Media Check URL",
  },
  "propertyTypeDetails.paidDisplayAdsDescription": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.paidDisplayAdsDescription",
    defaultMessage: "Paid Display Ads Description",
  },
  "propertyTypeDetails.paidSearchEngineDescription": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.paidSearchEngineDescription",
    defaultMessage: "Paid Search Engine Description",
  },
  "propertyTypeDetails.mobileAppName": {
    id: "accounts.PromotionalPropertyForm.propertyTypeDetails.mobileAppName",
    defaultMessage: "Mobile App Name",
  },
  "propertyTypeDetails.mobileAppDownloadInformation": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.mobileAppDownloadInformation",
    defaultMessage: "Mobile App Platform",
  },
  "propertyTypeDetails.mobileAppDownloadInformation.ios": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.mobileAppDownloadInformation.ios",
    defaultMessage: "IOS Platform",
  },
  "propertyTypeDetails.mobileAppDownloadInformation.android": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.mobileAppDownloadInformation.android",
    defaultMessage: "Android Platform",
  },
  "propertyTypeDetails.mobileAppDownloadLink": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.mobileAppDownloadLink",
    defaultMessage: "Mobile App Download Link",
  },
  "propertyTypeDetails.browserExtensionDownloadInformation": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.browserExtensionDownloadInformation",
    defaultMessage: "Browser Extension Platform",
  },
  "propertyTypeDetails.browserExtensionDownloadInformation.chrome": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.browserExtensionDownloadInformation.chrome",
    defaultMessage: "Chrome Browser Extension Download Link",
  },
  "propertyTypeDetails.browserExtensionDownloadInformation.firefox": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.browserExtensionDownloadInformation.firefox",
    defaultMessage: "Firefox Browser Extension Download Link",
  },
  "propertyTypeDetails.browserExtensionDownloadInformation.safari": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.browserExtensionDownloadInformation.safari",
    defaultMessage: "Safari Browser Extension Download Link",
  },
  "propertyTypeDetails.browserExtensionDownloadInformation.opera": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.browserExtensionDownloadInformation.opera",
    defaultMessage: "Opera Browser Extension Download Link",
  },
  "propertyTypeDetails.emailAddress": {
    id: "accounts.PromotionalPropertyForm.propertyTypeDetails.emailAddress",
    defaultMessage: "Email Address",
  },
  "propertyTypeDetails.emailMailingListSize": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.emailMailingListSize",
    defaultMessage: "Email Mailing List Size",
  },
  "propertyTypeDetails.emailOpenRate": {
    id: "accounts.PromotionalPropertyForm.propertyTypeDetails.emailOpenRate",
    defaultMessage: "Email Open Rate",
  },
  "propertyTypeDetails.mobileAppPlatform": {
    id:
      "accounts.PromotionalPropertyForm.propertyTypeDetails.mobileAppPlatform",
    defaultMessage: "Mobile App Platform",
  },
  "propertyTypeDetails.otherDescription": {
    id: "accounts.PromotionalPropertyForm.propertyTypeDetails.otherDescription",
    defaultMessage: "Other Promotional Property Type Description",
  },
  title: {
    id: "accounts.PromotionalPropertyForm.title",
    defaultMessage: "Create Promotional Property",
  },
  editFormTitle: {
    id: "accounts.PromotionalPropertyForm.editFormTitle",
    defaultMessage: "Edit Promotional Property",
  },
  cancel: {
    id: "accounts.PromotionalPropertyForm.cancel",
    defaultMessage: "Cancel",
  },
  submit: {
    id: "accounts.PromotionalPropertyForm.submit",
    defaultMessage: "Submit",
  },
  name: {
    id: "accounts.PromotionalPropertyForm.name",
    defaultMessage: "Promotional Property Name",
  },
  namePlaceholder: {
    id: "accounts.PromotionalPropertyForm.namePlaceholder",
    defaultMessage: "Enter Name",
  },
  nameHelp: {
    id: "accounts.PromotionalPropertyForm.nameHelp",
    defaultMessage:
      "The name advertisers will see for your promotional property. Be brief but descriptive. Max 60 characters. Examples could be Company 123 Homepage, X’s Instagram, New Customer Email, etc.",
  },
  description: {
    id: "accounts.PromotionalPropertyForm.description",
    defaultMessage: "Promotional Property Description (Optional)",
  },
  descriptionPlaceholder: {
    id: "accounts.PromotionalPropertyForm.descriptionPlaceholder",
    defaultMessage: "Describe Your Property",
  },
  descriptionHelp: {
    id: "accounts.PromotionalPropertyForm.descriptionHelp",
    defaultMessage:
      "Describe your property to potential advertisers. What do you do, and how are you different from other publishers? Sell your value to your potential partners. Max 1500 characters.",
  },
  header: {
    id: "accounts.PromotionalPropertyForm.header",
    defaultMessage: "Promotional Property",
  },
  descriptiveText: {
    id: "accounts.PromotionalPropertyForm.descriptiveText",
    defaultMessage:
      "Describe your promotional property. Advertisers use this information to search for and review potential publishers to partner with. They want to see what it is you do and what kind of value you would bring to their brand.",
  },
  propertyTypeTitle: {
    id: "accounts.PromotionalPropertyForm.propertyTypeTitle",
    defaultMessage: "Define Your Property",
  },
  promotionalModelTitle: {
    id: "accounts.PromotionalPropertyForm.promotionalModelTitle",
    defaultMessage: "Define Your Promotional Model",
  },
  propertyTagsTitle: {
    id: "accounts.PromotionalPropertyForm.propertyTagsTitle",
    defaultMessage: "Define Your Tags (Optional)",
  },
  required: {
    id: "accounts.PromotionalPropertyForm.required",
    defaultMessage: "Required",
  },
  checkUrlRequired: {
    id: "accounts.PromotionalPropertyForm.checkUrlRequired",
    defaultMessage: "Check URL Required",
  },
  invalidEmail: {
    id: "accounts.PromotionalPropertyForm.invalidEmail",
    defaultMessage: "Invalid email address",
  },
  invalidUrl: {
    id: "accounts.PromotionalPropertyForm.invalidUrl",
    defaultMessage: "Invalid url",
  },
  tooLong: {
    id: "accounts.PromotionalPropertyForm.tooLong",
    defaultMessage: "Maximum length allowed is {maxLength}",
  },
  tooShort: {
    id: "accounts.PromotionalPropertyForm.tooShort",
    defaultMessage: "Minimum length allowed is {minLength}",
  },
  atLeastOneRequired: {
    id: "accounts.PromotionalPropertyForm.atLeastOneRequired",
    defaultMessage: "At least one {fieldLabel} must be selected",
  },
  primaryPromotionalModelRequired: {
    id: "accounts.PromotionalPropertyForm.primaryPromotionalModelRequired",
    defaultMessage: "A primary promotional model must be selected",
  },
  isPrimaryFieldHelp: {
    id: "accounts.PromotionalPropertyForm.isPrimaryFieldHelp",
    defaultMessage:
      "If selected, the primary promotional model for this property will become the promotional model for your whole publisher account. This will be used just for reporting, so choose the property that best illustrates what you do. If another property is already set as your primary property, it will be unselected and this one will be set as your primary property.",
  },
  isPrimaryLabel: {
    id: "accounts.PromotionalPropertyForm.isPrimaryLabel",
    defaultMessage: "This is my primary promotional property",
  },
  statusLabel: {
    id: "accounts.PromotionalPropertyForm.statusLabel",
    defaultMessage: "Status",
  },
  statusActive: {
    id: "accounts.PromotionalPropertyForm.statusActive",
    defaultMessage: "Active",
  },
  statusArchived: {
    id: "accounts.PromotionalPropertyForm.statusArchived",
    defaultMessage: "Archived",
  },
  namePanelTitle: {
    id: "accounts.PromotionalPropertyForm.namePanelTitle",
    defaultMessage: "Name your Property",
  },
  invalidNumber: {
    id: "accounts.PromotionalPropertyForm.invalidNumber",
    defaultMessage: "Must be a number",
  },
  invalidInteger: {
    id: "accounts.PromotionalPropertyForm.invalidInteger",
    defaultMessage: "Must be a whole number",
  },
  promotionalModel: {
    id: "accounts.PromotionalPropertyForm.promotionalModel",
    defaultMessage: "promotional model",
  },
  mustBeZeroOrGreater: {
    id: "accounts.PromotionalPropertyForm.mustBeZeroOrGreater",
    defaultMessage: "Must be a non-negative number",
  },
  mustBeBetweenZeroAndOneHundred: {
    id: "accounts.PromotionalPropertyForm.mustBeBetweenZeroAndOneHundred",
    defaultMessage: "Must be a number between 0 and 100",
  },
  guideMe: {
    id: "accounts.PromotionalPropertyForm.guideMe",
    defaultMessage: "Guide Me",
  },
});
