export const getLanguages = () => [
  {
    name: "Čeština",
    isoCode: "cs",
  },
  {
    name: "Deutsch",
    isoCode: "de",
  },
  {
    name: "English",
    isoCode: "en",
  },
  {
    name: "Español",
    isoCode: "es",
  },
  {
    name: "Français",
    isoCode: "fr",
  },
  {
    name: "Polski",
    isoCode: "pl",
  },
  {
    name: "中文",
    isoCode: "zh",
  },
  {
    name: "日本語",
    isoCode: "ja",
  },
  {
    name: "Português",
    isoCode: "pt",
  },
];
