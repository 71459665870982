import { defineMessages } from "react-intl";

export default defineMessages({
  formErrorNotificationSingular: {
    id: "accounts.ErrorNotification.formErrorNotificationSingular",
    defaultMessage: "To save this property, 1 change needs to be made:",
  },
  formErrorNotificationPlural: {
    id: "accounts.ErrorNotification.formErrorNotificationPlural",
    defaultMessage: "To save this property, {number} changes need to be made:",
  },
});
