import { defineMessages } from "react-intl";

export default defineMessages({
  otherLabel: {
    id: "accounts.OtherPropertyType.otherLabel",
    defaultMessage: "I do not see my property type listed here",
  },
  otherDescription: {
    id: "accounts.OtherPropertyType.otherDescription",
    defaultMessage: "Description",
  },
  otherDescriptionPlaceholder: {
    id: "accounts.OtherPropertyType.otherDescriptionPlaceholder",
    defaultMessage: "Enter description",
  },
  otherHelp: {
    id: "accounts.OtherPropertyType.otherHelp",
    defaultMessage:
      "If your property does not fit into any of the above options, please briefly describe your promotional method/property type here so that advertisers can understand what you do.",
  },
});
