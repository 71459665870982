import { CJField, SelectInput } from "@cjdev-internal/visual-stack-x/CJForm";
import { useIntl } from "react-intl";
import messages from "./messages";
import { getLanguages } from "./getLanguages";
import globalMessages from "messages/globalMessages";

export const LanguageSelectField = () => {
  const languages = getLanguages();
  const { formatMessage } = useIntl();

  return (
    <CJField
      name="language"
      label={formatMessage(messages.label)}
      required
      htmlFor="language-input"
    >
      <SelectInput
        inputId="language-input"
        name="language"
        required={formatMessage(globalMessages.requiredError)}
        unpack
        options={languages.map((language) => ({
          label: language.name,
          value: language.isoCode,
        }))}
      />
    </CJField>
  );
};
