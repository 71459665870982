import { NotificationContext } from "context";
import { useProviderInitializedContext } from "./useProviderInitializedContext";

export const useNotification = () => {
  const { notification, setNotification } = useProviderInitializedContext(
    NotificationContext
  );
  return {
    notification,
    setNotification,
  };
};
