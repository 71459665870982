import { ErrorNotificationContext } from "context";
import { useProviderInitializedContext } from "./useProviderInitializedContext";

export const useErrorNotification = () => {
  const {
    errorNotification,
    setErrorNotification,
  } = useProviderInitializedContext(ErrorNotificationContext);
  return {
    errorNotification,
    setErrorNotification,
  };
};
