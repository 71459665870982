import {
  ChoiceInput,
  Field,
  Input,
} from "@cjdev-internal/visual-stack-x/legacy/Form";
import { Select } from "@cjdev-internal/visual-stack-x/Select.js";
import { Button } from "@cjdev-internal/visual-stack-x/Button.js";
import { Box } from "@cjdev-internal/visual-stack-x/Box.js";
import { Row } from "@cjdev-internal/visual-stack-x/Row.js";
import { Stack } from "@cjdev-internal/visual-stack-x/Stack.js";
import { Text } from "@cjdev-internal/visual-stack-x/Text.js";
import * as R from "ramda";
import React, { useContext } from "react";

import messages from "./messages.js";
import Alert from "../../../Alert/index.js";
import DetailsPanel from "../../../DetailsPanel/index.js";
import { FormikContext } from "../../PromotionalPropertyForm/index.js";
import { useIntl } from "hooks/index.js";
import "./styles.css";
import {
  useCheckSocialAccount,
  LOADING,
  FOUND,
  NOT_FOUND,
  ERROR,
} from "./useCheckSocialAccount.js";
import { Spinner } from "@cjdev-internal/visual-stack-x/Spinner.js";
import { useSocialPlatformOptions } from "./useSocialPlatformOptions.js";
import { isCiqPlatform } from "../../../../utils/ciq.js";
import { Link } from "@cjdev-internal/visual-stack-x/components/Link/Link.js";
import { socialMediaPlatformHelpTextParams } from "./platformHelpTextParams.js";

export default () => {
  const { formatMessage } = useIntl();
  const options = useSocialPlatformOptions();
  const { checkSocialAccount, status } = useCheckSocialAccount();
  const {
    values,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    touched,
    errors,
  } = useContext(FormikContext);

  const getSelectedOption = (value) =>
    R.find((option) => option.value === value, options);

  const buildSocialMediaPlatformHelpText = (platform) => {
    const helpTextParams = socialMediaPlatformHelpTextParams(platform);
    return helpTextParams
      ? formatMessage(messages.checkUrlHelpExample, helpTextParams)
      : null;
  };

  return (
    <>
      <Field help={formatMessage(messages.socialHelp)}>
        <ChoiceInput
          name="propertyTypeDetails.type"
          label={formatMessage(messages.socialLabel)}
          value="SOCIAL_MEDIA"
          checked={values.propertyTypeDetails.type === "SOCIAL_MEDIA"}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Field>
      {values.propertyTypeDetails.type === "SOCIAL_MEDIA" ? (
        <DetailsPanel>
          <Stack className="property-type-row">
            <Row>
              <Field
                required
                label={formatMessage(messages.socialPlatform)}
                error={
                  touched.propertyTypeDetails &&
                  touched.propertyTypeDetails.socialMediaPlatform &&
                  errors.propertyTypeDetails &&
                  errors.propertyTypeDetails.socialMediaPlatform
                }
              >
                <Select
                  name="propertyTypeDetails.socialMediaPlatform"
                  placeholder={formatMessage(
                    messages.socialPlatformPlaceholder
                  )}
                  error={
                    touched.propertyTypeDetails &&
                    touched.propertyTypeDetails.socialMediaPlatform &&
                    errors.propertyTypeDetails &&
                    errors.propertyTypeDetails.socialMediaPlatform
                  }
                  value={getSelectedOption(
                    values.propertyTypeDetails.socialMediaPlatform
                  )}
                  onChange={({ value }) => {
                    setFieldValue(
                      "propertyTypeDetails.socialMediaPlatform",
                      value
                    );
                  }}
                  onBlur={() => {
                    setFieldTouched(
                      "propertyTypeDetails.socialMediaPlatform",
                      true
                    );
                  }}
                  options={options}
                />
              </Field>
              {status === NOT_FOUND && (
                <Alert closeType="icon" iconType="info" autoHide>
                  <Stack gap="default">
                    <Text type="h6">
                      {formatMessage(messages.notFoundAlertTitle)}
                    </Text>
                    <Text>{formatMessage(messages.notFoundAlertContent)}</Text>
                  </Stack>
                </Alert>
              )}
              <Field
                required
                label={formatMessage(messages.socialProfile)}
                help={
                  status === LOADING
                    ? formatMessage(messages.checkUrlHelpLoading)
                    : touched.propertyTypeDetails?.socialMediaCheck &&
                      status === FOUND
                    ? formatMessage(messages.checkUrlHelpFound)
                    : touched.propertyTypeDetails?.socialMediaCheck &&
                      status === NOT_FOUND
                    ? formatMessage(messages.checkUrlHelpNotFound)
                    : buildSocialMediaPlatformHelpText(
                        values.propertyTypeDetails.socialMediaPlatform
                      )
                }
                error={
                  (touched.propertyTypeDetails?.socialMediaHandle &&
                    errors.propertyTypeDetails?.socialMediaHandle) ||
                  (touched.propertyTypeDetails?.socialMediaCheck &&
                    errors.propertyTypeDetails?.socialMediaCheck) ||
                  (touched.propertyTypeDetails?.socialMediaCheck &&
                    status === ERROR &&
                    formatMessage(messages.checkUrlHelpError))
                }
              >
                <Row gap="small">
                  <Input
                    name="propertyTypeDetails.socialMediaHandle"
                    onChange={(e) => {
                      setFieldTouched(
                        "propertyTypeDetails.socialMediaCheck",
                        false
                      );
                      setFieldValue(
                        "propertyTypeDetails.socialMediaCheck",
                        false
                      );
                      handleChange(e);
                    }}
                    data-testid="socialMediaCheck"
                    onBlur={handleBlur}
                    value={values.propertyTypeDetails.socialMediaHandle || ""}
                    className={
                      (touched.propertyTypeDetails?.socialMediaHandle &&
                        errors.propertyTypeDetails?.socialMediaHandle) ||
                      (touched.propertyTypeDetails?.socialMediaCheck &&
                        errors.propertyTypeDetails?.socialMediaCheck) ||
                      (touched.propertyTypeDetails?.socialMediaCheck &&
                        status === ERROR)
                        ? "input-error"
                        : undefined
                    }
                  />
                  {isCiqPlatform(
                    values.propertyTypeDetails.socialMediaPlatform
                  ) ? (
                    <Button
                      type="primary"
                      className="check-social-button"
                      aria-label="ciq-check-role"
                      disabled={
                        !values.propertyTypeDetails.socialMediaPlatform ||
                        !values.propertyTypeDetails.socialMediaHandle ||
                        status === LOADING
                      }
                      onClick={() => {
                        setFieldTouched(
                          "propertyTypeDetails.socialMediaCheck",
                          true
                        );
                        setFieldValue(
                          "propertyTypeDetails.socialMediaCheck",
                          true
                        );
                        checkSocialAccount(
                          values.propertyTypeDetails.socialMediaPlatform,
                          values.propertyTypeDetails.socialMediaHandle
                        );
                      }}
                    >
                      {status === LOADING ? (
                        <Spinner />
                      ) : (
                        formatMessage(messages.checkUrl)
                      )}
                    </Button>
                  ) : null}
                </Row>
              </Field>
            </Row>
            <Box padding="medium">
              <Link
                href="https://members.cj.com/member/contactSupport.cj?id=kA16O000000oLo7"
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatMessage(messages.learnMore)}
              </Link>
            </Box>
          </Stack>
        </DetailsPanel>
      ) : null}
    </>
  );
};
