import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import { oauthCallBack } from "../../auth";
import { PROMOTIONAL_PROPERTIES } from "../../paths";

const OauthCallbackComponent = ({ history }) => {
  useEffect(() => {
    const runOauthCallback = async () => {
      await oauthCallBack();
      history.push(PROMOTIONAL_PROPERTIES);
    };
    runOauthCallback();
  }, [history]);

  return <div />;
};

export default withRouter(OauthCallbackComponent);
