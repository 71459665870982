import React from "react";

// date is an ISO string or a Date object
export default ({ date }) => {
  const dateObject = typeof date === "string" ? new Date(date) : date;
  return (
    <div>
      {dateObject &&
        dateObject.toLocaleDateString(navigator.language, {
          year: "numeric",
          month: "short",
          day: "numeric",
        })}
    </div>
  );
};
