import cookies from "js-cookie";
import * as R from "ramda";
import { useUserContext } from "./useUserContext";

export const useCurrentCompany = () => {
  const { user } = useUserContext();
  return user?.currentCompany || selectActingCompany(user);
};

const selectActingCompany = (user) => {
  const companyId = parseInt(cookies.get("jsCompanyId"), 10);

  if (user && !R.isEmpty(user.companies)) {
    return (
      R.pipe(
        R.propOr([], "companies"),
        R.find(R.propEq("id", companyId))
      )(user) || {}
    );
  }

  return {};
};
