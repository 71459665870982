import React from "react";
import ReactDOM from "react-dom";
import { App } from "./components/App/App";
import { AppWithNav } from "./components/App/AppWithNav";

import { shouldDisplay2022Nav } from "@platform/cj-platform-navigation";
import { InfluencerSignupApp } from "components/App/InfluencerSignupApp";
import { EmailConfirmationApp } from "./components/App/EmailConfirmationApp.js";
import { SignupWelcomeApp } from "components/App/SignupWelcomeApp";

// Temporarily suppressing untranslated keys error coming from Platform
const consoleError = console.error.bind(console);
console.error = (message, ...args) => {
  if (message.code === "MISSING_TRANSLATION") {
    return;
  }
  consoleError(message, ...args);
};

const renderApp = (app) => {
  const rootElement = document.getElementById("root");
  ReactDOM.render(app, rootElement);
};

const url = new URL(window.location.href);

// We want to bypass the Nav as we want to display pages with anonymous access.
if (url.pathname === "/signup-welcome") {
  renderApp(<SignupWelcomeApp />);
} else if (url.pathname === "/influencer-signup") {
  renderApp(<InfluencerSignupApp />);
} else if (url.pathname === "/email-confirmation") {
  renderApp(<EmailConfirmationApp />);
} else {
  shouldDisplay2022Nav().then((display2022Nav) => {
    display2022Nav ? renderApp(<AppWithNav />) : renderApp(<App />);
  });
}
