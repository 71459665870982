import { Spinner } from "@cjdev-internal/visual-stack-x/Spinner.js";
import * as R from "ramda";
import React from "react";

import CreatePromotionalProperty from "../CreatePromotionalProperty/index.js";
import { GET_PROPERTY_FOR_COPY } from "../../queries/index.js";
import { useQuery } from "@apollo/client";
import { useParams } from "@platform/cj-platform-navigation";

export const CopyPromotionalProperty = () => {
  const { id: promotionalPropertyId } = useParams();

  return <CopyPromotionalPropertyView id={promotionalPropertyId} />;
};

const CopyPromotionalPropertyView = ({ id }) => {
  const { loading, error, data } = useQuery(GET_PROPERTY_FOR_COPY, {
    variables: { id },
  });

  if (loading) return <Spinner />;
  if (error) return "Something went wrong";

  return (
    <CreatePromotionalProperty
      initialValues={R.omit(["id", "isPrimary"])(data.promotionalProperty)}
    />
  );
};

export default CopyPromotionalPropertyView;
