import { Stack } from "@cjdev-internal/visual-stack-x/Stack";
import { ErrorPage as VsxErrorPage } from "@cjdev-internal/visual-stack-x/ErrorPage";

export const ErrorPage = ({ ...props }) => {
  return (
    <Stack expand align="center" justify="center">
      <VsxErrorPage {...props} />
    </Stack>
  );
};
