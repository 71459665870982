import { defineMessages } from "react-intl";

export default defineMessages({
  mobileAppLabel: {
    id: "accounts.PropertyTypes.mobileAppLabel",
    defaultMessage: "Mobile App",
  },
  mobileAppHelp: {
    id: "accounts.PropertyTypes.mobileAppHelp",
    defaultMessage:
      "My customers view my content on an app they download onto their phone or tablet through a store like the Google Play or Apple App store.",
  },
  mobileAppName: {
    id: "accounts.PropertyTypes.mobileAppName",
    defaultMessage: "App Name",
  },
  mobileAppNamePlaceholder: {
    id: "accounts.PropertyTypes.mobileAppNamePlaceholder",
    defaultMessage: "Enter name",
  },
  mobileAppPlatform: {
    id: "accounts.PropertyTypes.mobileAppPlatform",
    defaultMessage: "Platform",
  },
  mobileAppPlatformIos: {
    id: "accounts.PropertyTypes.mobileAppPlatformIos",
    defaultMessage: "IOS",
  },
  mobileAppPlatformAndroid: {
    id: "accounts.PropertyTypes.mobileAppPlatformAndroid",
    defaultMessage: "Android",
  },
  iosMobileAppDownloadLink: {
    id: "accounts.PropertyTypes.iosMobileAppDownloadLink",
    defaultMessage: "Download Link (must start with https://itunes.apple.com)",
  },
  androidMobileAppDownloadLink: {
    id: "accounts.PropertyTypes.androidMobileAppDownloadLink",
    defaultMessage: "Download Link (must start with https://play.google.com)",
  },
  mobileAppDownloadLinkPlaceholder: {
    id: "accounts.PropertyTypes.mobileAppDownloadLinkPlaceholder",
    defaultMessage: "Enter URL",
  },
});
