import * as R from "ramda";
import * as Yup from "yup";
import { isCiqPlatform } from "../../../utils/ciq.js";

import messages from "./messages.js";

const isNonEmptyString = R.pipe(R.isEmpty, R.not);

const isNonEmtpyObject = R.pipe(R.keys, R.isEmpty, R.not);

const supportedBrowsers = ["chrome", "firefox", "safari", "opera"];

const getBrowserValidation = (intl) => {
  let validations = {};
  const rule = Yup.string()
    .url(intl.formatMessage(messages.invalidUrl))
    .test({
      message: intl.formatMessage(messages.required),
      test: isNonEmptyString,
    });
  supportedBrowsers.forEach((browser) => {
    validations[browser] = rule;
  });
  return validations;
};

const validationSchema = (intl) =>
  Yup.object().shape({
    propertyTypeDetails: Yup.object()
      .shape({
        type: Yup.string().required(intl.formatMessage(messages.required)),
        websiteUrl: Yup.string().when("type", {
          is: "WEBSITE",
          then: Yup.string()
            .required(intl.formatMessage(messages.required))
            .url(intl.formatMessage(messages.invalidUrl))
            .test({
              message: intl.formatMessage(messages.invalidUrl),
              test: R.anyPass([
                R.isNil,
                R.isEmpty,
                R.startsWith("http:"),
                R.startsWith("https:"),
              ]),
            })
            .max(125, intl.formatMessage(messages.tooLong, { maxLength: 125 })),
        }),
        browserExtensionName: Yup.string().when("type", {
          is: "BROWSER_EXTENSION",
          then: Yup.string()
            .max(100, intl.formatMessage(messages.tooLong, { maxLength: 100 }))
            .required(intl.formatMessage(messages.required)),
        }),
        browserExtensionDescription: Yup.string().when("type", {
          is: "BROWSER_EXTENSION",
          then: Yup.string()
            .max(300, intl.formatMessage(messages.tooLong, { maxLength: 300 }))
            .required(intl.formatMessage(messages.required)),
        }),
        browserExtensionDownloadInformation: Yup.object()
          .default({})
          .when("type", {
            is: "BROWSER_EXTENSION",
            then: Yup.object(getBrowserValidation(intl)).test({
              message: intl.formatMessage(messages.required),
              test: isNonEmtpyObject,
            }),
          }),
        browserExtensionAgreement: Yup.boolean().when("type", {
          is: "BROWSER_EXTENSION",
          then: Yup.boolean()
            .oneOf([true])
            .required(intl.formatMessage(messages.required)),
        }),
        servicesAndToolsMarketingSiteUrl: Yup.string().when("type", {
          is: "SERVICES_AND_TOOLS",
          then: Yup.string()
            .url(intl.formatMessage(messages.invalidUrl))
            .max(125, intl.formatMessage(messages.tooLong, { maxLength: 125 }))
            .required(intl.formatMessage(messages.required)),
        }),
        socialMediaHandle: Yup.string().when("type", {
          is: "SOCIAL_MEDIA",
          then: Yup.string()
            .max(100, intl.formatMessage(messages.tooLong, { maxLength: 100 }))
            .required(intl.formatMessage(messages.required)),
        }),
        socialMediaPlatform: Yup.string().when("type", {
          is: "SOCIAL_MEDIA",
          then: Yup.string().required(intl.formatMessage(messages.required)),
        }),
        socialMediaCheck: Yup.boolean().when(["type", "socialMediaPlatform"], {
          is: (type, socialMediaPlatform) =>
            type === "SOCIAL_MEDIA" && isCiqPlatform(socialMediaPlatform),
          then: Yup.boolean()
            .nullable()
            .test({
              test: R.equals(true),
              message: intl.formatMessage(messages.checkUrlRequired),
            }),
        }),
        paidSearchEngineDescription: Yup.string().when("type", {
          is: "PAID_SEARCH_ENGINE",
          then: Yup.string()
            .max(100, intl.formatMessage(messages.tooLong, { maxLength: 100 }))
            .required(intl.formatMessage(messages.required)),
        }),
        paidDisplayAdsDescription: Yup.string().when("type", {
          is: "PAID_DISPLAY_ADS",
          then: Yup.string()
            .max(100, intl.formatMessage(messages.tooLong, { maxLength: 100 }))
            .required(intl.formatMessage(messages.required)),
        }),
        mobileAppDownloadInformation: Yup.object()
          .default({})
          .when("type", {
            is: "MOBILE_APP",
            then: Yup.object({
              ios: Yup.string()
                .url(intl.formatMessage(messages.invalidUrl))
                .test({
                  message: intl.formatMessage(messages.required),
                  test: isNonEmptyString,
                }),
              android: Yup.string()
                .url(intl.formatMessage(messages.invalidUrl))
                .test({
                  message: intl.formatMessage(messages.required),
                  test: isNonEmptyString,
                }),
            }).test({
              message: intl.formatMessage(messages.required),
              test: isNonEmtpyObject,
            }),
          }),
        mobileAppName: Yup.string().when("type", {
          is: "MOBILE_APP",
          then: Yup.string()
            .max(100, intl.formatMessage(messages.tooLong, { maxLength: 100 }))
            .required(intl.formatMessage(messages.required)),
        }),
        emailAddress: Yup.string().when("type", {
          is: "EMAIL",
          then: Yup.string()
            .email(intl.formatMessage(messages.invalidEmail))
            .max(60, intl.formatMessage(messages.tooLong, { maxLength: 60 }))
            .required(intl.formatMessage(messages.required)),
        }),
        emailMailingListSize: Yup.number().when("type", {
          is: "EMAIL",
          then: Yup.number()
            .required(intl.formatMessage(messages.required))
            .typeError(intl.formatMessage(messages.invalidInteger))
            .integer(intl.formatMessage(messages.invalidInteger))
            .min(0, intl.formatMessage(messages.mustBeZeroOrGreater)),
        }),
        emailOpenRate: Yup.number().when("type", {
          is: "EMAIL",
          then: Yup.number()
            .typeError(intl.formatMessage(messages.invalidNumber))
            .required(intl.formatMessage(messages.required))
            .min(0, intl.formatMessage(messages.mustBeBetweenZeroAndOneHundred))
            .max(
              100,
              intl.formatMessage(messages.mustBeBetweenZeroAndOneHundred)
            ),
        }),
        otherDescription: Yup.string().when("type", {
          is: "OTHER",
          then: Yup.string()
            .required(intl.formatMessage(messages.required))
            .min(30, intl.formatMessage(messages.tooShort, { minLength: 30 }))
            .max(100, intl.formatMessage(messages.tooLong, { maxLength: 100 })),
        }),
      })
      .required(intl.formatMessage(messages.required)),
    promotionalModels: Yup.array()
      .required(
        intl.formatMessage(messages.atLeastOneRequired, {
          fieldLabel: intl.formatMessage(messages.promotionalModel),
        })
      )
      .test(
        "primaryPromotionalModelSet",
        intl.formatMessage(messages.primaryPromotionalModelRequired),
        (promotionalModels) =>
          R.any(
            (promotionalModel) => promotionalModel.isPrimary,
            promotionalModels
          )
      )
      .of(
        Yup.object().shape({
          type: Yup.string().required(intl.formatMessage(messages.required)),
          isPrimary: Yup.boolean().required(
            intl.formatMessage(messages.required)
          ),
          description: Yup.string().when("type", (name, schema) =>
            name === "OTHER"
              ? schema
                  .required(intl.formatMessage(messages.required))
                  .min(
                    30,
                    intl.formatMessage(messages.tooShort, { minLength: 30 })
                  )
                  .max(
                    100,
                    intl.formatMessage(messages.tooLong, { maxLength: 100 })
                  )
              : schema.nullable()
          ),
        })
      ),
    name: Yup.string()
      .trim()
      .required(intl.formatMessage(messages.required))
      .max(60, intl.formatMessage(messages.tooLong, { maxLength: 60 })),
    description: Yup.string()
      .nullable()
      .max(1500, intl.formatMessage(messages.tooLong, { maxLength: 1500 })),
  });

export default validationSchema;

export const formatErrorMessages = (errors, translate, namespace = "") =>
  R.flatten(
    R.map(([key, value]) => {
      const namespaceWithKey = namespace ? `${namespace}.${key}` : key;
      if (typeof value === "string") {
        return {
          key: translate(namespaceWithKey),
          message: value,
        };
      }
      if (Array.isArray(value)) {
        return R.reject(R.isNil)(value).map((value) =>
          formatErrorMessages(value, translate, namespaceWithKey)
        );
      }
      return formatErrorMessages(value, translate, namespaceWithKey);
    }, R.toPairs(errors))
  );
