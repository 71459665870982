import { useQuery } from "@tanstack/react-query";
import { getCountries } from "../api";
import { useUser } from "@platform/cj-platform-navigation";

export const useCountriesQuery = (language) => {
  const user = useUser();
  const queryLanguage = language || user?.currentCompany?.preferredLanguage;

  return useQuery(["countries", queryLanguage], () =>
    getCountries(queryLanguage)
  );
};
