import { defineMessages } from "react-intl";

export default defineMessages({
  socialLabel: {
    id: "accounts.PropertyTypes.socialLabel",
    defaultMessage: "Social",
  },
  socialHelp: {
    id: "accounts.PropertyTypes.socialHelp",
    defaultMessage:
      "My customers view content I post on social media or other social platforms where I have an account.",
  },
  socialPlatform: {
    id: "accounts.PropertyTypes.socialPlatform",
    defaultMessage: "Social Media Platform",
  },
  socialPlatformPlaceholder: {
    id: "accounts.PropertyTypes.socialPlatformPlaceholder",
    defaultMessage: "Select a platform",
  },
  socialPlatformInstagram: {
    id: "accounts.PropertyTypes.socialPlatformInstagram",
    defaultMessage: "Instagram",
  },
  socialPlatformOther: {
    id: "accounts.PropertyTypes.socialPlatformOther",
    defaultMessage: "Other",
  },
  socialPlatformFacebook: {
    id: "accounts.PropertyTypes.socialPlatformFacebook",
    defaultMessage: "Facebook",
  },
  socialPlatformYouTube: {
    id: "accounts.PropertyTypes.socialPlatformYouTube",
    defaultMessage: "YouTube",
  },
  socialPlatformTikTok: {
    id: "accounts.PropertyTypes.socialPlatformTikTok",
    defaultMessage: "TikTok",
  },
  socialPlatformTwitter: {
    id: "accounts.PropertyTypes.socialPlatformTwitter",
    defaultMessage: "Twitter",
  },
  socialPlatformPinterest: {
    id: "accounts.PropertyTypes.socialPlatformPinterest",
    defaultMessage: "Pinterest",
  },
  socialPlatformTwitch: {
    id: "accounts.PropertyTypes.socialPlatformTwitch",
    defaultMessage: "Twitch",
  },
  socialPlatformWhatsApp: {
    id: "accounts.PropertyTypes.socialPlatformWhatsApp",
    defaultMessage: "WhatsApp",
  },
  socialPlatformSnapchat: {
    id: "accounts.PropertyTypes.socialPlatformSnapchat",
    defaultMessage: "Snapchat",
  },
  socialProfile: {
    id: "accounts.PropertyTypes.socialProfile",
    defaultMessage: "User Handle or Link to Profile",
  },
  checkUrl: {
    id: "accounts.SocialPropertyType.checkUrl",
    defaultMessage: "CHECK URL",
  },
  checkUrlHelpExample: {
    id: "accounts.SocialPropertyType.checkUrlHelpExample",
    defaultMessage: "Examples: {0} or {1}",
  },
  checkUrlHelpLoading: {
    id: "accounts.SocialPropertyType.checkUrlHelpLoading",
    defaultMessage: "Fetching user handle/URL...",
  },
  checkUrlHelpFound: {
    id: "accounts.SocialPropertyType.checkUrlHelpFound",
    defaultMessage: "User handle/URL found",
  },
  checkUrlHelpNotFound: {
    id: "accounts.SocialPropertyType.checkUrlHelpNotFound",
    defaultMessage: "User handle/URL not found",
  },
  checkUrlHelpError: {
    id: "accounts.SocialPropertyType.checkUrlHelpError",
    defaultMessage: "There was an error, please try again.",
  },
  learnMore: {
    id: "accounts.SocialPropertyType.learnMore",
    defaultMessage:
      "Learn more about adding social media handles to your promotional properties",
  },
  notFoundAlertTitle: {
    id: "accounts.SocialPropertyType.notFoundAlertTitle",
    defaultMessage: "Your user handle/URL cannot be found.",
  },
  notFoundAlertContent: {
    id: "accounts.SocialPropertyType.notFoundAlertContent",
    defaultMessage:
      "You can continue and save your promotional property however no social metrics will show in your profile for this property.",
  },
});
