import { ErrorPage } from "components/ErrorPage";
import { getQueryParam, missing } from "utils/util";
import messages from "./messages";
import { useIntl } from "react-intl";

export const Authorization = ({ children }) => {
  const { formatMessage } = useIntl();
  const token = getQueryParam("token");
  const advertiserId = getQueryParam("advertiserId");

  if (missing(token)) {
    return (
      <ErrorPage
        errorCode={400}
        messageOverride={formatMessage(messages.missingToken)}
      />
    );
  }

  if (missing(advertiserId)) {
    return (
      <ErrorPage
        errorCode={400}
        messageOverride={formatMessage(messages.missingAdvertiserId)}
      />
    );
  }

  return children;
};
