import { Button } from "@cjdev-internal/visual-stack-x/Button.js";
import {
  ChoiceInput,
  Field,
  TextField,
} from "@cjdev-internal/visual-stack-x/legacy/Form";
import { FieldArray } from "formik";
import { find, findIndex } from "ramda";
import React, { useContext } from "react";

import messages from "./messages.js";
import "./styles.css";
import DetailsPanel from "../../DetailsPanel/index.js";
import { FormikContext } from "../PromotionalPropertyForm/index.js";
import Tag from "../../Tag/index.js";
import { useIntl } from "hooks/index.js";

export const promotionalModelTypes = [
  "CONTENT_BLOG_MEDIA",
  "COUPON_DEAL",
  "PRODUCT_COMPARISON_REVIEWS_DISCOVERY",
  "LOYALTY_CASH_BACK",
  "LOYALTY_NON_CASH_REWARDS",
  "LOYALTY_CLOSED_USER_GROUP",
  "TRADEMARK_BIDDING",
  "INFLUENCER",
  "PAY_PER_CALL",
  "OTHER",
];

const findByType = (type, promotionalModels) =>
  find((promotionalModel) => promotionalModel.type === type, promotionalModels);

const findIndexByType = (type, promotionalModels) =>
  findIndex(
    (promotionalModel) => promotionalModel.type === type,
    promotionalModels
  );

const isChecked = (type, promotionalModels) =>
  !!findByType(type, promotionalModels);

const setChecked = (arrayHelpers, values, promotionalModelType, checked) => {
  if (checked) {
    arrayHelpers.push({ type: promotionalModelType, isPrimary: false });
  } else {
    arrayHelpers.remove(
      findIndexByType(promotionalModelType, values.promotionalModels)
    );
  }
};

const setPrimary = (type, arrayHelpers, promotionalModels) => {
  const primaryPromotionalModelIndex = findIndex(
    (promotionalModel) => promotionalModel.isPrimary,
    promotionalModels
  );
  const primaryPromotionalModel =
    promotionalModels[primaryPromotionalModelIndex];

  if (primaryPromotionalModel) {
    arrayHelpers.replace(primaryPromotionalModelIndex, {
      ...primaryPromotionalModel,
      isPrimary: false,
    });
  }

  arrayHelpers.replace(findIndexByType(type, promotionalModels), {
    ...findByType(type, promotionalModels),
    isPrimary: true,
  });
};

const PromotionalModel = ({ arrayHelpers, promotionalModelType }) => {
  const { formatMessage } = useIntl();

  const { errors, handleBlur, handleChange, touched, values } = useContext(
    FormikContext
  );

  const promotionalModelIndex = findIndexByType(
    promotionalModelType,
    values.promotionalModels
  );

  return (
    <div className="promotional-model">
      <Field
        help={
          messages[`${promotionalModelType}_HELP`] &&
          formatMessage(messages[`${promotionalModelType}_HELP`])
        }
      >
        <ChoiceInput
          type="checkbox"
          name={`promotionalModels.${promotionalModelType}`}
          label={formatMessage(messages[promotionalModelType])}
          value={promotionalModelType}
          checked={isChecked(promotionalModelType, values.promotionalModels)}
          onChange={(e) => {
            e.stopPropagation();
            setChecked(
              arrayHelpers,
              values,
              promotionalModelType,
              e.target.checked
            );
          }}
        />
      </Field>

      {isChecked(promotionalModelType, values.promotionalModels) && (
        <DetailsPanel>
          {findByType(promotionalModelType, values.promotionalModels)
            .isPrimary ? (
            <Tag className={`${promotionalModelType}-primary`}>
              {formatMessage(messages.primaryPromotionalMethod)}
            </Tag>
          ) : (
            <Field>
              <Button
                type="secondary"
                onClick={() => {
                  setPrimary(
                    promotionalModelType,
                    arrayHelpers,
                    values.promotionalModels
                  );
                }}
              >
                {formatMessage(messages.setPrimary)}
              </Button>
              <div className="promotional-model-set-primary-helper">
                {formatMessage(messages.setPrimaryHelper)}
              </div>
            </Field>
          )}
          {promotionalModelType === "OTHER" && (
            <TextField
              label={formatMessage(messages.description)}
              name={`promotionalModels[${promotionalModelIndex}].description`}
              placeholder={formatMessage(messages.descriptionPlaceholder)}
              error={
                touched &&
                touched.promotionalModels &&
                touched.promotionalModels[promotionalModelIndex] &&
                touched.promotionalModels[promotionalModelIndex].description &&
                errors &&
                errors.promotionalModels &&
                errors.promotionalModels[promotionalModelIndex] &&
                errors.promotionalModels[promotionalModelIndex].description
              }
              onBlur={handleBlur}
              onChange={handleChange}
              value={
                (values.promotionalModels[promotionalModelIndex] &&
                  values.promotionalModels[promotionalModelIndex]
                    .description) ||
                ""
              }
            />
          )}
        </DetailsPanel>
      )}
    </div>
  );
};

export default () => {
  const { formatMessage } = useIntl();

  const { errors, touched } = useContext(FormikContext);

  return (
    <section className="field-array">
      <Field
        required
        label={formatMessage(messages.promotionalModel)}
        help={formatMessage(messages.promotionalModelHelp)}
        error={
          touched &&
          touched.promotionalModels &&
          errors &&
          typeof errors.promotionalModels === "string" &&
          errors.promotionalModels
        }
      />
      <FieldArray name="promotionalModels">
        {(arrayHelpers) => (
          <>
            {promotionalModelTypes.map((promotionalModelType) => (
              <PromotionalModel
                key={promotionalModelType}
                promotionalModelType={promotionalModelType}
                arrayHelpers={arrayHelpers}
              />
            ))}
          </>
        )}
      </FieldArray>
    </section>
  );
};
