import { defineMessages } from "react-intl";

export default defineMessages({
  paidSearchEngineLabel: {
    id: "accounts.PropertyTypes.paidSearchEngineLabel",
    defaultMessage: "Paid Search Engine Marketing (direct)",
  },
  paidSearchEngineHelp: {
    id: "accounts.PropertyTypes.paidSearchEngineHelp",
    defaultMessage:
      "I run search engine ads, but my customers see an ad directly for the merchant, not my brand, and when they click are taken to the merchant site, not my site.",
  },
  paidSearchEnginePlatformDescriptionLabel: {
    id: "accounts.PropertyTypes.paidSearchEnginePlatformDescriptionLabel",
    defaultMessage: "Description",
  },
  paidSearchEnginePlatformDescriptionPlaceholder: {
    id: "accounts.PropertyTypes.paidSearchEnginePlatformDescriptionPlaceholder",
    defaultMessage: "Enter description",
  },
  paidSearchEnginePlatformDescriptionHelp: {
    id: "accounts.PropertyTypes.paidSearchEnginePlatformDescriptionHelp",
    defaultMessage:
      "Describe the platforms where you purchase ads and what kind of click through rates your ads achieve.",
  },
  paidSearchEnginePlatformPlaceholder: {
    id: "accounts.PropertyTypes.paidSearchEnginePlatformPlaceholder",
    defaultMessage: "Enter platform",
  },
  paidSearchEngineClickThroughRate: {
    id: "accounts.PropertyTypes.paidSearchEngineClickThroughRate",
    defaultMessage: "Click Through Rate (example, 1 is 1%)",
  },
  paidSearchEngineClickThroughRatePlaceholder: {
    id: "accounts.PropertyTypes.paidSearchEngineClickThroughRatePlaceholder",
    defaultMessage: "Enter rate",
  },
  paidSearchEnginePlatformGoogle: {
    id: "accounts.PropertyTypes.paidSearchEnginePlatformGoogle",
    defaultMessage: "Google",
  },
  paidSearchEnginePlatformBing: {
    id: "accounts.PropertyTypes.paidSearchEnginePlatformBing",
    defaultMessage: "Bing",
  },
});
