import { Box } from "@cjdev-internal/visual-stack-x/Box.js";
import { Spinner } from "@cjdev-internal/visual-stack-x/Spinner.js";
import { TopNavLayout } from "@cjdev-internal/visual-stack-x/TopNavLayout.js";
import { FormModalWithOptions } from "@platform/cj-platform-navigation";
import React, { useEffect, useState } from "react";
import cookies from "js-cookie";
import {
  useHistory,
  useIntl,
  useNewNavigation,
  useUserContext,
} from "hooks/index.js";
import messages from "./messages.js";
import { PROMOTIONAL_PROPERTIES } from "paths.js";
import { authenticatedFetch } from "utils/util.js";

export const PropertyVerificationPage = () => {
  const { use2022Nav } = useNewNavigation();
  const { navigateTo } = useHistory();
  const { formatMessage } = useIntl();
  const [linkUrl, setLinkUrl] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const { user } = useUserContext();
  const companyId = use2022Nav
    ? user.currentCompany.id
    : cookies.get("jsCompanyId");

  useEffect(() => {
    const fetchCiqAccountLinkPage = async () => {
      try {
        const ciqAccountLinkPageResponse = await authenticatedFetch(
          `${process.env.REACT_APP_ACCOUNTS_API_URL}/ciq-link-accounts-url?publisherId=${companyId}`
        );

        if (!ciqAccountLinkPageResponse.ok) {
          throw new Error("Error retrieving account link page from Creator IQ");
        }

        setLinkUrl(await ciqAccountLinkPageResponse.text());
      } catch (e) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCiqAccountLinkPage();
  }, [companyId]);

  const refreshAuthenticationStatus = async () => {
    await authenticatedFetch(
      `${process.env.REACT_APP_ACCOUNTS_API_URL}/refresh-auth-status/${companyId}`
    );
  };

  const onSubmit = async () => {
    await refreshAuthenticationStatus();
    window.sessionStorage.setItem("refreshAuthStatus", true);
    window.location.assign(`${PROMOTIONAL_PROPERTIES}`);
  };
  const onCancel = () => navigateTo(PROMOTIONAL_PROPERTIES);

  const topNavProps = {
    title: "Property Authentication",
    contentProps: { gap: "small" },
    actions: {
      submit: {
        content: "Done",
        onClick: onSubmit,
      },
      // cancel: {
      //   content: "Cancel",
      //   onClick: onCancel,
      // },
    },
    className: "property-verification-page",
  };

  const propertyVerificationContent = isLoading ? (
    <Box align="center">
      <Spinner />
    </Box>
  ) : (
    <>
      <Box align="center">
        {isError ? (
          <div>{formatMessage(messages.error)}</div>
        ) : (
          <iframe
            width="100%"
            height="100%"
            src={linkUrl}
            title={formatMessage(messages.title)}
          />
        )}
      </Box>
    </>
  );

  return use2022Nav ? (
    <FormModalWithOptions
      isOpen
      onRequestClose={onCancel}
      onSubmit={onSubmit}
      submitText="Done"
      title={topNavProps.title}
    >
      {propertyVerificationContent}
    </FormModalWithOptions>
  ) : (
    <TopNavLayout {...topNavProps}>{propertyVerificationContent}</TopNavLayout>
  );
};
