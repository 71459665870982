import React, { createContext } from "react";

export const UserContext = createContext({
  user: {},
  providerInitialized: false,
});

export const UserProvider = ({ children, user }) => {
  return (
    <UserContext.Provider
      value={{
        user,
        providerInitialized: true,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
