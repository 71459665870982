import { defineMessages } from "react-intl";

export default defineMessages({
  notAuthenticatedtitle: {
    id: "accounts.AuthenticateModal.notAuthenticatedtitle",
    defaultMessage: "Authentication Status: Not Connected",
  },
  authenticatedtitle: {
    id: "accounts.AuthenticateModal.authenticatedtitle",
    defaultMessage: "Authentication Status: Connected",
  },
  description: {
    id: "accounts.AuthenticateModal.description",
    defaultMessage:
      "Build trust with brands from the start. Authenticate your propertes to validate audience reach and enrich your profile stats for optimal discovery by brands.",
  },
  learnMore: {
    id: "accounts.AuthenticateModal.learnMore",
    defaultMessage: "Learn more about authenticating your social accounts",
  },
  cancel: {
    id: "accounts.AuthenticateModal.cancel",
    defaultMessage: "Cancel",
  },
  connectProperty: {
    id: "accounts.AuthenticateModal.connectProperty",
    defaultMessage: "Connect Property",
  },
  editConnection: {
    id: "accounts.AuthenticateModal.editConnection",
    defaultMessage: "Edit connection",
  },
});
