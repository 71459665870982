import React, { createContext } from "react";
import { useCurrentCompany, useUserContext } from "../hooks";
import { ErrorPage } from "components/ErrorPage";
import { useDecryptInfluencerToken } from "hooks/useDecryptInfluencerToken";
import { getWizardCompleteCookie } from "views/InfluencerWizard/influencerCookies";
import { redirectToMember } from "utils/util";

export const AuthenticationContext = createContext({
  authenticatedUser: {},
  providerInitialized: false,
});

const isImpersonating = (user) => !!user.impersonator;
const isAuthorizedEmployee = (user) =>
  user.isEmployee && user.employeeRoles.includes("internal_support");
const isAuthorizedImpersonator = (user) =>
  user.impersonator.isEmployee &&
  user.impersonator.employeeRoles.includes("internal_support");

const isInternalSupportEmployee = (user) => {
  return isImpersonating(user)
    ? isAuthorizedImpersonator(user)
    : isAuthorizedEmployee(user);
};

const isPublisher = (currentCompany) => "publisher" === currentCompany?.type;

export const AuthenticationProvider = ({ children }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const influencerToken = queryParams.get("influencerToken");

  const { user } = useUserContext();
  const currentCompany = useCurrentCompany();
  const { data: decryptedToken } = useDecryptInfluencerToken(influencerToken);

  const creatorId = decryptedToken?.creatorId;
  const creatorCompletedWizard = getWizardCompleteCookie(creatorId);

  if (creatorCompletedWizard) {
    redirectToMember();
    return null;
  }

  // don't show "Unauthorized" before we identify the user
  if (!user) {
    return null;
  }

  const isAuthorized =
    isPublisher(currentCompany) || isInternalSupportEmployee(user);

  return isAuthorized ? (
    <AuthenticationContext.Provider
      value={{
        providerInitialized: true,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  ) : (
    <ErrorPage errorCode={403} />
  );
};
