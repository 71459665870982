const platformToHelpTextMap = {
  INSTAGRAM: ["@myprofile", "https://www.instagram.com/myprofile/"],
  FACEBOOK: ["myprofile", "https://www.facebook.com/myprofile"],
  YOUTUBE: ["@myprofile", "https://www.youtube.com/@myprofile"],
  TWITTER: ["@myprofile", "https://twitter.com/myprofile"],
  PINTEREST: ["@myprofile", "https://www.pinterest.com/myprofile/"],
  TWITCH: ["myprofile", "https://twitch.tv/myprofile"],
  TIKTOK: ["@myprofile", "https://www.tiktok.com/@myprofile"],
};

export const socialMediaPlatformHelpTextParams = (platform) => {
  const key = (platform || "").toUpperCase();
  return platformToHelpTextMap[key];
};
