import {
  ApolloProvider as BaseApolloProvider,
  ApolloClient,
  HttpLink,
} from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { onError } from "@apollo/client/link/error";
import { getIntrospectionQuery } from "graphql";
import * as R from "ramda";
import React, { useEffect, useState } from "react";

import { redirectToLogin } from "../auth";
import { authenticatedFetch } from "../utils/util";

export const ApolloProvider = ({ children }) => {
  const [client, setClient] = useState();

  useEffect(() => {
    const createClient = () => {
      const introspectionQueryResults = getIntrospectionQuery();

      const httpLink = new HttpLink({
        uri: `${process.env.REACT_APP_ACCOUNTS_API_URL}/graphql`,
        fetch: authenticatedFetch,
      });

      const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          const errorMessages = graphQLErrors.map((error) => ({
            message: error.message,
            code: error.extensions.code,
          }));
          console.error(`GraphQL errors: ${JSON.stringify(errorMessages)}`);

          if (
            R.any(
              (error) =>
                error.extensions.code === "UNAUTHENTICATED" ||
                error.extensions.code === "FORBIDDEN",
              graphQLErrors
            )
          ) {
            redirectToLogin();
          }
        }

        if (networkError) {
          console.error(`There was a network error`);
        }
      });

      const link = errorLink.concat(httpLink);

      const cache = new InMemoryCache({
        addTypename: true,
        dataIdFromObject: (o) => o.id,
        possibleTypes: introspectionQueryResults.possibleTypes,
      });

      const client = new ApolloClient({
        cache,
        link,
        defaultOptions: {
          watchQuery: {
            fetchPolicy: "no-cache",
          },
          query: {
            fetchPolicy: "no-cache",
          },
        },
      });

      setClient(client);
    };
    createClient();
  }, []);

  return client ? (
    <BaseApolloProvider client={client}>{children}</BaseApolloProvider>
  ) : null;
};
