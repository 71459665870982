import React, { createContext } from "react";

export const NavigationContext = createContext({
  use2022Nav: false,
  providerInitialized: false,
});

export const NavigationProvider = ({ children, use2022Nav }) => {
  return (
    <NavigationContext.Provider
      value={{
        use2022Nav,
        providerInitialized: true,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
