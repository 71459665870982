import { defineMessages } from "react-intl";

export default defineMessages({
  label: {
    id: "accounts.LanguageSelectField.label",
    defaultMessage: "Language",
  },
  required: {
    id: "accounts.LanguageSelectField.required",
    defaultMessage: "Language is required",
  },
});
