import { ButtonWithDropdown } from "@cjdev-internal/visual-stack-x/ButtonWithDropdown";
import { ListView } from "@cjdev-internal/visual-stack-x/ListView";
import { SocialIcon } from "@cjdev-internal/visual-stack-x/components/SocialIcon";
import { useState } from "react";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { Row } from "@cjdev-internal/visual-stack-x/Row";
import messages from "./messages";
import { useIntl } from "../../hooks/index.js";
import { useSocialPlatformOptions } from "../promotional_properties/PropertyTypes/SocialPropertyTypeWithCiq/useSocialPlatformOptions.js";

export const PropertyButtonWithDropdown = ({ addSocialProperty }) => {
  const [defExpanded, setDefExpanded] = useState(false);
  const { formatMessage } = useIntl();
  const options = useSocialPlatformOptions();

  return (
    <ButtonWithDropdown
      buttonContent={formatMessage(messages.addSocialProperties)}
      expanded={defExpanded}
      doExpand={setDefExpanded}
    >
      <ListView
        indexer={(option) => option.value}
        itemStyle={{ padding: "5px" }}
        onClick={(option) => {
          addSocialProperty(option.value.toLowerCase());
          setDefExpanded(false);
        }}
        renderContent={(option) => {
          return (
            <Row gap="medium" align="center">
              <SocialIcon icon={option.value.toLowerCase()} size={20} />
              <Text>{option.label}</Text>
            </Row>
          );
        }}
        data={options}
      />
    </ButtonWithDropdown>
  );
};
