import { useState } from "react";
import { gql, useApolloClient } from "@apollo/client";
import {
  STATE_DELETED,
  STATE_EDITED,
  STATE_VALIDATED,
} from "views/InfluencerWizard/editingStates";
import { isSocial, isWebsite } from "..";

const notEdited = (property) => !property.state;

const isEditedWebsite = (property) =>
  property.state === STATE_EDITED && isWebsite(property);

const isValidatedSocial = (property) =>
  property.state === STATE_VALIDATED && isSocial(property);

const isPropertyToAdd = (property) =>
  !property.id &&
  (notEdited(property) ||
    isEditedWebsite(property) ||
    isValidatedSocial(property));

const isPropertyToUpdate = (property) =>
  property.id && (isValidatedSocial(property) || isEditedWebsite(property));

const isPropertyToDelete = (property) =>
  property.state === STATE_DELETED && property.id;

const propertiesToPersist = (promotionalPropertiesForSignup) => {
  const additions = [];
  const updates = [];
  const deletes = [];

  let newPrimaryProperty;

  promotionalPropertiesForSignup.forEach((property) => {
    if (isPropertyToAdd(property)) {
      additions.push(property);
    } else if (isPropertyToUpdate(property)) {
      updates.push(property);
    } else if (isPropertyToDelete(property)) {
      deletes.push(property);
    }
  });

  const previousPrimaryProperty = deletes.find(
    (property) => property.isPrimary
  );
  if (previousPrimaryProperty) {
    const propertyFromInitialImport = promotionalPropertiesForSignup.find(
      (p) => p.id && notEdited(p)
    );
    const randomIdx = Math.floor(Math.random() * additions.length);
    const userAddedProperty = additions.splice(randomIdx, 1).pop();

    newPrimaryProperty = propertyFromInitialImport || userAddedProperty;
    previousPrimaryProperty.isPrimary = false;
  }

  return {
    additions,
    updates,
    deletes,
    newPrimaryProperty,
  };
};

const propertyName = (property) =>
  (isSocial(property) ? property.socialMediaHandle : property.url).substring(
    0,
    60
  );

const propertyTypeDetails = (property) =>
  isSocial(property)
    ? {
        type: "SOCIAL_MEDIA",
        socialMediaPlatform: property.socialMediaPlatform.toUpperCase(),
        socialMediaHandle: property.socialMediaHandle,
      }
    : {
        type: "WEBSITE",
        websiteUrl: property.url,
      };

const create = (property, publisherId) => {
  return {
    status: "ACTIVE",
    isPrimary: true,
    name: propertyName(property),
    promotionalModels: [
      {
        isPrimary: true,
        type: "INFLUENCER",
      },
    ],
    propertyTypeDetails: propertyTypeDetails(property),
    publisherId: publisherId.toString(),
    tags: [],
  };
};

const update = (property, publisherId, shouldArchive) => {
  return {
    id: property.id,
    status: shouldArchive ? "ARCHIVED" : "ACTIVE",
    isPrimary: !shouldArchive,
    name: propertyName(property),
    propertyTypeDetails: propertyTypeDetails(property),
    promotionalModels: [
      {
        type: "INFLUENCER",
        isPrimary: true,
      },
    ],
    publisherId,
    tags: [],
  };
};

const updatePrimaryPropertyQuery = gql(`
  mutation($update: UpdatePromotionalPropertyInput) {
    update: updatePromotionalProperty(input: $update) {
      id
    }
  }
`);

const createPrimaryPropertyQuery = gql(`
  mutation($create: CreatePromotionalPropertyInput) {
    create:createPromotionalProperty(input: $create) {
      id
    }
  }
`);

export const useSavePromotionalProperties = () => {
  const [isSaving, setIsSaving] = useState(false);
  const client = useApolloClient();

  const saveProperties = async (
    promotionalPropertiesForSignup = [],
    publisherId
  ) => {
    const {
      additions,
      updates,
      deletes,
      newPrimaryProperty,
    } = propertiesToPersist(promotionalPropertiesForSignup);

    if (additions.length + updates.length + deletes.length === 0) {
      return Promise.resolve("NOT_SAVED");
    }

    setIsSaving(true);
    if (newPrimaryProperty) {
      const mutation = newPrimaryProperty.id
        ? updatePrimaryPropertyQuery
        : createPrimaryPropertyQuery;

      const variables = newPrimaryProperty.id
        ? { update: update(newPrimaryProperty, publisherId) }
        : { create: create(newPrimaryProperty, publisherId) };

      await client.mutate({
        mutation,
        variables,
      });
    }

    const additionVars = additions.map(
      (_, i) => `$create${i}: CreatePromotionalPropertyInput!`
    );
    const updateVars = updates.map(
      (_, i) => `$update${i}: UpdatePromotionalPropertyInput!`
    );
    const deleteVars = deletes.map(
      (_, i) => `$delete${i}: UpdatePromotionalPropertyInput!`
    );

    const additionOps = additions.map(
      (_, i) =>
        `create_${i}: createPromotionalProperty(input: $create${i}) { id }`
    );
    const updateOps = updates.map(
      (_, i) =>
        `update_${i}: updatePromotionalProperty(input: $update${i}) { id }`
    );
    const deleteOps = deletes.map(
      (_, i) =>
        `delete_${i}: updatePromotionalProperty(input: $delete${i}) { id }`
    );

    const mutationVars = additionVars
      .concat(updateVars)
      .concat(deleteVars)
      .join(",\n");

    const mutationOps = additionOps
      .concat(updateOps)
      .concat(deleteOps)
      .join("\n");

    const mutation = `mutation(
    ${mutationVars}
    ) {
    ${mutationOps}
    }`;

    const variables = {};

    additions.forEach((property, i) => {
      const variableName = `create${i}`;
      variables[variableName] = create(property, publisherId);
    });

    updates.forEach((property, i) => {
      const variableName = `update${i}`;
      variables[variableName] = update(property, publisherId, false);
    });

    deletes.forEach((property, i) => {
      const variableName = `delete${i}`;
      variables[variableName] = update(property, publisherId, true);
    });

    try {
      await client.mutate({
        mutation: gql(mutation),
        variables,
      });

      return "SAVED";
    } catch (e) {
      return "ERROR";
    } finally {
      setIsSaving(false);
    }
  };

  return [saveProperties, isSaving];
};
