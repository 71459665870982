import React, { createContext, useState } from "react";

export const ErrorNotificationContext = createContext({
  errorNotification: null,
  setErrorNotification: null,
  providerInitialized: false,
});

export const ErrorNotificationProvider = ({ children }) => {
  const [errorNotification, setErrorNotification] = useState(null);

  return (
    <ErrorNotificationContext.Provider
      value={{
        errorNotification,
        setErrorNotification,
        providerInitialized: true,
      }}
    >
      {children}
    </ErrorNotificationContext.Provider>
  );
};
