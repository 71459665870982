import { FormattedMessage as IntlFormattedMessage } from "react-intl";
import { FormattedMessage as PlatformFormattedMessage } from "@platform/cj-platform-navigation";
import { useNewNavigation } from "../../hooks";

export const FormattedMessage = ({ id, defaultMessage, values }) => {
  const { use2022Nav } = useNewNavigation();
  return use2022Nav ? (
    <PlatformFormattedMessage
      id={id}
      defaultMessage={defaultMessage}
      values={values}
    />
  ) : (
    <IntlFormattedMessage
      id={id}
      defaultMessage={defaultMessage}
      values={values}
    />
  );
};
