import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "accounts.InfluencerSigupForm.title",
    defaultMessage: "Get started by verifying your email",
  },
  emailLabel: {
    id: "accounts.InfluencerSigupForm.emailLabel",
    defaultMessage: "Email",
  },
  emailInvalid: {
    id: "accounts.InfluencerSigupForm.emailInvalid",
    defaultMessage: "Email is invalid",
  },
  passwordLabel: {
    id: "accounts.InfluencerSigupForm.passwordLabel",
    defaultMessage: "Password",
  },
  confirmPasswordLabel: {
    id: "accounts.InfluencerSigupForm.confirmPasswordLabel",
    defaultMessage: "Confirm Password",
  },
  passwordsMustMatch: {
    id: "accounts.InfluencerSigupForm.passwordsMustMatch",
    defaultMessage: "The passwords must match",
  },
  submitButton: {
    id: "accounts.InfluencerSigupForm.submitButton",
    defaultMessage: "Verify Email",
  },
  already: {
    id: "accounts.InfluencerSigupForm.already",
    defaultMessage: "Already have an account with CJ Affiliate?",
  },
  login: {
    id: "accounts.InfluencerSigupForm.login",
    defaultMessage: "Log in here",
  },
});
