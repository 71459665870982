import { defineMessages } from "react-intl";

export default defineMessages({
  browserExtensionLabel: {
    id: "accounts.BrowserExtensionPropertyType.browserExtensionLabel",
    defaultMessage: "Browser Extension",
  },
  browserExtensionHelp: {
    id: "accounts.BrowserExtensionPropertyType.browserExtensionHelp",
    defaultMessage:
      "My customers view my content on an extension, add-on or toolbar that they download onto their web browser.",
  },
  browserExtensionSoftwareName: {
    id: "accounts.BrowserExtensionPropertyType.browserExtensionSoftwareName",
    defaultMessage: "Software Name",
  },
  browserExtensionSoftwareNamePlaceholder: {
    id:
      "accounts.BrowserExtensionPropertyType.browserExtensionSoftwareNamePlaceholder",
    defaultMessage: "Enter name",
  },
  browserExtensionPlatform: {
    id: "accounts.BrowserExtensionPropertyType.browserExtensionPlatform",
    defaultMessage: "Platform",
  },
  browserExtensionPlatformChrome: {
    id: "accounts.BrowserExtensionPropertyType.browserExtensionPlatformChrome",
    defaultMessage: "Chrome",
  },
  browserExtensionPlatformFirefox: {
    id: "accounts.BrowserExtensionPropertyType.browserExtensionPlatformFirefox",
    defaultMessage: "Firefox",
  },
  browserExtensionPlatformSafari: {
    id: "accounts.BrowserExtensionPropertyType.browserExtensionPlatformSafari",
    defaultMessage: "Safari",
  },
  browserExtensionPlatformOpera: {
    id: "accounts.BrowserExtensionPropertyType.browserExtensionPlatformOpera",
    defaultMessage: "Opera",
  },
  chromeDownloadLinkPlaceholder: {
    id: "accounts.BrowserExtensionPropertyType.chromeDownloadLinkPlaceholder",
    defaultMessage: "Download Link (must start with https://chrome.google.com)",
  },
  firefoxDownloadLinkPlaceholder: {
    id: "accounts.BrowserExtensionPropertyType.firefoxDownloadLinkPlaceholder",
    defaultMessage:
      "Download Link (must start with https://addons.mozilla.org)",
  },
  safariDownloadLinkPlaceholder: {
    id: "accounts.BrowserExtensionPropertyType.safariDownloadLinkPlaceholder",
    defaultMessage: "Download Link (must start with https://apps.apple.com)",
  },
  operaDownloadLinkPlaceholder: {
    id: "accounts.BrowserExtensionPropertyType.operaDownloadLinkPlaceholder",
    defaultMessage: "Download Link (must start with https://addons.opera.com)",
  },
  browserExtensionDownloadLinkPlaceholder: {
    id:
      "accounts.BrowserExtensionPropertyType.browserExtensionDownloadLinkPlaceholder",
    defaultMessage: "Enter URL",
  },
  browserExtensionDownloadLinkHelp: {
    id:
      "accounts.BrowserExtensionPropertyType.browserExtensionDownloadLinkHelp",
    defaultMessage: "Correctly formatted URL",
  },
  browserExtensionAgreement1: {
    id: "accounts.BrowserExtensionPropertyType.browserExtensionAgreement1",
    defaultMessage: "I have read and agree to the",
  },
  browserExtensionAgreement2: {
    id: "accounts.BrowserExtensionPropertyType.browserExtensionAgreement2",
    defaultMessage: "CJ Software Policy",
  },
  browserExtensionAdvancedOptions: {
    id: "accounts.BrowserExtensionPropertyType.browserExtensionAdvancedOptions",
    defaultMessage:
      "CJ requires all browser extensions to offer affirmative click, meaning your customers must actively click on the browser extension for CJ tracking to be activated. Browser extensions that track automatically without requiring a click from the customer (automatic redirect) require additional review.",
  },
  browserExtensionAffirmativeClick: {
    id:
      "accounts.BrowserExtensionPropertyType.browserExtensionAffirmativeClick",
    defaultMessage: "Browser extension uses only affirmative click (preferred)",
  },
  browserExtensionAutomaticRedirect: {
    id:
      "accounts.BrowserExtensionPropertyType.browserExtensionAutomaticRedirect",
    defaultMessage:
      "Browser extension uses automatic redirect with affirmative click upon request (uncommon)",
  },
  browserExtensionDescription: {
    id: "accounts.BrowserExtensionPropertyType.browserExtensionDescription",
    defaultMessage: "Description",
  },
  browserExtensionDescriptionPlaceholder: {
    id:
      "accounts.BrowserExtensionPropertyType.browserExtensionDescriptionPlaceholder",
    defaultMessage: "Enter description",
  },
  browserExtensionDescriptionHelp: {
    id: "accounts.BrowserExtensionPropertyType.browserExtensionDescriptionHelp",
    defaultMessage:
      "Please use the description box to describe how your browser extension functions and how customers engage with it. Completing this description and keeping it updated for any changes in functionality is required to be compliant with CJ’s Software Policy.",
  },
  browserExtensionPlatformHelp: {
    id: "accounts.BrowserExtensionPropertyType.browserExtensionPlatformHelp",
    defaultMessage:
      "Our publishers are expected to be transparent about any browser extensions they use to promote advertisers. You may not promote advertisers on your browser extension until the CJ Network Quality team approves it.",
  },
});
