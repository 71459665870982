import { useToast } from "@cjdev-internal/visual-stack-x/Toast";
import * as R from "ramda";
import { useIntl } from "./useIntl";
import messages from "./messages";

export const useErrorToast = () => {
  const [errorToastMount, show] = useToast();
  const { formatMessage } = useIntl();

  const showError = (error) => {
    if (typeof error === "string") {
      show({
        type: "warning",
        message: error,
        duration: 7000,
      });
      return;
    }

    if (error && error.errors) {
      R.forEach((error) => {
        show({
          type: "warning",
          message: error.defaultMessage,
          duration: 7000,
        });
      }, error.errors);
      return;
    }

    show({
      type: "warning",
      message: formatMessage(messages.unknownError),
      duration: 7000,
    });
  };

  return [errorToastMount, showError];
};
