import { authenticatedFetch } from "utils/util";
import { v4 as uuidv4 } from "uuid";

const getAuthHeaders = (accessToken) => ({
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
  mode: "cors",
});

export const getUser = async (accessToken, userId) => {
  const response = await fetch(
    `${process.env.REACT_APP_MEMBER_URL}/affapi/oauth/user/${userId}?includeActiveOnlyForBackwardsCompatibility=false`,
    getAuthHeaders(accessToken)
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error("Error fetching user");
  }
};

export const verifyUser = async (accessToken) => {
  const response = await fetch(
    `${process.env.REACT_APP_MEMBER_URL}/affapi/token/verify`,
    getAuthHeaders(accessToken)
  );
  if (response.status === 200) {
    return await response.json();
  } else {
    throw new Error("Error verifying token");
  }
};

export const getCompany = async (accessToken, companyId) => {
  const response = await fetch(
    `${process.env.REACT_APP_MEMBER_URL}/affapi/company/publisher/${companyId}`,
    getAuthHeaders(accessToken)
  );

  if (response.ok) {
    return await response.json();
  }

  if (response.status === 404) {
    return null;
  }

  throw new Error("Error fetching company");
};

export const getCountries = async (language) => {
  const response = await fetch(
    `${process.env.REACT_APP_MEMBER_URL}/member/api/country?language=${
      language || "en"
    }`
  );
  if (response.status === 200) {
    return await response.json();
  }
  throw new Error("Error fetching countries");
};

export const getBrandedSignupInfo = async (advertiserId) => {
  const response = await fetch(
    `${process.env.REACT_APP_MEMBER_URL}/affapi/branded-advertiser/${advertiserId}`
  );
  if (response.status === 200) {
    const data = await response.json();
    return data;
  }
  throw new Error("Error fetching branded advertiser info");
};

export const createPublisherApplication = async (data) => {
  const response = await fetch(
    `${process.env.REACT_APP_MEMBER_URL}/affapi/publishers/applications/`,
    {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(data),
    }
  );
  if (response.status === 200) {
    return await response.json();
  }
  if (response.status === 400) {
    throw response;
  }
  throw new Error("Error creating publisher application");
};

export const fetchTranslations = async (language, ...keys) => {
  const response = await fetch(
    `${
      process.env.REACT_APP_MEMBER_URL
    }/member/content.json?l=${language}&p=${keys.join(",")}`
  );

  return response.ok ? await response.json() : {};
};

export const fetchPubDevCreator = async (influencerToken) => {
  const url = `${process.env.REACT_APP_ACCOUNTS_API_URL}/pubdev-creator?influencerToken=${influencerToken}`;
  const response = await authenticatedFetch(url);

  if (!response.ok) {
    throw new Error("Error fetching pubdev creator");
  }

  const json = await response.json();

  const propertiesWithUuids = json.properties.map((property) => ({
    ...property,
    uuid: uuidv4(),
  }));

  return {
    ...json,
    properties: propertiesWithUuids,
  };
};

export const fetchPublisherProfile = async (publisherId) => {
  const response = await authenticatedFetch(
    `${process.env.REACT_APP_MEMBER_URL}/affapi/publisher/${publisherId}/profile`
  );
  if (!response.ok) {
    throw new Error("Error fetching publisher profile.");
  }

  return await response.json();
};

export const putUser = async (userId, user) => {
  const response = await authenticatedFetch(
    `${process.env.REACT_APP_MEMBER_URL}/affapi/oauth/user/${userId}`,
    {
      method: "PUT",
      body: JSON.stringify(user),
    }
  );

  if (!response.ok) {
    throw Object.assign(
      new Error("Error updating user"),
      await response.json()
    );
  }
};

export const putCompany = async (companyId, company) => {
  const response = await authenticatedFetch(
    `${process.env.REACT_APP_MEMBER_URL}/affapi/companies/${companyId}/address`,
    {
      method: "PUT",
      body: JSON.stringify(company),
    }
  );

  if (!response.ok) {
    throw Object.assign(
      new Error("Error updating company"),
      await response.json()
    );
  }
};

export const putCurrency = async (companyId, currencyCode) => {
  const response = await authenticatedFetch(
    `${process.env.REACT_APP_MEMBER_URL}/affapi/companies/${companyId}/functional-currency`,
    {
      method: "PUT",
      body: JSON.stringify({ currency: currencyCode }),
    }
  );

  if (!response.ok) {
    throw Object.assign(
      new Error("Error updating functional currency"),
      await response.json()
    );
  }
};

export const getAvailableFunctionalCurrencies = async () => {
  const response = await authenticatedFetch(
    `${process.env.REACT_APP_MEMBER_URL}/affapi/available-functional-currencies`
  );
  if (response.status === 200) {
    return await response.json();
  }
  throw new Error("Error fetching available functional currencies");
};

export const getPubDevCiqPublisherMapping = async (influencerSignupToken) => {
  const response = await fetch(
    `${process.env.REACT_APP_ACCOUNTS_API_URL}/pubdev-ciq-publisher-mapping?token=${influencerSignupToken}`
  );
  if (response.status === 200) {
    return await response.json();
  }
  if (response.status === 404) {
    return null;
  }
  throw new Error("Error while validating influencer signup token.");
};

export const decryptInfluencerToken = async (influencerToken) => {
  const url = `${process.env.REACT_APP_ACCOUNTS_API_URL}/decrypt-influencer-token/${influencerToken}`;
  const response = await authenticatedFetch(url);

  if (response.status === 401) {
    throw new Error("Unauthorized.");
  }

  if (response.status === 400) {
    throw new Error("Bad Request.");
  }

  const json = await response.json();

  return json;
};
