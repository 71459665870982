import { useFeatures, useIntl } from "hooks/index.js";
import messages from "./messages.js";

export const useSocialPlatformOptions = () => {
  const { formatMessage } = useIntl();
  const { hasFeature } = useFeatures();

  const options = [
    {
      value: "INSTAGRAM",
      label: formatMessage(messages.socialPlatformInstagram),
    },
    {
      value: "FACEBOOK",
      label: formatMessage(messages.socialPlatformFacebook),
    },
    {
      value: "YOUTUBE",
      label: formatMessage(messages.socialPlatformYouTube),
    },
    {
      value: "TWITTER",
      label: formatMessage(messages.socialPlatformTwitter),
    },
    {
      value: "PINTEREST",
      label: formatMessage(messages.socialPlatformPinterest),
    },
    {
      value: "TWITCH",
      label: formatMessage(messages.socialPlatformTwitch),
    },
    {
      value: "WHATSAPP",
      label: formatMessage(messages.socialPlatformWhatsApp),
    },
    {
      value: "SNAPCHAT",
      label: formatMessage(messages.socialPlatformSnapchat),
    },
    {
      value: "OTHER",
      label: formatMessage(messages.socialPlatformOther),
    },
  ];

  if (hasFeature("TIKTOK_SUPPORT")) {
    options.splice(options.length - 1, 0, {
      value: "TIKTOK",
      label: formatMessage(messages.socialPlatformTikTok),
    });
  }
  return options;
};
