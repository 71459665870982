import { Link } from "@cjdev-internal/visual-stack-x/Link.js";
import {
  ChoiceInput,
  Field,
  TextField,
} from "@cjdev-internal/visual-stack-x/legacy/Form";
import React, { useContext, useState } from "react";

import messages from "./messages.js";
import DetailsPanel from "../../../DetailsPanel/index.js";
import { FormikContext } from "../../PromotionalPropertyForm/index.js";
import { useIntl } from "hooks/index.js";
import BrowserSelection from "./BrowserSelection.js";

export default () => {
  const { formatMessage } = useIntl();
  const { handleChange, handleBlur, values, touched, errors } = useContext(
    FormikContext
  );

  const [softwarePolicyClicked, setSoftwarePolicyClicked] = useState(false);
  return (
    <>
      <Field help={formatMessage(messages.browserExtensionHelp)}>
        <ChoiceInput
          name="propertyTypeDetails.type"
          label={formatMessage(messages.browserExtensionLabel)}
          value="BROWSER_EXTENSION"
          checked={values.propertyTypeDetails.type === "BROWSER_EXTENSION"}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Field>
      {values.propertyTypeDetails.type === "BROWSER_EXTENSION" ? (
        <DetailsPanel>
          <TextField
            required
            label={formatMessage(messages.browserExtensionSoftwareName)}
            name="propertyTypeDetails.browserExtensionName"
            placeholder={formatMessage(
              messages.browserExtensionSoftwareNamePlaceholder
            )}
            value={values.propertyTypeDetails.browserExtensionName || ""}
            error={
              touched.propertyTypeDetails &&
              touched.propertyTypeDetails.browserExtensionName &&
              errors.propertyTypeDetails &&
              errors.propertyTypeDetails.browserExtensionName
            }
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <BrowserSelection />
          <ChoiceInput
            required
            key="choice-input"
            type="checkbox"
            name="propertyTypeDetails.browserExtensionAgreement"
            disabled={!softwarePolicyClicked}
            value={!!values.propertyTypeDetails.browserExtensionAgreement}
            onChange={(e) => handleChange(e)}
            label={
              <span>
                {formatMessage(messages.browserExtensionAgreement1) + " "}
                <Link
                  data-testid="cj-software-policy"
                  href="https://www.cj.com/legal/software-policy"
                  onClick={() => {
                    setSoftwarePolicyClicked(true);
                  }}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {formatMessage(messages.browserExtensionAgreement2)}
                </Link>
                .
              </span>
            }
          />
          <TextField
            help={formatMessage(messages.browserExtensionDescriptionHelp)}
            required
            label={formatMessage(messages.browserExtensionDescription)}
            name="propertyTypeDetails.browserExtensionDescription"
            placeholder={formatMessage(
              messages.browserExtensionDescriptionPlaceholder
            )}
            value={values.propertyTypeDetails.browserExtensionDescription || ""}
            error={
              touched.propertyTypeDetails &&
              touched.propertyTypeDetails.browserExtensionDescription &&
              errors.propertyTypeDetails &&
              errors.propertyTypeDetails.browserExtensionDescription
            }
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </DetailsPanel>
      ) : null}
    </>
  );
};
