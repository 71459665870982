import { useIntl } from "react-intl";
import { CJLogo } from "@cjdev-internal/visual-stack-x/CJLogo";
import {
  PageHeader,
  PageTitle,
} from "@cjdev-internal/visual-stack-x/PageHeader";
import { Row } from "@cjdev-internal/visual-stack-x/Row";
import { Stack } from "@cjdev-internal/visual-stack-x/Stack";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { useAdvertiserBrandedInfo } from "hooks/useAdvertiserBrandedInfo";
import { ErrorPage } from "components/ErrorPage";
import messages from "./messages";
import "./styles.css";
import { ExternalLink } from "components/ExternalLink";
import { useDecryptInfluencerToken } from "hooks/useDecryptInfluencerToken";
import { SpinnerPage } from "components/SpinnerPage";

export const InfluencerSignupLayout = ({
  advertiserId,
  children,
  influencerToken,
  showHeader = true,
}) => {
  const { formatMessage } = useIntl();

  const {
    data: decryptedToken,
    error: decryptTokenError,
  } = useDecryptInfluencerToken(influencerToken, advertiserId);

  advertiserId = advertiserId || decryptedToken?.advertiserId;

  const {
    data: advertiserBrandedInfo,
    error,
    isLoading,
  } = useAdvertiserBrandedInfo(advertiserId);

  if (isLoading) {
    return <SpinnerPage />;
  }

  if (error || decryptTokenError) {
    return <ErrorPage errorCode={500} messageOverride={error.message} />;
  }

  return (
    <Stack expand gap="2xl" className="branded-signup-layout">
      {showHeader && (
        <PageHeader>
          <Row expand align="center" gap="medium">
            <img
              alt={`${advertiserBrandedInfo.name} logo`}
              src={`https://signup.cj.com/member/publisher/logo/${advertiserBrandedInfo.smallLogoId}`}
            />
            <PageTitle>
              <Text className="title">
                {advertiserBrandedInfo.name} {formatMessage(messages.program)}
              </Text>
            </PageTitle>
          </Row>
        </PageHeader>
      )}

      <Stack expand>{children}</Stack>

      <Row
        padding="2xl"
        align="center"
        justify="space-between"
        className="footer"
      >
        <Row gap="medium">
          <CJLogo />
          <Stack>
            <Text weight="medium">
              {formatMessage(messages.byJoining, [advertiserBrandedInfo.name])}
            </Text>
            <Text>{formatMessage(messages.signing)}</Text>
            <Text>
              <ExternalLink href="https://www.cj.com/publisher">
                {formatMessage(messages.learn)}
              </ExternalLink>
            </Text>
          </Stack>
        </Row>
        <Row gap="large">
          <Text color="secondary">
            &copy; {new Date().getFullYear()} Commission Junction LLC
          </Text>
          <ExternalLink href="https://www.cj.com/legal/privacy">
            <Text color="secondary">{formatMessage(messages.privacy)}</Text>
          </ExternalLink>
          <ExternalLink href="https://www.cj.com/support-center">
            <Text color="secondary">{formatMessage(messages.support)}</Text>
          </ExternalLink>
        </Row>
      </Row>
    </Stack>
  );
};
