import { Field } from "@cjdev-internal/visual-stack-x/legacy/Form";
import { CreatableSelect } from "@cjdev-internal/visual-stack-x/CreatableSelect.js";
import React, { useContext } from "react";

import messages from "./messages.js";
import "./styles.css";
import { FormikContext } from "../PromotionalPropertyForm/index.js";
import { useIntl } from "hooks/index.js";

const PropertyTags = () => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue } = useContext(FormikContext);

  return (
    <Field>
      <CreatableSelect
        name="tags"
        isMulti
        value={values.tags.map((tag) => ({ value: tag.name, label: tag.name }))}
        components={{ DropdownIndicator: null }}
        placeholder={formatMessage(messages.propertyTagsPlaceholder)}
        onChange={(selectedOptions) => {
          setFieldValue("tags", [
            ...selectedOptions.map((selectedOption) => ({
              name: selectedOption.value,
            })),
          ]);
        }}
      />
    </Field>
  );
};

export default () => {
  const { formatMessage } = useIntl();

  const getPropertyTagHelpText = () => {
    return (
      <div>
        {formatMessage(messages.publicPropertyTagsHelp)}
        <br />
        <br />
        <div className={"italicized"}>
          {formatMessage(messages.propertyTagsIdeas1)}
          <br />
          {formatMessage(messages.propertyTagsIdeas2)}
          <br />
          {formatMessage(messages.propertyTagsIdeas3)}
          <br />
          {formatMessage(messages.propertyTagsIdeas4)}
          <br />
          {formatMessage(messages.propertyTagsIdeas5)}
          <br />
          {formatMessage(messages.propertyTagsIdeas6)}
          <br />
          {formatMessage(messages.propertyTagsIdeas7)}
          <br />
          {formatMessage(messages.propertyTagsIdeas8)}
          <br />
        </div>
      </div>
    );
  };

  return (
    <>
      <section className="property-tags-section">
        <Field
          label={formatMessage(messages.publicPropertyTagsSubHeader)}
          help={getPropertyTagHelpText()}
        />
        <PropertyTags />
      </section>
    </>
  );
};
