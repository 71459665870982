import { IntlProvider } from "react-intl";
import messages from "../../translations";
import { EmailConfirmation } from "../../views/EmailConfirmation";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Authorization } from "components/Authorization";

export const EmailConfirmationApp = () => {
  const locale =
    new URLSearchParams(window.location.search).get("locale") || "en";
  const queryClient = new QueryClient();

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <QueryClientProvider client={queryClient}>
        <Authorization>
          <EmailConfirmation />
        </Authorization>
      </QueryClientProvider>
    </IntlProvider>
  );
};
