import {
  ChoiceInput,
  Field,
  TextField,
} from "@cjdev-internal/visual-stack-x/legacy/Form";
import React, { useContext } from "react";

import messages from "./messages.js";
import DetailsPanel from "../../../DetailsPanel/index.js";
import { FormikContext } from "../../PromotionalPropertyForm/index.js";
import { useIntl } from "hooks/index.js";

export default () => {
  const { formatMessage } = useIntl();
  const { handleChange, handleBlur, values, touched, errors } = useContext(
    FormikContext
  );

  return (
    <>
      <Field help={formatMessage(messages.emailHelp)}>
        <ChoiceInput
          name="propertyTypeDetails.type"
          label={formatMessage(messages.emailLabel)}
          value="EMAIL"
          checked={values.propertyTypeDetails.type === "EMAIL"}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Field>
      {values.propertyTypeDetails.type === "EMAIL" ? (
        <DetailsPanel>
          <TextField
            required
            label={formatMessage(messages.email)}
            name="propertyTypeDetails.emailAddress"
            error={
              touched.propertyTypeDetails &&
              touched.propertyTypeDetails.emailAddress &&
              errors.propertyTypeDetails &&
              errors.propertyTypeDetails.emailAddress
            }
            value={values.propertyTypeDetails.emailAddress || ""}
            placeholder={formatMessage(messages.emailPlaceholder)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div className="property-type-row">
            <TextField
              required
              label={formatMessage(messages.emailMailingListSize)}
              name="propertyTypeDetails.emailMailingListSize"
              error={
                touched.propertyTypeDetails &&
                touched.propertyTypeDetails.emailMailingListSize &&
                errors.propertyTypeDetails &&
                errors.propertyTypeDetails.emailMailingListSize
              }
              placeholder={formatMessage(
                messages.emailMailingListSizePlaceholder
              )}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.propertyTypeDetails.emailMailingListSize || ""}
            />
            <TextField
              required
              label={formatMessage(messages.emailOpenRate)}
              name="propertyTypeDetails.emailOpenRate"
              error={
                touched.propertyTypeDetails &&
                touched.propertyTypeDetails.emailOpenRate &&
                errors.propertyTypeDetails &&
                errors.propertyTypeDetails.emailOpenRate
              }
              placeholder={formatMessage(messages.emailOpenRatePlaceholder)}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.propertyTypeDetails.emailOpenRate || ""}
            />
          </div>
        </DetailsPanel>
      ) : null}
    </>
  );
};
