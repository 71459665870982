import { Button } from "@cjdev-internal/visual-stack-x/Button.js";
import CloseIcon from "mdi-react/CloseIcon";
import React from "react";
import ReactSVG from "react-svg";

import infoSvg from "./alert-info.svg";
import successSvg from "./alert-success.svg";
import warningSvg from "./alert-warning.svg";
import "./styles.css";

const SuccessIcon = ({ className }) => (
  <ReactSVG src={successSvg} className={className} />
);

const InfoIcon = ({ className }) => (
  <ReactSVG src={infoSvg} className={className} />
);

const WarningIcon = ({ className }) => (
  <ReactSVG src={warningSvg} className={className} />
);

const iconTypeToIcon = {
  success: <SuccessIcon className="alert-icon" />,
  info: <InfoIcon className="alert-icon" />,
  warning: <WarningIcon className="alert-icon" />,
};

const alertClasses = {
  success: "alert-success",
  info: "alert-info",
  error: "alert-error",
};

class Alert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dismissed: false,
    };
    this.closeAlert = this.closeAlert.bind(this);
  }

  componentDidMount() {
    if (this.props.autoHide) {
      const timeoutId = setTimeout(this.closeAlert, this.props.timeout || 5000);
      this.setState({ timeoutId });
    }
  }

  componentWillUnmount() {
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }
  }

  getClasses() {
    let classNames = ["alert"];

    if (this.props.type) {
      classNames.push(alertClasses[this.props.type]);
    }

    if (this.props.className) {
      classNames.push(this.props.className);
    }

    return classNames.join(" ");
  }

  closeAlert() {
    this.setState({ dismissed: true });
    this.props.onHide && this.props.onHide();
  }

  getIcon() {
    return iconTypeToIcon[this.props.iconType] || iconTypeToIcon["info"];
  }

  getCloseButton() {
    return this.props.closeType === "icon" ? (
      <CloseIcon
        onClick={this.closeAlert}
        className="alert-close-icon"
        data-testid="alert-close-icon"
      />
    ) : this.props.closeType === "button" ? (
      <Button
        onClick={this.closeAlert}
        type="secondary"
        className={"alert-close-button"}
      >
        Dismiss
      </Button>
    ) : null;
  }

  render() {
    if (this.state.dismissed) {
      return null;
    }

    return (
      <div name={this.props.name} className="alert">
        {this.getIcon()}
        <span className="content">{this.props.children}</span>
        {this.getCloseButton()}
      </div>
    );
  }
}

export default Alert;
