import { useMutation } from "@tanstack/react-query";
import { putCompany, putCurrency, putUser } from "api";
import { useUserContext } from "hooks";
import { getUserId } from "utils/platformAuth";
import { setWizardCompleteCookieFor } from "../influencerCookies";
import { useDecryptInfluencerToken } from "hooks/useDecryptInfluencerToken";

export const useUserCompanyMutation = (influencerToken, options) => {
  const { user } = useUserContext();
  const { data } = useDecryptInfluencerToken(influencerToken);
  const creatorId = data?.creatorId;

  return useMutation({
    mutationKey: ["user-company"],
    mutationFn: async (userCompany) => {
      const userId = user.userId ? user.userId : await getUserId();

      await putUser(userId, {
        firstName: userCompany.firstName,
        lastName: userCompany.lastName,
        phoneNumber: userCompany.userPhoneNumber,
      });

      await putCompany(user.currentCompany.id, {
        name: userCompany.companyName,
        addressOne: userCompany.companyAddressOne,
        addressTwo: userCompany.companyAddressTwo,
        city: userCompany.companyCity,
        countrySubdivision: userCompany.companyState,
        country: userCompany.companyCountry,
        postalCode: userCompany.companyZipCode,
        phone: userCompany.companyPhoneNumber,
      });

      await putCurrency(user.currentCompany.id, userCompany.companyCurrency);
      setWizardCompleteCookieFor(creatorId);
    },
    ...options,
  });
};
