import { gql } from "@apollo/client";

const PROPERTY_TYPE_DETAILS_FRAGMENT = gql`
  fragment PromotionalPropertyTypeDetails on PromotionalProperty {
    propertyTypeDetails {
      type
      ... on PromotionalPropertyWebsiteDetails {
        websiteUrl
      }
      ... on PromotionalPropertySocialMediaDetails {
        socialMediaHandle
        socialMediaPlatform
      }
      ... on PromotionalPropertyEmailDetails {
        emailAddress
        emailMailingListSize
        emailOpenRate
      }
      ... on PromotionalPropertyMobileAppDetails {
        mobileAppName
        mobileAppDownloadInformation {
          mobileAppDownloadLink
          mobileAppPlatform
        }
      }
      ... on PromotionalPropertyBrowserExtensionDetails {
        browserExtensionName
        browserExtensionDescription
        browserExtensionDownloadInformation {
          browserExtensionBrowser
          browserExtensionDownloadLink
        }
      }
      ... on PromotionalPropertyServicesAndToolsDetails {
        servicesAndToolsMarketingSiteUrl
      }
      ... on PromotionalPropertyPaidDisplayAdsDetails {
        paidDisplayAdsDescription
      }
      ... on PromotionalPropertyPaidSearchDetails {
        paidSearchEngineDescription
      }
      ... on PromotionalPropertyOtherDetails {
        otherDescription
      }
    }
  }
`;

const PROMOTIONAL_MODELS_FRAGMENT = gql`
  fragment PromotionalModels on PromotionalProperty {
    promotionalModels {
      description
      isPrimary
      type
    }
  }
`;

const PROMOTIONAL_TAGS_FRAGMENT = gql`
  fragment PromotionalTags on PromotionalProperty {
    tags {
      name
    }
  }
`;

export const GET_PROPERTY_FOR_COPY = gql`
  query getPropertyForCopy($id: ID!) {
    promotionalProperty(id: $id) {
      publisherId
      name
      status
      description
      ...PromotionalTags
      ...PromotionalModels
      ...PromotionalPropertyTypeDetails
    }
  }
  ${PROMOTIONAL_TAGS_FRAGMENT}
  ${PROMOTIONAL_MODELS_FRAGMENT}
  ${PROPERTY_TYPE_DETAILS_FRAGMENT}
`;

export const GET_PROPERTY_FOR_EDIT = gql`
  query getPropertyForEdit($id: ID!) {
    promotionalProperty(id: $id) {
      id
      publisherId
      name
      status
      description
      isPrimary
      ...PromotionalTags
      ...PromotionalModels
      ...PromotionalPropertyTypeDetails
    }
  }
  ${PROMOTIONAL_TAGS_FRAGMENT}
  ${PROMOTIONAL_MODELS_FRAGMENT}
  ${PROPERTY_TYPE_DETAILS_FRAGMENT}
`;
