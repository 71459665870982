import { IconButtonWithDropdown } from "@cjdev-internal/visual-stack-x/ButtonWithDropdown";
import { Stack } from "@cjdev-internal/visual-stack-x/Stack";
import classNames from "classnames";
import * as R from "ramda";
import React, { useCallback, useEffect, useState, useRef } from "react";
import "./style.css";

export const ActionsButton = ({ actions, className, ...restProps }) => {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef(null);

  const handleClick = useCallback(
    (e) => {
      if (!ref.current.contains(e.target)) {
        setExpanded(false);
      }
    },
    [ref]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);
    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, [handleClick]);

  return (
    <div ref={ref}>
      <IconButtonWithDropdown
        {...restProps}
        icon="dots-vertical"
        className={classNames("actions-button", className)}
        expanded={expanded}
        doExpand={() => {
          setExpanded(!expanded);
        }}
      >
        <Stack>
          {R.map((action) => {
            if (!action) {
              // eslint-disable-next-line array-callback-return
              return;
            }

            return (
              <div
                key={action.label}
                className="actions-button-action"
                onClick={action.onClick}
              >
                {action.label}
              </div>
            );
          }, actions)}
        </Stack>
      </IconButtonWithDropdown>
    </div>
  );
};
