import React, { useContext } from "react";
import * as R from "ramda";
import { useIntl } from "hooks/index.js";
import { FormikContext } from "../../PromotionalPropertyForm/index.js";
import {
  ChoiceInput,
  Field,
  Input,
} from "@cjdev-internal/visual-stack-x/legacy/Form";
import messages from "./messages.js";
import "./styles.css";

const getMobilePlatformError = R.path([
  "propertyTypeDetails",
  "mobileAppDownloadInformation",
]);

const getMobileIOSPlatformError = R.path([
  "propertyTypeDetails",
  "mobileAppDownloadInformation",
  "ios",
]);
const getMobileAndroidPlatformError = R.path([
  "propertyTypeDetails",
  "mobileAppDownloadInformation",
  "android",
]);

const parsePlatformErrors = (errors) => {
  const mobilePlatformError = getMobilePlatformError(errors);
  const mobileIOSPlatformError = getMobileIOSPlatformError(errors);
  const mobileAndroidPlatformError = getMobileAndroidPlatformError(errors);
  return {
    default:
      typeof mobilePlatformError === "string" ? mobilePlatformError : undefined,
    ios: mobileIOSPlatformError,
    android: mobileAndroidPlatformError,
  };
};

export default () => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue, errors, touched, setTouched } = useContext(
    FormikContext
  );
  const platformErrors = parsePlatformErrors(errors);
  const iosDownloadUrl = R.path([
    "propertyTypeDetails",
    "mobileAppDownloadInformation",
    "ios",
  ])(values);
  const isIosSelected = !R.isNil(iosDownloadUrl);
  const androidDownloadUrl = R.path([
    "propertyTypeDetails",
    "mobileAppDownloadInformation",
    "android",
  ])(values);
  const isAndroidSelected = !R.isNil(androidDownloadUrl);
  const handlePlatformCheckbox = (name) => (event) => {
    setTouched({
      propertyTypeDetails: {
        mobileAppDownloadInformation: true,
      },
    });
    const checkboxSelected = event.target.checked;
    const platformInput = checkboxSelected ? "" : undefined;
    setFieldValue(name, platformInput);
  };

  return (
    <div className="property-type-row" data-testid="mobile-app-platform">
      <Field
        required
        label={formatMessage(messages.mobileAppPlatform)}
        error={getMobilePlatformError(touched) && platformErrors.default}
      >
        <div className="mobile-platform-choice-container">
          <ChoiceInput
            onChange={handlePlatformCheckbox(
              "propertyTypeDetails.mobileAppDownloadInformation.ios"
            )}
            name="propertyTypeDetails.mobileAppDownloadInformation.ios"
            type="checkbox"
            label={formatMessage(messages.mobileAppPlatformIos)}
            checked={isIosSelected}
          />
          <div className="platform-input-container" data-testid="ios-platform">
            <Input
              trimmed
              disabled={!isIosSelected}
              onChange={(e) => {
                const url = e.target.value;
                setFieldValue(
                  "propertyTypeDetails.mobileAppDownloadInformation.ios",
                  url
                );
              }}
              name="propertyTypeDetails.mobileAppDownloadInformation.ios.downloadUrl"
              className="mobile-platform-choice-textbox"
              placeholder={formatMessage(messages.iosMobileAppDownloadLink)}
              value={iosDownloadUrl || ""}
            />
            {platformErrors.ios && (
              <div className="vsx-validation-error">{platformErrors.ios}</div>
            )}
          </div>
        </div>
        <div className="mobile-platform-choice-container">
          <ChoiceInput
            name="propertyTypeDetails.mobileAppDownloadInformation.android"
            type="checkbox"
            label={formatMessage(messages.mobileAppPlatformAndroid)}
            checked={isAndroidSelected}
            onChange={handlePlatformCheckbox(
              "propertyTypeDetails.mobileAppDownloadInformation.android"
            )}
          />
          <div
            className="platform-input-container"
            data-testid="android-platform"
          >
            <Input
              disabled={!isAndroidSelected}
              name="propertyTypeDetails.mobileAppDownloadInformation.android.downloadUrl"
              className="mobile-platform-choice-textbox"
              placeholder={formatMessage(messages.androidMobileAppDownloadLink)}
              onChange={(e) => {
                const url = e.target.value;
                setFieldValue(
                  "propertyTypeDetails.mobileAppDownloadInformation.android",
                  url
                );
              }}
              value={androidDownloadUrl || ""}
            />
            {platformErrors.android && (
              <div className="vsx-validation-error">
                {platformErrors.android}
              </div>
            )}
          </div>
        </div>
      </Field>
    </div>
  );
};
