import { defineMessages } from "react-intl";

export default defineMessages({
  success: {
    id: "accounts.CreatePromotionalProperty.success",
    defaultMessage: "Successfully created new promotional property.",
  },
  "errors.isPrimary": {
    id: "accounts.CreatePromotionalProperty.errors.isPrimary",
    defaultMessage:
      "One of your active promotional properties must be set as your primary promotional property.",
  },
  "errors.createdStatus": {
    id: "accounts.CreatePromotionalProperty.errors.createdStatus",
    defaultMessage:
      "Promotional properties must be created with Active status.",
  },
  "errors.publisherId": {
    id: "accounts.CreatePromotionalProperty.errors.publisherId",
    defaultMessage: "The publisher ID is invalid.",
  },
  "errors.tryAgain": {
    id: "accounts.CreatePromotionalProperty.error",
    defaultMessage: "Something went wrong. Please try again later.",
  },
});
