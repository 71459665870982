import { Box } from "@cjdev-internal/visual-stack-x/Box";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import "./styles.css";
import { FormattedMessage } from "../FormattedMessage";

export const Tip = ({ children }) => (
  <Box padding="medium-large" className="tip">
    <Text>
      <Text weight="bold">
        <FormattedMessage id="accounts.tip.title" defaultMessage="Tip:" />
      </Text>{" "}
      {children}
    </Text>
  </Box>
);
