import { defineMessages } from "react-intl";

export default defineMessages({
  BLANK_SLATE_TITLE: {
    id: "accounts.ViewPromotionalProperties.BlankSlate.blankSlateTitle",
    defaultMessage: "Manage Your Promotional Properties",
  },
  BLANK_SLATE_DESCRIPTION: {
    id: "accounts.ViewPromotionalProperties.BlankSlate.blankSlateDescription",
    defaultMessage:
      "This is where you will describe the properties where you will be promoting advertiser brands. It is also how advertisers will see and understand how you will be promoting them. Register all of the properties where you plan to place links.",
  },
  guideMe: {
    id: "accounts.ViewPromotionalProperties.BlankSlate.guideMe",
    defaultMessage: "Guide Me",
  },
});
