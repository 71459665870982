import {
  ChoiceInput,
  Field,
  TextArea,
} from "@cjdev-internal/visual-stack-x/legacy/Form";
import React, { useContext } from "react";

import messages from "./messages.js";
import DetailsPanel from "../../../DetailsPanel/index.js";
import { FormikContext } from "../../PromotionalPropertyForm/index.js";
import { useIntl } from "hooks/index.js";

export default () => {
  const { formatMessage } = useIntl();
  const { handleChange, handleBlur, values, touched, errors } = useContext(
    FormikContext
  );

  const propertyTypeDetailsTouched = touched.propertyTypeDetails || {};
  const propertyTypeDetailsErrors = errors.propertyTypeDetails || {};
  return (
    <>
      <Field help={formatMessage(messages.paidDisplayAdsHelp)}>
        <ChoiceInput
          name="propertyTypeDetails.type"
          label={formatMessage(messages.paidDisplayAdsLabel)}
          value="PAID_DISPLAY_ADS"
          checked={values.propertyTypeDetails.type === "PAID_DISPLAY_ADS"}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Field>
      {values.propertyTypeDetails.type === "PAID_DISPLAY_ADS" ? (
        <DetailsPanel>
          <div className="property-type-row">
            <Field
              required
              label={formatMessage(messages.paidDisplayAdsDescription)}
              optional={false}
              error={
                propertyTypeDetailsTouched.paidDisplayAdsDescription &&
                propertyTypeDetailsErrors.paidDisplayAdsDescription
              }
              help={formatMessage(messages.paidDisplayAdsDescriptionHelp)}
            >
              <TextArea
                trimmed
                name="propertyTypeDetails.paidDisplayAdsDescription"
                value={
                  values.propertyTypeDetails.paidDisplayAdsDescription || ""
                }
                placeholder={formatMessage(
                  messages.paidDisplayAdsDescriptionPlaceholder
                )}
                className={
                  propertyTypeDetailsTouched.paidDisplayAdsDescription &&
                  propertyTypeDetailsErrors.paidDisplayAdsDescription &&
                  "input-error"
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Field>
          </div>
        </DetailsPanel>
      ) : null}
    </>
  );
};
