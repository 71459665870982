import ClientOAuth2 from "client-oauth2";

import * as api from "./api";
import { authUrlBase, clientId, urlBase } from "./config";
import { getURI, redirect } from "./utils/util";
import { getBearerToken } from "./utils/platformAuth";

export const OAUTH_CALLBACK_PATH = "/oauth-callback";

export const rolesWithViewPermission = [
  "NON_SUPERUSER",
  "SUPERUSER",
  "OPERATOR",
  "ANALYZER",
  "BUSINESS",
  "TECHNICAL",
];
export const rolesWithManagePermission = [
  "NON_SUPERUSER",
  "SUPERUSER",
  "OPERATOR",
  "BUSINESS",
  "TECHNICAL",
];

const cjAuth = () => {
  const authServer = authUrlBase();
  const appBaseUrl = urlBase();

  return new ClientOAuth2({
    clientId: clientId(),
    authorizationUri: `${authServer}/auth`,
    redirectUri: `${appBaseUrl}${OAUTH_CALLBACK_PATH}`,
  });
};

export const redirectToLogin = () => {
  redirect(
    `${process.env.REACT_APP_MEMBER_URL}/member/login?appName=${process.env.REACT_APP_APP_NAME}&path=promotional-properties`
  );
};

export const oauthCallBack = async () => {
  const uri = getURI();
  const { data } = await cjAuth().token.getToken(uri);

  const { companyId } = JSON.parse(atob(data.state));

  if (!companyId) {
    redirectToLogin();
  }
};

export const getUser = async (userId) => {
  const accessToken = await getBearerToken();
  return await api.getUser(accessToken, userId);
};

export const verifyUser = async () => {
  const accessToken = await getBearerToken();
  return await api.verifyUser(accessToken);
};

export const userHasPermissions = (permissions, user, companyId = null) => {
  // In New Nav, the user's current company will be provided
  if (user.currentCompany) {
    const cc = user.currentCompany;
    const userIsEmployee = user.isEmployee || user.impersonator?.isEmployee;
    return (
      ["ACTIVE", "SETUP"].includes(cc.status) &&
      ["publisher"].includes(cc.type) &&
      (userIsEmployee || permissions.indexOf(cc.companyRole) > -1)
    );
  }

  // In Old Nav, the company ID is provided by a cookie
  const company = user.companies
    .filter((c) => c.status === "ACTIVE" || c.status === "SETUP")
    .filter((c) => c.type === "publisher")
    .find((c) => parseInt(c.id, 10) === parseInt(companyId, 10));

  return (
    user.isEmployee ||
    (!!company && permissions.indexOf(company.companyRole) > -1)
  );
};
