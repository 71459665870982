import { defineMessages } from "react-intl";

export default defineMessages({
  joinProgram: {
    id: "accounts.SignupWelcome.joinProgram",
    defaultMessage: "{advertiserName} Affiliate Program",
  },
  joinProgramCTA: {
    id: "accounts.SignupWelcome.joinProgramCTA",
    defaultMessage: "Join the {advertiserName} program",
  },
  welcome: {
    id: "accounts.SignupWelcome.welcome",
    defaultMessage: "Welcome! We're excited that you're here.",
  },
  applicationDescription: {
    id: "accounts.SignupWelcome.applicationDescription",
    defaultMessage:
      "Please complete the application to join the {programName}.",
  },
  applicationJoinDescription: {
    id: "accounts.SignupWelcome.applicationJoinDescription",
    defaultMessage:
      "Please complete the application to join the {0} affiliate program.",
  },
  existingAccountPrompt: {
    id: "accounts.SignupWelcome.existingAccountPrompt",
    defaultMessage: "Already have an account with CJ Affiliate?",
  },
  logIn: {
    id: "accounts.SignupWelcome.logIn",
    defaultMessage: "Log in here",
  },
  programHighlights: {
    id: "accounts.SignupWelcome.programHighlights",
    defaultMessage: "Program Highlights",
  },
  publisherLogin: {
    id: "all.foundation.login.branded_login_title",
    defaultMessage: "CJ Affiliate Publisher Login",
  },
  accountAlreadyExists: {
    id: "accounts.SignupWelcome.accountAlreadyExists",
    defaultMessage:
      "An account already exists for this influencer. Please login using that account.",
  },
});
