import { defineMessages } from "react-intl";

export default defineMessages({
  formTitle: {
    id: "accounts.InfluencerDescription.formTitle",
    defaultMessage: "Step 2/3",
  },
  introduceYourself: {
    id: "accounts.InfluencerWizard.description.introduceYourself",
    defaultMessage: "Introduce yourself to the community!",
  },
  thisWillHelp: {
    id: "accounts.InfluencerWizard.description.thisWillHelp",
    defaultMessage:
      "This will help others get to know you and help brands find you.",
  },
  profileDescription: {
    id: "accounts.InfluencerWizard.description.profileDescription",
    defaultMessage: "Enter A Profile Description",
  },
  minLengthHelp: {
    id: "accounts.InfluencerWizard.description.minLengthHelp",
    defaultMessage: "Please provide a description of at least {0} characters.",
  },
  minLengthSecondaryHelp: {
    id: "accounts.InfluencerWizard.description.minLengthSecondaryHelp",
    defaultMessage: "{0} of {1} characters used.",
  },
  changeDescriptionLater: {
    id: "accounts.InfluencerWizard.description.changeLater",
    defaultMessage: "You can always change this later.",
  },
});
