import { useHistoryContext } from "./useHistoryContext";
import { useNewNavigation } from "./useNewNavigation";

export const useHistory = () => {
  const { use2022Nav } = useNewNavigation();
  const { historyContext } = useHistoryContext();

  const navigateTo = (path) => {
    use2022Nav ? historyContext(path) : historyContext.push(path);
  };

  return {
    navigateTo,
  };
};
