import React, { useContext } from "react";
import {
  ChoiceInput,
  Field,
  Input,
} from "@cjdev-internal/visual-stack-x/legacy/Form";

import { useIntl } from "hooks/index.js";
import messages from "./messages.js";
import "./styles.css";
import { FormikContext } from "../../PromotionalPropertyForm/index.js";
import * as R from "ramda";

const BrowserOption = ({ browserType, labelMessage, placeholderMessage }) => {
  const { formatMessage } = useIntl();
  const { values, setFieldValue, errors, setTouched } = useContext(
    FormikContext
  );

  const downloadUrl = R.path([
    "propertyTypeDetails",
    "browserExtensionDownloadInformation",
    browserType,
  ])(values);
  const isBrowserSelected = !R.isNil(downloadUrl);

  const handleBrowserCheckbox = (name) => (event) => {
    setTouched({
      propertyTypeDetails: {
        browserExtensionDownloadInformation: true,
      },
    });
    const checkboxSelected = event.target.checked;
    const downloadUrl = checkboxSelected ? "" : undefined;
    setFieldValue(name, downloadUrl);
  };

  const browserExtensionDownloadInformationError = R.path([
    "propertyTypeDetails",
    "browserExtensionDownloadInformation",
  ])(errors);

  const extensionError =
    typeof browserExtensionDownloadInformationError === "object"
      ? browserExtensionDownloadInformationError[browserType]
      : undefined;

  return (
    <div className="browser-choice-container">
      <ChoiceInput
        name={`propertyTypeDetails.browserExtensionDownloadInformation.${browserType}`}
        type="checkbox"
        label={formatMessage(labelMessage)}
        checked={isBrowserSelected}
        onChange={handleBrowserCheckbox(
          `propertyTypeDetails.browserExtensionDownloadInformation.${browserType}`
        )}
      />
      <div
        className="browser-input-container"
        data-testid={`browser-${browserType}`}
      >
        <Input
          trimmed
          disabled={!isBrowserSelected}
          className="browser-choice-textbox"
          name={`propertyTypeDetails.browserExtensionDownloadInformation.${browserType}.downloadUrl`}
          placeholder={formatMessage(placeholderMessage)}
          value={downloadUrl || ""}
          onChange={(e) => {
            const url = e.target.value;
            setFieldValue(
              `propertyTypeDetails.browserExtensionDownloadInformation.${browserType}`,
              url
            );
          }}
        />
        {extensionError && (
          <div className="vsx-validation-error">{extensionError}</div>
        )}
      </div>
    </div>
  );
};

export default () => {
  const { formatMessage } = useIntl();
  const { errors, touched } = useContext(FormikContext);

  const browserExtensionDownloadInformationError = R.path([
    "propertyTypeDetails",
    "browserExtensionDownloadInformation",
  ])(errors);

  const normalizedBrowserExtensionDownloadInformationError =
    typeof browserExtensionDownloadInformationError === "string"
      ? browserExtensionDownloadInformationError
      : undefined;

  const browserExtensionDownloadInformationTouched = R.path([
    "propertyTypeDetails",
    "browserExtensionDownloadInformation",
  ])(touched);

  return (
    <div className="property-type-row" data-testid="browser-selection">
      <Field
        required
        help={formatMessage(messages.browserExtensionPlatformHelp)}
        label={formatMessage(messages.browserExtensionPlatform)}
        error={
          browserExtensionDownloadInformationTouched &&
          normalizedBrowserExtensionDownloadInformationError
        }
      >
        <BrowserOption
          browserType="chrome"
          labelMessage={messages.browserExtensionPlatformChrome}
          placeholderMessage={messages.chromeDownloadLinkPlaceholder}
        />
        <BrowserOption
          browserType="firefox"
          labelMessage={messages.browserExtensionPlatformFirefox}
          placeholderMessage={messages.firefoxDownloadLinkPlaceholder}
        />
        <BrowserOption
          browserType="safari"
          labelMessage={messages.browserExtensionPlatformSafari}
          placeholderMessage={messages.safariDownloadLinkPlaceholder}
        />
        <BrowserOption
          browserType="opera"
          labelMessage={messages.browserExtensionPlatformOpera}
          placeholderMessage={messages.operaDownloadLinkPlaceholder}
        />
      </Field>
    </div>
  );
};
