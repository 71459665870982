import { defineMessages } from "react-intl";

export default defineMessages({
  label: {
    id: "accounts.CountrySelectField.label",
    defaultMessage: "Country",
  },
  errorLoading: {
    id: "accounts.CountrySelectField.errorLoading",
    defaultMessage: "Error loading countries",
  },
});
