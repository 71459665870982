import React from "react";

// https://reactjs.org/docs/error-boundaries.html
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: "",
      stack: "",
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {
    const stackTrace = errorInfo.componentStack.split("\n");

    this.setState({
      ...this.state,
      errorMessage: error.message,
      stack: stackTrace,
    });
  }

  render() {
    const stackTrace =
      Array.isArray(this.state.stack) &&
      this.state.stack.map((stack) => (
        <div>
          {stack}
          <br />
        </div>
      ));

    if (this.state.hasError) {
      // Custom fallback UI
      return (
        <div>
          <h1>An error has occurred.</h1>
          <div>
            {this.state.errorMessage}
            <br />
            <div style={{ marginLeft: "4rem" }}>{stackTrace}</div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
