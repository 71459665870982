import { Button } from "@cjdev-internal/visual-stack-x/Button";
import {
  CJField,
  CJForm,
  EmailInput,
  TextInput,
  useCJForm,
} from "@cjdev-internal/visual-stack-x/CJForm";
import {
  FormPageBlock,
  FormPageLayout,
} from "@cjdev-internal/visual-stack-x/FormPageLayout";
import { Row } from "@cjdev-internal/visual-stack-x/Row";
import { Stack } from "@cjdev-internal/visual-stack-x/Stack";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import { Link } from "@cjdev-internal/visual-stack-x/Link";
import { createPublisherApplication, fetchTranslations } from "api";
import { useIntl } from "react-intl";
import { redirect } from "utils/util";
import { CountrySelectField } from "./CountrySelectField";
import { LanguageSelectField } from "./LanguageSelectField";
import messages from "./messages";
import { useAlert } from "@cjdev-internal/visual-stack-x/Alert.js";
import { useEffect } from "react";
import globalMessages from "messages/globalMessages";

export const INFLUENCER_ONE_TIME_LINK = "INFLUENCER_ONE_TIME_LINK";

export const InfluencerSignupForm = ({
  token,
  advertiserId,
  language,
  onLanguageChange,
}) => {
  const { formatMessage } = useIntl();
  const [alertMount, showAlert, closeAlert] = useAlert();

  const config = useCJForm({
    defaultValues: {
      language,
    },
  });

  const currentLanguage = config.watch("language");
  useEffect(() => {
    onLanguageChange && onLanguageChange(currentLanguage);
  }, [currentLanguage, onLanguageChange]);

  const onSubmit = async (data) => {
    try {
      const { resendToken } = await createPublisherApplication({
        brandedSignupAdvertiserCid: advertiserId,
        influencerSignupToken: token,
        language: data.language,
        country: data.country,
        email: data.email,
        password: data.password,
        signupSource: INFLUENCER_ONE_TIME_LINK,
      });

      const encodedEmail = encodeURIComponent(data.email);

      redirect(
        `/email-confirmation?advertiserId=${advertiserId}&email=${encodedEmail}&token=${resendToken}&locale=${data.language}&influencerToken=${token}`
      );
    } catch (e) {
      if (e instanceof Error) {
        const code = "global.error.tryagain";
        const translations = await fetchTranslations(data.language, code);
        const message =
          translations[code] ||
          "There was a problem processing your request.  Please try again.";
        showAlert({ onClose: closeAlert, type: "warning", children: message });
      } else {
        const body = await e.json();
        const { code, field, defaultMessage } = body.errors[0];
        const translations = await fetchTranslations(data.language, code);
        const message = translations[code] || defaultMessage;
        config.setError(field, { type: "custom", message });
      }
    }
  };

  return (
    <FormPageLayout>
      <FormPageBlock>
        <CJForm
          {...config}
          onSubmit={onSubmit}
          heading={formatMessage(messages.title)}
          showRequiredIndicator
          requiredIndicatorText={formatMessage(globalMessages.requiredFields)}
        >
          <LanguageSelectField />

          <CountrySelectField language={language} />

          <CJField
            htmlFor="email-input"
            name="email"
            label={formatMessage(messages.emailLabel)}
            required
          >
            <EmailInput
              id="email-input"
              name="email"
              required={formatMessage(globalMessages.requiredError)}
              invalidMessage={formatMessage(messages.emailInvalid)}
            />
          </CJField>

          <CJField
            htmlFor="password-input"
            name="password"
            label={formatMessage(messages.passwordLabel)}
            required
          >
            <TextInput
              id="password-input"
              name="password"
              type="password"
              required={formatMessage(globalMessages.requiredError)}
            />
          </CJField>

          <CJField
            htmlFor="confirm-password-input"
            name="confirmPassword"
            label={formatMessage(messages.confirmPasswordLabel)}
            required
          >
            <TextInput
              id="confirm-password-input"
              name="confirmPassword"
              type="password"
              required={formatMessage(globalMessages.requiredError)}
              validate={(value, otherValues) =>
                value === otherValues.password ||
                formatMessage(messages.passwordsMustMatch)
              }
            />
          </CJField>

          <Stack gap="xl" paddingTop="xl" paddingBottom="xl">
            <Button type="primary" htmlType="submit">
              {formatMessage(messages.submitButton)}
            </Button>
            <Row gap="small" justify="center">
              <Text>{formatMessage(messages.already)}</Text>
              <Link href={`${process.env.REACT_APP_MEMBER_URL}/member/login`}>
                <Text color="link">{formatMessage(messages.login)}</Text>
              </Link>
            </Row>
          </Stack>
        </CJForm>
      </FormPageBlock>
      {alertMount}
    </FormPageLayout>
  );
};
