import { Link as LinkFromPlatform } from "@platform/cj-platform-navigation";
import { useNewNavigation } from "hooks";
import React from "react";
import { Link as LinkFromRouter } from "react-router-dom";

export const Link = ({ ...rest }) => {
  const { use2022Nav } = useNewNavigation();
  return use2022Nav ? (
    <LinkFromPlatform {...rest} />
  ) : (
    <LinkFromRouter {...rest} />
  );
};
