import { usePromotionalProperties } from "./usePromotionalProperties";
import { usePubDevCreators } from "hooks/usePubDevCreators";

export const usePropertiesForSignup = (publisherId, influencerToken) => {
  const {
    data: cjProperties,
    error: cjError,
    loading: cjLoading,
  } = usePromotionalProperties(publisherId);

  const zeroCjProperties = cjProperties?.length === 0;

  const {
    data: ciqCreator,
    error: ciqError,
    isLoading: ciqLoading,
  } = usePubDevCreators(influencerToken, zeroCjProperties);

  const isLoading = cjLoading || (zeroCjProperties && ciqLoading);
  const isError = cjError || ciqError;

  return {
    propertiesForSignup: zeroCjProperties
      ? ciqCreator?.properties
      : cjProperties,
    isLoading,
    isError,
  };
};
