import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import CreatePromotionalProperty from "../../views/CreatePromotionalProperty";
import { EditPromotionalProperty } from "../../views/EditPromotionalProperty";
import Pendo from "../Pendo";
import ViewPromotionalProperties from "../../views/ViewPromotionalProperties";
import {
  COPY_PROPERTY,
  CREATE_PROPERTY,
  EDIT_PROPERTY,
  INFLUENCER_DESCRIPTION,
  INFLUENCER_ONBOARDING,
  INFLUENCER_USER_COMPANY,
  PROMOTIONAL_PROPERTIES,
  PROPERTY_VERIFICATION,
} from "../../paths";
import { PropertyVerificationPage } from "../../views/PropertyVerificationPage";
import {
  FormModal,
  PlatformApp,
  useNavigate,
} from "@platform/cj-platform-navigation";
import "./styles.css";
import translations from "../../translations";
import { NavigationViewProvider } from "context/NavViewProvider";
import { CopyPromotionalProperty } from "views/CopyPromotionalProperty";
import { CreateButton } from "components/CreateButton";
import {
  ApolloProvider,
  HistoryProvider,
  IntlProvider,
  NavigationProvider,
  UserProvider,
} from "context";
import { isProduction } from "../../config";
import messages from "./messages";
import { ErrorPage } from "components/ErrorPage";
import {
  rolesWithManagePermission,
  rolesWithViewPermission,
  userHasPermissions,
} from "auth";
import { PropertyImportWrapper } from "views/InfluencerWizard/PropertyImport/PropertyImportWrapper";
import { InfluencerDescriptionWrapper } from "views/InfluencerWizard/InfluencerDescription";
import { InfluencerUserCompanyWrapper } from "views/InfluencerWizard/InfluencerUserCompanyPage";
import { getWizardCompleteCookie } from "views/InfluencerWizard/influencerCookies";
import { redirectToMember } from "utils/util";
import { useDecryptInfluencerToken } from "hooks/useDecryptInfluencerToken";
import { useAdvertiserBrandedInfo } from "hooks/useAdvertiserBrandedInfo";

export const AppWithNav = () => {
  const routes = [
    {
      path: "/",
      layout: {
        filters: false,
        sidenav: false,
        dimensions: false,
      },
      titleMessage: messages.promotionalProperties,
      Content: () => {
        const navigate = useNavigate();
        useEffect(() => {
          navigate(PROMOTIONAL_PROPERTIES);
          // We have to return anything here, otherwise React will get stuck in an uncaught promise
          // and prevent us from redirecting to PROMOTIONAL_PROPERTIES route
        });
        return null;
      },
    },
    {
      path: PROMOTIONAL_PROPERTIES,
      layout: {
        filters: false,
        sidenav: false,
        pageTitle: true,
        dimensions: false,
        Buttons: true,
      },
      titleMessage: messages.promotionalProperties,
      Buttons: () => (
        <NavigationProvider use2022Nav={true}>
          <CreateButton />
        </NavigationProvider>
      ),
      Content: ({ user }) => {
        if (!userHasPermissions(rolesWithViewPermission, user)) {
          return <ErrorPage errorCode={403} />;
        }

        return (
          <NavigationViewProvider user={user}>
            <Pendo />
            <ViewPromotionalProperties />
          </NavigationViewProvider>
        );
      },
    },
    {
      path: CREATE_PROPERTY,
      layout: {
        filters: false,
        sidenav: false,
        dimensions: false,
      },
      titleMessage: { ...messages.createPromotionalProperty },
      Content: ({ user }) => {
        if (!userHasPermissions(rolesWithManagePermission, user)) {
          return <ErrorPage errorCode={403} />;
        }

        return (
          <NavigationViewProvider user={user}>
            <CreatePromotionalProperty />
          </NavigationViewProvider>
        );
      },
    },
    {
      path: EDIT_PROPERTY,
      layout: {
        filters: false,
        sidenav: false,
        dimensions: false,
      },
      titleMessage: { ...messages.editPromotionalProperty },
      Content: ({ user }) => {
        if (!userHasPermissions(rolesWithManagePermission, user)) {
          return <ErrorPage errorCode={403} />;
        }

        return (
          <NavigationViewProvider user={user}>
            <EditPromotionalProperty />
          </NavigationViewProvider>
        );
      },
    },
    {
      path: COPY_PROPERTY,
      layout: {
        filters: false,
        sidenav: false,
        dimensions: false,
      },
      titleMessage: { ...messages.copyPromotionalProperty },
      Content: ({ user }) => {
        if (!userHasPermissions(rolesWithManagePermission, user)) {
          return <ErrorPage errorCode={403} />;
        }

        return (
          <NavigationViewProvider user={user}>
            <CopyPromotionalProperty />
          </NavigationViewProvider>
        );
      },
    },
    {
      path: PROPERTY_VERIFICATION,
      layout: {
        filters: false,
        sidenav: false,
        dimensions: false,
      },
      titleMessage: { ...messages.properyAuthentication },
      Content: ({ user }) => {
        if (!userHasPermissions(rolesWithManagePermission, user)) {
          return <ErrorPage errorCode={403} />;
        }

        return (
          <NavigationViewProvider user={user}>
            <PropertyVerificationPage />
          </NavigationViewProvider>
        );
      },
    },
    {
      path: INFLUENCER_ONBOARDING,
      layout: {
        filters: false,
        sidenav: false,
        dimensions: false,
      },
      titleMessage: messages.onboardingChecklist,
      Content: ({ user }) => {
        const queryParams = new URLSearchParams(window.location.search);
        const influencerToken = queryParams.get("influencerToken");
        const navigate = useNavigate();
        const { data: decryptedToken } = useDecryptInfluencerToken(
          influencerToken
        );
        const { data: advertiserBrandedInfo } = useAdvertiserBrandedInfo(
          decryptedToken?.advertiserId
        );

        const creatorId = decryptedToken?.creatorId;
        const creatorCompletedWizard = getWizardCompleteCookie(creatorId);

        if (creatorCompletedWizard) {
          redirectToMember();
          return null;
        }

        return (
          <NavigationProvider use2022Nav={true}>
            <UserProvider user={user}>
              <ApolloProvider>
                <IntlProvider>
                  <HistoryProvider history={navigate}>
                    <FormModal
                      isOpen
                      logo={
                        advertiserBrandedInfo ? (
                          <img
                            alt={`${advertiserBrandedInfo?.name} logo`}
                            src={`https://signup.cj.com/member/publisher/logo/${advertiserBrandedInfo?.smallLogoId}`}
                          />
                        ) : (
                          <></>
                        )
                      }
                      title={
                        advertiserBrandedInfo ? (
                          `${advertiserBrandedInfo.name} Affiliate Program`
                        ) : (
                          <></>
                        )
                      }
                    >
                      <PropertyImportWrapper
                        user={user}
                        influencerToken={influencerToken}
                      />
                    </FormModal>
                  </HistoryProvider>
                </IntlProvider>
              </ApolloProvider>
            </UserProvider>
          </NavigationProvider>
        );
      },
    },
    {
      path: INFLUENCER_DESCRIPTION,
      layout: {
        filters: false,
        sidenav: false,
        dimensions: false,
      },
      titleMessage: messages.onboardingChecklist,
      Content: ({ user }) => {
        const queryParams = new URLSearchParams(window.location.search);
        const influencerToken = queryParams.get("influencerToken");
        const navigate = useNavigate();

        const { data: decryptedToken } = useDecryptInfluencerToken(
          influencerToken
        );
        const { data: advertiserBrandedInfo } = useAdvertiserBrandedInfo(
          decryptedToken?.advertiserId
        );

        const creatorId = decryptedToken?.creatorId;
        const creatorCompletedWizard = getWizardCompleteCookie(creatorId);

        if (creatorCompletedWizard) {
          redirectToMember();
          return null;
        }

        return (
          <NavigationProvider use2022Nav={true}>
            <UserProvider user={user}>
              <ApolloProvider>
                <IntlProvider>
                  <HistoryProvider history={navigate}>
                    <FormModal
                      isOpen
                      logo={
                        advertiserBrandedInfo ? (
                          <img
                            alt={`${advertiserBrandedInfo?.name} logo`}
                            src={`https://signup.cj.com/member/publisher/logo/${advertiserBrandedInfo?.smallLogoId}`}
                          />
                        ) : (
                          <></>
                        )
                      }
                      title={
                        advertiserBrandedInfo ? (
                          `${advertiserBrandedInfo.name} Affiliate Program`
                        ) : (
                          <></>
                        )
                      }
                    >
                      <InfluencerDescriptionWrapper
                        influencerToken={influencerToken}
                      />
                    </FormModal>
                  </HistoryProvider>
                </IntlProvider>
              </ApolloProvider>
            </UserProvider>
          </NavigationProvider>
        );
      },
    },
    {
      path: INFLUENCER_USER_COMPANY,
      layout: {
        filters: false,
        sidenav: false,
        dimensions: false,
      },
      titleMessage: messages.onboardingChecklist,
      Content: ({ user }) => {
        const queryParams = new URLSearchParams(window.location.search);
        const influencerToken = queryParams.get("influencerToken");
        const navigate = useNavigate();

        const { data: decryptedToken } = useDecryptInfluencerToken(
          influencerToken
        );
        const { data: advertiserBrandedInfo } = useAdvertiserBrandedInfo(
          decryptedToken?.advertiserId
        );

        const creatorId = decryptedToken?.creatorId;
        const creatorCompletedWizard = getWizardCompleteCookie(creatorId);

        if (creatorCompletedWizard) {
          redirectToMember();
          return null;
        }

        return (
          <NavigationProvider use2022Nav={true}>
            <UserProvider user={user}>
              <ApolloProvider>
                <IntlProvider>
                  <HistoryProvider history={navigate}>
                    <FormModal
                      isOpen
                      logo={
                        advertiserBrandedInfo ? (
                          <img
                            alt={`${advertiserBrandedInfo?.name} logo`}
                            src={`https://signup.cj.com/member/publisher/logo/${advertiserBrandedInfo?.smallLogoId}`}
                          />
                        ) : (
                          <></>
                        )
                      }
                      title={
                        advertiserBrandedInfo ? (
                          `${advertiserBrandedInfo.name} Affiliate Program`
                        ) : (
                          <></>
                        )
                      }
                    >
                      <InfluencerUserCompanyWrapper
                        influencerToken={influencerToken}
                      />
                    </FormModal>
                  </HistoryProvider>
                </IntlProvider>
              </ApolloProvider>
            </UserProvider>
          </NavigationProvider>
        );
      },
    },
    {
      // Wildcard route must always be last, otherwise it will override all other
      // routes that appear after it.
      path: "*",
      layout: {
        filters: false,
        sidenav: false,
        dimensions: false,
      },
      titleMessage: messages.promotionalProperties,
      Content: () => {
        return <ErrorPage errorCode={404} />;
      },
    },
  ];

  const platformDomains = isProduction() ? undefined : { members: "lab106" };
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <PlatformApp
        routes={routes}
        translations={translations}
        platformDomains={platformDomains}
      />
    </QueryClientProvider>
  );
};
