import { Spinner } from "@cjdev-internal/visual-stack-x/Spinner.js";
import { gql, useMutation, useQuery } from "@apollo/client";
import * as R from "ramda";
import React from "react";

import messages from "./messages.js";
import PromotionPropertyForm from "../../components/promotional_properties/PromotionalPropertyForm/index.js";
import { useHistory, useIntl } from "hooks/index.js";
import { GET_PROPERTY_FOR_EDIT } from "../../queries/index.js";
import {
  coerceNumericValues,
  withoutTypenames,
  removeUnrelatedFields,
  getMessageKeyFromGraphQlError,
} from "../../utils/util.js";
import { PROMOTIONAL_PROPERTIES } from "../../paths.js";
import { useParams } from "@platform/cj-platform-navigation";
import { useNotification } from "hooks/useNotification.js";
import { useErrorNotification } from "hooks/useErrorNotification.js";

export const EditPromotionalProperty = () => {
  const { id: promotionalPropertyId } = useParams();

  return <EditPromotionalPropertyView id={promotionalPropertyId} />;
};

const EditPromotionalPropertyView = ({ id }) => {
  const { navigateTo } = useHistory();
  const { formatMessage } = useIntl();
  const { setNotification } = useNotification();
  const { setErrorNotification } = useErrorNotification();

  const { loading, error, data } = useQuery(GET_PROPERTY_FOR_EDIT, {
    variables: { id: parseInt(id) },
  });

  const [updatePromotionalProperty, { loading: mutating }] = useMutation(
    UPDATE_PROMOTIONAL_PROPERTY,
    {
      onCompleted() {
        setNotification(formatMessage(messages.success));
        navigateTo(PROMOTIONAL_PROPERTIES);
      },
      onError(error) {
        setErrorNotification([
          {
            message: formatMessage(
              messages[getMessageKeyFromGraphQlError(error)]
            ),
          },
        ]);
      },
    }
  );

  if (loading) return <Spinner />;
  if (error) return <div>Something went wrong</div>;
  return (
    <PromotionPropertyForm
      isEdit
      isLoading={mutating}
      initialValues={R.set(
        R.lensPath(["propertyTypeDetails", "socialMediaCheck"]),
        true
      )(data.promotionalProperty)}
      onCancel={() => {
        navigateTo(PROMOTIONAL_PROPERTIES);
      }}
      onSubmit={(values) => {
        delete values.propertyTypeDetails.browserExtensionAgreement;
        delete values.propertyTypeDetails.socialMediaCheck;
        updatePromotionalProperty({
          variables: {
            promotionalProperty: removeUnrelatedFields(
              coerceNumericValues(withoutTypenames(values))
            ),
          },
        });
      }}
    />
  );
};

export const UPDATE_PROMOTIONAL_PROPERTY = gql`
  mutation updatePromotionalProperty(
    $promotionalProperty: UpdatePromotionalPropertyInput
  ) {
    updatePromotionalProperty(input: $promotionalProperty) {
      id
    }
  }
`;

export default EditPromotionalPropertyView;
