import { defineMessages } from "react-intl";

export default defineMessages({
  formTitle: {
    id: "accounts.influencerUserCompanyPage.formTitle",
    defaultMessage: "Step 3/3",
  },
  userSectionSubheading: {
    id: "accounts.influencerUserCompanyPage.userSectionSubheading",
    defaultMessage: "Add your user information",
  },
  userSectionDescription: {
    id: "accounts.influencerUserCompanyPage.userSectionDescription",
    defaultMessage:
      "Your user information will be used to create an admin user role in your account.",
  },
  firstNameLabel: {
    id: "accounts.influencerUserCompanyPage.firstNameLabel",
    defaultMessage: "First Name",
  },
  lastNameLabel: {
    id: "accounts.influencerUserCompanyPage.lastNameLabel",
    defaultMessage: "Last Name",
  },
  userPhoneNumberLabel: {
    id: "accounts.influencerUserCompanyPage.userPhoneNumberLabel",
    defaultMessage: "User Phone Number",
  },
  userPhoneNumberHelp: {
    id: "accounts.influencerUserCompanyPage.userPhoneNumberHelp",
    defaultMessage: "This will not be visible in network profile.",
  },
  companySectionSubheading: {
    id: "accounts.influencerUserCompanyPage.companySectionSubheading",
    defaultMessage: "Add your company information",
  },
  companySectionDescription: {
    id: "accounts.influencerUserCompanyPage.companySectionDescription",
    defaultMessage:
      "Your company information will be used to build your account level settings.",
  },
  companyNamePlaceholder: {
    id: "accounts.influencerUserCompanyPage.companyNamePlaceholder",
    defaultMessage: "Enter your individual, company, or brand name.",
  },
  companyNameLabel: {
    id: "accounts.influencerUserCompanyPage.companyNameLabel",
    defaultMessage: "Company Name",
  },
  companyNameHelp: {
    id: "accounts.influencerUserCompanyPage.companyNameHelp",
    defaultMessage: "Your company name will be visible to the network.",
  },
  companyPhoneNumberLabel: {
    id: "accounts.influencerUserCompanyPage.companyPhoneNumberLabel",
    defaultMessage: "Company Phone Number",
  },
  companyPhoneNumberHelp: {
    id: "accounts.influencerUserCompanyPage.companyPhoneNumberHelp",
    defaultMessage: "This will not be visible in network profile.",
  },
  companyCountryLabel: {
    id: "accounts.influencerUserCompanyPage.companyCountryLabel",
    defaultMessage: "Company Country",
  },
  companyCountryHelp: {
    id: "accounts.influencerUserCompanyPage.companyCountryHelp",
    defaultMessage: "Please select your company's country of operation.",
  },
  companyAddressOneLabel: {
    id: "accounts.influencerUserCompanyPage.companyAddressOneLabel",
    defaultMessage: "Company Address Line 1",
  },
  companyAddressTwoLabel: {
    id: "accounts.influencerUserCompanyPage.companyAddressTwoLabel",
    defaultMessage: "Company Address Line 2",
  },
  companyCityLabel: {
    id: "accounts.influencerUserCompanyPage.companyCityLabel",
    defaultMessage: "Company City",
  },
  companyStateLabel: {
    id: "accounts.influencerUserCompanyPage.companyStateLabel",
    defaultMessage: "Company State",
  },
  companyZipLabel: {
    id: "accounts.influencerUserCompanyPage.companyZipLabel",
    defaultMessage: "Company Zip Code",
  },
  companyFunctionalCurrencyLabel: {
    id: "accounts.influencerUserCompanyPage.companyFunctionalCurrencyLabel",
    defaultMessage: "Functional Currency Reporting Setting",
  },
  companyFunctionalCurrencyHelp: {
    id: "accounts.influencerUserCompanyPage.companyFunctionalCurrencyHelp",
    defaultMessage: "A different currency can be selected for payment options.",
  },
  tipDescription: {
    id: "accounts.influencerUserCompanyPage.tipDescription",
    defaultMessage:
      "Separate from functional currency, payments can be issued in different currencies, depending on your country and currency needs.",
  },
  functionalCurrencyTooltipText: {
    id: "accounts.influencerUserCompanyPage.functionalCurrencyTooltipText",
    defaultMessage:
      "Functional currency determines what currency is used in platform reporting and is separate from your payment currency.",
  },
});
