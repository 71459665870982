import {
  ChoiceInput,
  Field,
  TextField,
} from "@cjdev-internal/visual-stack-x/legacy/Form";
import React, { useContext } from "react";

import messages from "./messages.js";
import DetailsPanel from "../../../DetailsPanel/index.js";
import { FormikContext } from "../../PromotionalPropertyForm/index.js";
import { useIntl } from "hooks/index.js";

export default () => {
  const { formatMessage } = useIntl();
  const { handleChange, handleBlur, values, touched, errors } = useContext(
    FormikContext
  );

  return (
    <>
      <Field help={formatMessage(messages.websiteHelp)}>
        <ChoiceInput
          name="propertyTypeDetails.type"
          label={formatMessage(messages.websiteLabel)}
          value="WEBSITE"
          checked={values.propertyTypeDetails.type === "WEBSITE"}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Field>
      {values.propertyTypeDetails.type === "WEBSITE" ? (
        <DetailsPanel>
          <TextField
            required
            label={formatMessage(messages.websiteUrl)}
            name="propertyTypeDetails.websiteUrl"
            error={
              touched.propertyTypeDetails &&
              touched.propertyTypeDetails.websiteUrl &&
              errors.propertyTypeDetails &&
              errors.propertyTypeDetails.websiteUrl
            }
            placeholder="http(s)://"
            value={values.propertyTypeDetails.websiteUrl || ""}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </DetailsPanel>
      ) : null}
    </>
  );
};
