import {
  ChoiceInput,
  Field,
  TextField,
} from "@cjdev-internal/visual-stack-x/legacy/Form";
import React, { useContext } from "react";

import messages from "./messages.js";
import DetailsPanel from "../../../DetailsPanel/index.js";
import { FormikContext } from "../../PromotionalPropertyForm/index.js";
import { useIntl } from "hooks/index.js";

export default () => {
  const { formatMessage } = useIntl();
  const { handleChange, handleBlur, values, touched, errors } = useContext(
    FormikContext
  );

  return (
    <>
      <Field help={formatMessage(messages.servicesAndToolsHelp)}>
        <ChoiceInput
          name="propertyTypeDetails.type"
          label={formatMessage(messages.servicesAndToolsLabel)}
          value="SERVICES_AND_TOOLS"
          checked={values.propertyTypeDetails.type === "SERVICES_AND_TOOLS"}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Field>
      {values.propertyTypeDetails.type === "SERVICES_AND_TOOLS" ? (
        <DetailsPanel>
          <TextField
            required
            label={formatMessage(messages.servicesAndToolsMarketingSiteUrl)}
            name="propertyTypeDetails.servicesAndToolsMarketingSiteUrl"
            value={
              values.propertyTypeDetails.servicesAndToolsMarketingSiteUrl || ""
            }
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.propertyTypeDetails &&
              touched.propertyTypeDetails.servicesAndToolsMarketingSiteUrl &&
              errors.propertyTypeDetails &&
              errors.propertyTypeDetails.servicesAndToolsMarketingSiteUrl
            }
            placeholder={formatMessage(
              messages.servicesAndToolsMarketingSiteUrlPlaceholder
            )}
          />
        </DetailsPanel>
      ) : null}
    </>
  );
};
