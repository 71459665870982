import { Button } from "@cjdev-internal/visual-stack-x/Button";
import {
  CJField,
  CJForm,
  CJFormFullWidthContainer,
  CJFormSubheading,
  SelectInput,
  TextInput,
  useCJForm,
} from "@cjdev-internal/visual-stack-x/CJForm";
import {
  FormPageBlock,
  FormPageLayout,
} from "@cjdev-internal/visual-stack-x/FormPageLayout";
import { Row } from "@cjdev-internal/visual-stack-x/Row";
import { Stack } from "@cjdev-internal/visual-stack-x/Stack";
import { HorizontalLine } from "components/HorizontalLine";
import { InfluencerSignupHeader } from "components/InfluencerSignupHeader";
import { SpinnerPage } from "components/SpinnerPage";
import { Tip } from "components/Tip";
import {
  useAvailableFunctionalCurrenciesQuery,
  useCountriesQuery,
  useCurrentCompany,
  useErrorToast,
  useHistory,
  useIntl,
  useNewNavigation,
  usePubDevCreators,
  useUserContext,
} from "hooks";
import { INFLUENCER_DESCRIPTION } from "paths";
import * as R from "ramda";
import { useEffect, useState } from "react";
import { isNullOrEmpty, mapOptions, redirectToMember } from "utils/util";
import messages from "./messages";
import globalMessages from "messages/globalMessages";
import { useUserCompanyMutation } from "./useUserCompanyMutation";
import appMessages from "../../../components/App/messages";
import { usePubDevCiqPublisherMapping } from "../../../hooks";
import { ErrorPage } from "../../../components/ErrorPage";
import { InfluencerSignupLayout } from "components/InfluencerSignupLayout";
import authMessages from "components/Authorization/messages";
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from "utils/sessionStorageDao";

export const SAVED_FORM_INPUT_KEY = "influencer-wizard-page3";

export const InfluencerUserCompanyWrapper = ({ influencerToken }) => {
  const { user } = useUserContext();
  const { use2022Nav } = useNewNavigation();
  const { formatMessage } = useIntl();
  const currentCompany = useCurrentCompany();
  if (user && !user.currentCompany) {
    user.currentCompany = currentCompany;
  }
  if (!influencerToken) {
    return (
      <ErrorPage
        errorCode={400}
        messageOverride={formatMessage(authMessages.missingToken)}
      />
    );
  }
  document.title = formatMessage(appMessages.onboardingChecklist);

  const showHeader = !use2022Nav;
  const savedFormInput = getSessionStorageItem(SAVED_FORM_INPUT_KEY);

  return (
    <InfluencerSignupLayout
      influencerToken={influencerToken}
      showHeader={showHeader}
    >
      <InfluencerUserCompanyPage
        influencerToken={influencerToken}
        user={user}
        savedFormInput={savedFormInput}
      />
    </InfluencerSignupLayout>
  );
};

export const InfluencerUserCompanyPage = ({
  influencerToken,
  user,
  savedFormInput,
}) => {
  const { navigateTo } = useHistory();
  const { formatMessage } = useIntl();
  const [errorToastMount, showError] = useErrorToast();
  const countriesQuery = useCountriesQuery();
  const availableFunctionalCurrenciesQuery = useAvailableFunctionalCurrenciesQuery();
  const pubDevCreatorsQuery = usePubDevCreators(
    influencerToken,
    isNullOrEmpty(savedFormInput)
  );
  const userCompanyMutation = useUserCompanyMutation(influencerToken, {
    onSuccess: redirectToMember,
    onError: showError,
  });
  const values = isNullOrEmpty(savedFormInput)
    ? {
        firstName: pubDevCreatorsQuery.data?.contact?.firstName,
        lastName: pubDevCreatorsQuery.data?.contact?.lastName,
        userPhoneNumber: pubDevCreatorsQuery.data?.contact?.phoneNumber,
        companyPhoneNumber: pubDevCreatorsQuery.data?.contact?.phoneNumber,
        companyAddressOne: pubDevCreatorsQuery.data?.contact?.addressLine1,
        companyAddressTwo: pubDevCreatorsQuery.data?.contact?.addressLine2,
        companyCity: pubDevCreatorsQuery.data?.contact?.city,
        companyZipCode: pubDevCreatorsQuery.data?.contact?.zipCode,
      }
    : savedFormInput;
  const config = useCJForm({ values });
  const {
    data: existingPubDevMapping,
    isLoading: isPubDevMappingLoading,
  } = usePubDevCiqPublisherMapping(influencerToken);

  const selectedCountryCode = config.watch("companyCountry");
  const { setValue } = config;
  const [
    restoreCompanyStateFromSavedForm,
    setRestoreCompanyStateFromSavedForm,
  ] = useState(true);

  useEffect(() => {
    if (
      savedFormInput?.companyCountry === selectedCountryCode &&
      restoreCompanyStateFromSavedForm
    ) {
      setValue("companyState", savedFormInput?.companyState);
    } else {
      setValue("companyState", "");
      setRestoreCompanyStateFromSavedForm(false);
    }
  }, [
    selectedCountryCode,
    setValue,
    savedFormInput,
    restoreCompanyStateFromSavedForm,
  ]);

  const watchAllFields = config.watch();

  useEffect(() => {
    setSessionStorageItem(SAVED_FORM_INPUT_KEY, watchAllFields);
  }, [watchAllFields]);

  const subdivisions = R.find(
    R.propEq("isoCode", selectedCountryCode),
    countriesQuery.data || []
  )?.subdivisions;

  const isUS = selectedCountryCode === "US";

  const navigateToInfluencerDescription = (influencerToken) => {
    navigateTo(`${INFLUENCER_DESCRIPTION}?influencerToken=${influencerToken}`);
  };

  if (
    countriesQuery.isLoading ||
    availableFunctionalCurrenciesQuery.isLoading ||
    pubDevCreatorsQuery.isLoading ||
    isPubDevMappingLoading
  ) {
    return <SpinnerPage />;
  }

  if (existingPubDevMapping?.publisherId !== user?.currentCompany?.id) {
    return <ErrorPage errorCode={403} />;
  }

  return (
    <Stack gap="xl" padding="xl">
      <InfluencerSignupHeader
        title={formatMessage(globalMessages.influencerWizardTitle)}
      />
      <FormPageLayout width="wide">
        <FormPageBlock>
          <CJForm
            {...config}
            width="wide"
            onSubmit={userCompanyMutation.mutate}
            heading={formatMessage(messages.formTitle)}
            showRequiredIndicator
            requiredIndicatorText={formatMessage(globalMessages.requiredFields)}
          >
            <CJFormSubheading
              heading={formatMessage(messages.userSectionSubheading)}
            >
              <Stack gap="large">
                {formatMessage(messages.userSectionDescription)}
                <HorizontalLine />
              </Stack>
            </CJFormSubheading>

            <CJField
              htmlFor="first-name-input"
              name="firstName"
              required
              label={formatMessage(messages.firstNameLabel)}
            >
              <TextInput
                id="first-name-input"
                name="firstName"
                required={formatMessage(globalMessages.requiredError)}
                maxLength={{
                  message: formatMessage(globalMessages.maxLengthError, [29]),
                  value: 29,
                }}
              />
            </CJField>
            <CJField
              htmlFor="last-name-input"
              name="lastName"
              required
              label={formatMessage(messages.lastNameLabel)}
            >
              <TextInput
                id="last-name-input"
                name="lastName"
                required={formatMessage(globalMessages.requiredError)}
                maxLength={{
                  message: formatMessage(globalMessages.maxLengthError, [29]),
                  value: 29,
                }}
              />
            </CJField>
            <CJField
              htmlFor="user-phone-number-input"
              name="userPhoneNumber"
              required
              label={formatMessage(messages.userPhoneNumberLabel)}
              help={formatMessage(messages.userPhoneNumberHelp)}
            >
              <TextInput
                id="user-phone-number-input"
                name="userPhoneNumber"
                required={formatMessage(globalMessages.requiredError)}
                minLength={{
                  message: formatMessage(globalMessages.minLengthError, [
                    isUS ? 10 : 7,
                  ]),
                  value: isUS ? 10 : 7,
                }}
                maxLength={{
                  message: formatMessage(globalMessages.maxLengthError, [30]),
                  value: 30,
                }}
                pattern={{
                  message: formatMessage(globalMessages.phoneNumberError),
                  value: /^[\d.\s\-()+]*$/,
                }}
              />
            </CJField>

            <CJFormSubheading
              heading={formatMessage(messages.companySectionSubheading)}
            >
              <Stack gap="large">
                {formatMessage(messages.companySectionDescription)}
                <HorizontalLine />
              </Stack>
            </CJFormSubheading>

            <CJField
              htmlFor="company-name-input"
              name="companyName"
              required
              label={formatMessage(messages.companyNameLabel)}
              help={formatMessage(messages.companyNameHelp)}
            >
              <TextInput
                id="company-name-input"
                name="companyName"
                placeholder={formatMessage(messages.companyNamePlaceholder)}
                required={formatMessage(globalMessages.requiredError)}
                maxLength={{
                  message: formatMessage(globalMessages.maxLengthError, [160]),
                  value: 160,
                }}
              />
            </CJField>
            <CJField
              htmlFor="company-phone-number-input"
              name="companyPhoneNumber"
              required
              label={formatMessage(messages.companyPhoneNumberLabel)}
              help={formatMessage(messages.companyPhoneNumberHelp)}
            >
              <TextInput
                id="company-phone-number-input"
                name="companyPhoneNumber"
                required={formatMessage(globalMessages.requiredError)}
                minLength={{
                  message: formatMessage(globalMessages.minLengthError, [7]),
                  value: 7,
                }}
                maxLength={{
                  message: formatMessage(globalMessages.maxLengthError, [30]),
                  value: 30,
                }}
                pattern={{
                  message: formatMessage(globalMessages.phoneNumberError),
                  value: /^[\d.\s\-()+]*$/,
                }}
              />
            </CJField>
            <CJField
              htmlFor="company-country-select"
              name="companyCountry"
              required
              label={formatMessage(messages.companyCountryLabel)}
              help={formatMessage(messages.companyCountryHelp)}
            >
              <SelectInput
                id="company-country-select-container"
                inputId="company-country-select"
                name="companyCountry"
                placeholder={formatMessage(globalMessages.selectPlaceholder)}
                required={formatMessage(globalMessages.requiredError)}
                unpack
                options={mapOptions("name", "isoCode", countriesQuery?.data)}
              />
            </CJField>
            <CJField
              htmlFor="company-address-one-input"
              name="companyAddressOne"
              required
              fullWidth
              label={formatMessage(messages.companyAddressOneLabel)}
            >
              <TextInput
                id="company-address-one-input"
                data-testid="company-address-one-input"
                name="companyAddressOne"
                required={formatMessage(globalMessages.requiredError)}
                maxLength={{
                  message: formatMessage(globalMessages.maxLengthError, [55]),
                  value: 55,
                }}
              />
            </CJField>
            <CJField
              htmlFor="company-address-two-input"
              name="companyAddressTwo"
              fullWidth
              label={formatMessage(messages.companyAddressTwoLabel)}
            >
              <TextInput
                id="company-address-two-input"
                data-testid="company-address-two-input"
                name="companyAddressTwo"
                maxLength={{
                  message: formatMessage(globalMessages.maxLengthError, [165]),
                  value: 165,
                }}
              />
            </CJField>
            <CJField
              htmlFor="company-city"
              name="companyCity"
              required
              label={formatMessage(messages.companyCityLabel)}
            >
              <TextInput
                id="company-city"
                name="companyCity"
                required={formatMessage(globalMessages.requiredError)}
                maxLength={{
                  message: formatMessage(globalMessages.maxLengthError, [400]),
                  value: 400,
                }}
              />
            </CJField>
            <CJField
              htmlFor="company-state"
              name="companyState"
              required
              label={formatMessage(messages.companyStateLabel)}
            >
              <SelectInput
                id="company-state-select-container"
                inputId="company-state"
                name="companyState"
                placeholder={formatMessage(globalMessages.selectPlaceholder)}
                required={formatMessage(globalMessages.requiredError)}
                unpack
                options={mapOptions(
                  "subdivisionName",
                  "subdivisionCode",
                  subdivisions
                )}
              />
            </CJField>
            <CJField
              htmlFor="company-zip-code"
              name="companyZipCode"
              required={isUS}
              label={formatMessage(messages.companyZipLabel)}
            >
              <TextInput
                id="company-zip-code"
                name="companyZipCode"
                required={isUS && formatMessage(globalMessages.requiredError)}
                minLength={
                  isUS && {
                    message: formatMessage(globalMessages.minLengthError, [
                      isUS ? 5 : 3,
                    ]),
                    value: isUS ? 5 : 3,
                  }
                }
                maxLength={{
                  message: formatMessage(globalMessages.maxLengthError, [30]),
                  value: 30,
                }}
                pattern={
                  isUS && {
                    message: formatMessage(globalMessages.numbersError),
                    value: /^\d*$/,
                  }
                }
              />
            </CJField>
            <CJField
              htmlFor="company-currency-select"
              name="companyCurrency"
              required
              fullWidth
              tooltipIcon
              labelTooltip={formatMessage(
                messages.functionalCurrencyTooltipText
              )}
              label={formatMessage(messages.companyFunctionalCurrencyLabel)}
              help={formatMessage(messages.companyFunctionalCurrencyHelp)}
            >
              <SelectInput
                id="company-currency-select-container"
                inputId="company-currency-select"
                name="companyCurrency"
                placeholder={formatMessage(globalMessages.selectPlaceholder)}
                required={formatMessage(globalMessages.requiredError)}
                unpack
                options={R.map(
                  (code) => ({
                    label: code,
                    value: code,
                  }),
                  availableFunctionalCurrenciesQuery.data || []
                )}
              />
            </CJField>

            <CJFormFullWidthContainer>
              <Stack gap="xl">
                <HorizontalLine type="solid" />
                <Row justify="space-between" gap="6xl">
                  <Tip>{formatMessage(messages.tipDescription)}</Tip>
                  <Row gap="medium">
                    <Button
                      type="tertiary"
                      onClick={() => {
                        navigateToInfluencerDescription(influencerToken);
                      }}
                    >
                      {formatMessage(globalMessages.back)}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={userCompanyMutation.isLoading}
                      spinner={userCompanyMutation.isLoading}
                    >
                      {formatMessage(globalMessages.finish)}
                    </Button>
                  </Row>
                </Row>
              </Stack>
            </CJFormFullWidthContainer>
          </CJForm>
        </FormPageBlock>
      </FormPageLayout>
      {errorToastMount}
    </Stack>
  );
};
