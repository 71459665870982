import { useQuery } from "@tanstack/react-query";
import { authenticatedFetch } from "../utils/util";
import cookies from "js-cookie";
import { useNewNavigation } from "./useNewNavigation";
import { useUserContext } from "./useUserContext";

export const useFeatures = () => {
  const { use2022Nav } = useNewNavigation();
  const { user } = useUserContext();

  const companyId = use2022Nav
    ? user.currentCompany.id
    : cookies.get("jsCompanyId");

  const result = useQuery({
    queryKey: ["features", companyId],
    queryFn: async () => {
      const response = await authenticatedFetch(
        `${process.env.REACT_APP_MEMBER_URL}/affapi/company/${companyId}/features`
      );
      return await response.json();
    },
    refetchOnWindowFocus: false,
  });

  const hasFeature = (feature) => result.data?.includes(feature);

  return {
    ...result,
    hasFeature,
  };
};
