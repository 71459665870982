import { Stack } from "@cjdev-internal/visual-stack-x/Stack";
import { Button } from "@cjdev-internal/visual-stack-x/Button";
import { Row } from "@cjdev-internal/visual-stack-x/Row";
import { useAdvertiserBrandedInfo } from "hooks/useAdvertiserBrandedInfo";
import { Text } from "@cjdev-internal/visual-stack-x/Text";
import {
  FormPageLayout,
  FormPageBlock,
} from "@cjdev-internal/visual-stack-x/FormPageLayout";
import { ExternalLink } from "components/ExternalLink";
import { useIntl } from "react-intl";
import messages from "./messages";
import { redirect } from "utils/util";
import { InfluencerSignupLayout } from "components/InfluencerSignupLayout";
import { HorizontalLine } from "components/HorizontalLine";
import { usePubDevCiqPublisherMapping } from "../../hooks/usePubDevCiqPublisherMapping";
import { Spinner } from "@cjdev-internal/visual-stack-x/Spinner";
import { Link } from "@cjdev-internal/visual-stack-x/components/Link";
import { ErrorPage } from "@cjdev-internal/visual-stack-x/ErrorPage";

export const SignupWelcome = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const advertiserId = queryParams.get("advertiserId");
  const token = queryParams.get("token");

  const { data } = useAdvertiserBrandedInfo(advertiserId);
  const { formatMessage } = useIntl();

  const advertiserName = data?.name;
  const greetingMessage = data?.greetingMessage;

  const influencerSignup = `/influencer-signup?token=${token}&advertiserId=${advertiserId}`;

  const { data: existingMapping, isLoading } = usePubDevCiqPublisherMapping(
    token
  );
  if (isLoading) {
    return <Spinner size={"extra-large"} />;
  }
  return existingMapping ? (
    <Stack>
      <ErrorPage
        errorCode={409}
        autoPosition={false}
        paddingTop={"6xl"}
        paddingBottom={"large"}
        messageOverride={formatMessage(messages.accountAlreadyExists)}
      />
      <Stack expand align="center">
        <Link href={`${process.env.REACT_APP_MEMBER_URL}/member/login`}>
          {formatMessage(messages.publisherLogin)}
        </Link>
      </Stack>
    </Stack>
  ) : (
    <InfluencerSignupLayout advertiserId={advertiserId} influencerToken={token}>
      <FormPageLayout width="wide">
        <FormPageBlock>
          <Stack gap="large" align="center">
            <Row>
              <Text size={20} weight="medium">
                {formatMessage(messages.joinProgram, { advertiserName })}
              </Text>
            </Row>
            <Row>
              <Text size={16} weight="medium">
                {formatMessage(messages.welcome)}
              </Text>
            </Row>
            <Row>
              <Text size={14}>
                {formatMessage(messages.applicationDescription, {
                  programName: (
                    <Text key="program-name" size={14} weight="medium">
                      {advertiserName} Affiliate Program
                    </Text>
                  ),
                })}
                {/*formatMessage(messages.applicationJoinDescription, [advertiserName])*/}
              </Text>
            </Row>
            <Row paddingTop="xl">
              <Button type="primary" onClick={() => redirect(influencerSignup)}>
                {formatMessage(messages.joinProgramCTA, { advertiserName })}
              </Button>
            </Row>
            <Row>
              <Text>
                {formatMessage(messages.existingAccountPrompt)}{" "}
                <ExternalLink
                  href={`${process.env.REACT_APP_MEMBER_URL}/member/login`}
                >
                  {formatMessage(messages.logIn)}
                </ExternalLink>
              </Text>
            </Row>
          </Stack>

          <Stack paddingTop="2xl" paddingBottom="2xl">
            <HorizontalLine type="solid" />
          </Stack>

          <Stack align="left" gap="large">
            <Row>
              <Text weight="bold">
                {formatMessage(messages.programHighlights)}:{" "}
              </Text>
            </Row>
            <Row>
              <Text
                dangerouslySetInnerHTML={{
                  __html: greetingMessage,
                }}
              />
            </Row>
          </Stack>
        </FormPageBlock>
      </FormPageLayout>
    </InfluencerSignupLayout>
  );
};
