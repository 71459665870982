import { gql, useMutation } from "@apollo/client";
import React from "react";
import cookies from "js-cookie";
import messages from "./messages.js";
import PromotionalPropertyForm from "../../components/promotional_properties/PromotionalPropertyForm/index.js";
import {
  useIntl,
  useHistory,
  useUserContext,
  useNewNavigation,
} from "hooks/index.js";
import {
  coerceNumericValues,
  withoutTypenames,
  removeUnrelatedFields,
  getMessageKeyFromGraphQlError,
} from "../../utils/util.js";
import { PROMOTIONAL_PROPERTIES } from "../../paths.js";
import { useNotification } from "hooks/useNotification.js";
import { useErrorNotification } from "hooks/useErrorNotification.js";

const CreatePromotionalProperty = ({ initialValues = {} }) => {
  const { formatMessage } = useIntl();
  const { navigateTo } = useHistory();
  const { setNotification } = useNotification();
  const { setErrorNotification } = useErrorNotification();

  const [addPromotionalProperty, { loading }] = useMutation(
    ADD_PROMOTIONAL_PROPERTY,
    {
      onCompleted() {
        setNotification(formatMessage(messages.success));
        navigateTo(PROMOTIONAL_PROPERTIES);
      },
      onError(error) {
        setErrorNotification([
          {
            message: formatMessage(
              messages[getMessageKeyFromGraphQlError(error)]
            ),
          },
        ]);
      },
    }
  );

  const { use2022Nav } = useNewNavigation();
  const { user } = useUserContext();

  const publisherId = use2022Nav
    ? user.currentCompany.id
    : parseInt(cookies.get("jsCompanyId"));

  return (
    <PromotionalPropertyForm
      isLoading={loading}
      onCancel={() => {
        navigateTo(PROMOTIONAL_PROPERTIES);
      }}
      onSubmit={(values) => {
        delete values.propertyTypeDetails.browserExtensionAgreement;
        delete values.propertyTypeDetails.socialMediaCheck;
        addPromotionalProperty({
          variables: {
            promotionalProperty: removeUnrelatedFields(
              coerceNumericValues(withoutTypenames(values))
            ),
          },
        });
      }}
      initialValues={{
        publisherId,
        ...initialValues,
      }}
    />
  );
};

export const ADD_PROMOTIONAL_PROPERTY = gql`
  mutation createPromotionalProperty(
    $promotionalProperty: CreatePromotionalPropertyInput
  ) {
    createPromotionalProperty(input: $promotionalProperty) {
      id
    }
  }
`;

export default CreatePromotionalProperty;
