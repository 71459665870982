import Pendo from "@cjdev-internal/pendo";
import { useEffect } from "react";

import { getUser } from "../../auth";
import { getUserId } from "../../utils/platformAuth";

const env = process.env.NODE_ENV;

export default () => {
  useEffect(() => {
    (async () => {
      const initializer = Pendo.install(env);
      const userId = await getUserId();
      const user = await getUser(userId);
      const email = user.emailAddress;
      const isInternal = user.isEmployee;

      // Initialize
      initializer.initialize(userId, email, isInternal);
    })();
  }, []);

  return null;
};
