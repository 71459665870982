import { defineMessages } from "react-intl";

export default defineMessages({
  promotionalModel: {
    id: "accounts.PromotionalModels.promotionalModel",
    defaultMessage:
      "What is the primary value your customers get out of this property?",
  },
  promotionalModelHelp: {
    id: "accounts.PromotionalModels.promotionalModelHelp",
    defaultMessage:
      "You may choose multiple, but only choose those that are relevant for this specific property. You must set one of your promotional models as your primary promotional model for this property. This will be used mostly for reporting purposes so choose the one that most accurately describes this property.",
  },
  CONTENT_BLOG_MEDIA: {
    id: "accounts.PromotionalModels.contentBlogMedia",
    defaultMessage: "Content/Blog/Media",
  },
  CONTENT_BLOG_MEDIA_HELP: {
    id: "accounts.PromotionalModels.contentBlogMediaHelp",
    defaultMessage:
      "My customers read original content or articles that I write about topics that interest them.",
  },
  COUPON_DEAL: {
    id: "accounts.PromotionalModels.couponDeal",
    defaultMessage: "Coupon/Deal",
  },
  COUPON_DEAL_HELP: {
    id: "accounts.PromotionalModels.couponDealHelp",
    defaultMessage:
      "My customers come to discover coupons, vouchers, or discounts.",
  },
  LOYALTY_CASH_BACK: {
    id: "accounts.PromotionalModels.loyaltyCashBack",
    defaultMessage: "Loyalty - Cash Back",
  },
  LOYALTY_CASH_BACK_HELP: {
    id: "accounts.PromotionalModels.loyaltyCashBackHelp",
    defaultMessage:
      "My customers receive rewards in the form of cash back as a percentage of their purchase.",
  },
  LOYALTY_NON_CASH_REWARDS: {
    id: "accounts.PromotionalModels.loyaltyNonCashRewards",
    defaultMessage: "Loyalty - Non Cash Rewards",
  },
  LOYALTY_NON_CASH_REWARDS_HELP: {
    id: "accounts.PromotionalModels.loyaltyNonCashRewardsHelp",
    defaultMessage:
      "My customers receive non-cash rewards for their purchase in the form of points, airline miles, charity donations, etc.",
  },
  LOYALTY_CLOSED_USER_GROUP: {
    id: "accounts.PromotionalModels.loyaltyClosedUserGroup",
    defaultMessage: "Loyalty - Closed User Group",
  },
  LOYALTY_CLOSED_USER_GROUP_HELP: {
    id: "accounts.PromotionalModels.loyaltyClosedUserGroupHelp",
    defaultMessage:
      "I provide a membership service where my customers must have an exclusive login to access benefits, such as employee benefits, credit card rewards, or airline rewards malls.",
  },
  TRADEMARK_BIDDING: {
    id: "accounts.PromotionalModels.trademarkBidding",
    defaultMessage: "Trademark Bidding",
  },
  TRADEMARK_BIDDING_HELP: {
    id: "accounts.PromotionalModels.trademarkBiddingHelp",
    defaultMessage:
      "I purchase search engine ads by bidding on the brand names of my advertisers, but I display my own brand in the ad and when they click, my customers are directed to my own website.",
  },
  INFLUENCER: {
    id: "accounts.PromotionalModels.influencer",
    defaultMessage: "Influencer",
  },
  INFLUENCER_HELP: {
    id: "accounts.PromotionalModels.influencerHelp",
    defaultMessage:
      "My customers follow me because I am a personality or figure they are interested in following.",
  },
  OTHER: {
    id: "accounts.PromotionalModels.other",
    defaultMessage: "I do not see my promotional model listed here",
  },
  OTHER_HELP: {
    id: "accounts.PromotionalModels.otherHelp",
    defaultMessage:
      "If your property does not fit into any of the above options, please briefly describe your promotional method/property type here so that advertisers can understand what you do.",
  },
  PAY_PER_CALL: {
    id: "accounts.PromotionalModels.payPerCall",
    defaultMessage: "Pay Per Call",
  },
  PAY_PER_CALL_HELP: {
    id: "accounts.PromotionalModels.payPerCallHelp",
    defaultMessage:
      "I use call tracking to drive customer transactions over the telephone.",
  },
  PRODUCT_COMPARISON_REVIEWS_DISCOVERY: {
    id: "accounts.PromotionalModels.productComparisonReviewsDiscovery",
    defaultMessage: "Product Comparison, Reviews, or Discovery",
  },
  PRODUCT_COMPARISON_REVIEWS_DISCOVERY_HELP: {
    id: "accounts.PromotionalModels.productComparisonReviewsDiscoveryHelp",
    defaultMessage:
      "I provide links to specific products that customers can browse, compare, or search for.",
  },
  primaryPromotionalMethod: {
    id: "accounts.PromotionalModels.primaryPromotionalMethod",
    defaultMessage: "Primary Promotional Model",
  },
  setPrimary: {
    id: "accounts.PromotionalModels.setPrimary",
    defaultMessage: "Set to Primary Promotional Model",
  },
  mustChoosePrimary: {
    id: "accounts.PromotionalModels.mustChoosePrimary",
    defaultMessage: "(You must choose one primary promotional model)",
  },
  description: {
    id: "accounts.PromotionalModels.description",
    defaultMessage: "Description",
  },
  descriptionPlaceholder: {
    id: "accounts.PromotionalModels.descriptionPlaceholder",
    defaultMessage: "Enter description",
  },
  setPrimaryHelper: {
    id: "accounts.PromotionalModels.setPrimaryHelper",
    defaultMessage:
      "For reporting purposes. One of your promotional models must be the primary for this property.",
  },
});
