import React, { createContext, useState } from "react";

export const NotificationContext = createContext({
  notification: null,
  setNotification: null,
  providerInitialized: false,
});

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(null);
  return (
    <NotificationContext.Provider
      value={{
        notification,
        setNotification,
        providerInitialized: true,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
