import { defineMessages } from "react-intl";

export default defineMessages({
  program: {
    id: "accounts.InfluencerSignupLayout.program",
    defaultMessage: "Affiliate Program",
  },
  learn: {
    id: "accounts.InfluencerSignupLayout.learn",
    defaultMessage: "Learn more",
  },
  byJoining: {
    id: "accounts.InfluencerSignupLayout.byJoining",
    defaultMessage: "By joining the {0} affiliate program:",
  },
  signing: {
    id: "accounts.InfluencerSignupLayout.signing",
    defaultMessage: "You are signing up for a CJ Affiliate Publisher account.",
  },
  privacy: {
    id: "accounts.InfluencerSignupLayout.privacy",
    defaultMessage: "Privacy Policy",
  },
  support: {
    id: "accounts.InfluencerSignupLayout.support",
    defaultMessage: "Support Center",
  },
});
