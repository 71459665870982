import { useContext } from "react";
import { throwUninitializedContextError } from "./helpers/errorHelpers";

export const useProviderInitializedContext = (providerContext) => {
  const context = useContext(providerContext);
  if (context?.providerInitialized) {
    return context;
  }
  throwUninitializedContextError();
};
