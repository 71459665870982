import { usePropertiesForSignup } from "./hooks/usePropertiesForSignup";
import { usePubDevCiqPublisherMapping } from "../../../hooks/usePubDevCiqPublisherMapping";
import { SpinnerPage } from "../../../components/SpinnerPage/index";
import { ErrorPage } from "../../../components/ErrorPage/index";
import { PropertyImport } from "./index";
import { useIntl, useNewNavigation } from "../../../hooks";
import messages from "components/Authorization/messages";
import { InfluencerSignupLayout } from "components/InfluencerSignupLayout";

export const PropertyImportWrapper = ({ user, influencerToken }) => {
  const { formatMessage } = useIntl();
  const currentCompanyId = user.currentCompany.id;
  const { use2022Nav } = useNewNavigation();
  const { propertiesForSignup, isLoading } = usePropertiesForSignup(
    currentCompanyId,
    influencerToken
  );
  const {
    data: existingPubDevMapping,
    isLoading: isPubDevMappingLoading,
  } = usePubDevCiqPublisherMapping(influencerToken);

  if (!influencerToken) {
    return (
      <ErrorPage
        errorCode={400}
        messageOverride={formatMessage(messages.missingToken)}
      />
    );
  }

  if (isLoading || isPubDevMappingLoading) {
    return <SpinnerPage />;
  }

  const showHeader = !use2022Nav;

  return existingPubDevMapping?.publisherId !== currentCompanyId ? (
    <ErrorPage errorCode={403} />
  ) : (
    <InfluencerSignupLayout
      influencerToken={influencerToken}
      showHeader={showHeader}
    >
      <PropertyImport
        propertiesForSignup={propertiesForSignup}
        publisherId={currentCompanyId}
        influencerToken={influencerToken}
      />
    </InfluencerSignupLayout>
  );
};
