import { defineMessages } from "react-intl";

export default defineMessages({
  success: {
    id: "accounts.EditPromotionalProperty.success",
    defaultMessage: "Successfully saved promotional property.",
  },
  error: {
    id: "accounts.EditPromotionalProperty.error",
    defaultMessage: "Something went wrong. Please try again later.",
  },
  "errors.isPrimary": {
    id: "accounts.EditPromotionalProperty.errors.isPrimary",
    defaultMessage:
      "One of your active promotional properties must be set as your primary promotional property.",
  },
  "errors.tryAgain": {
    id: "accounts.EditPromotionalProperty.error",
    defaultMessage: "Something went wrong. Please try again later.",
  },
});
