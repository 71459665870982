import { CJField, SelectInput } from "@cjdev-internal/visual-stack-x/CJForm";
import { Spinner } from "@cjdev-internal/visual-stack-x/Spinner";
import { useCountriesQuery } from "hooks/useCountriesQuery";
import globalMessages from "messages/globalMessages";
import { useIntl } from "react-intl";
import { mapOptions } from "utils/util";
import messages from "./messages";

export const CountrySelectField = ({ language }) => {
  const countriesQuery = useCountriesQuery(language);
  const { formatMessage } = useIntl();

  if (countriesQuery.error) {
    return formatMessage(messages.errorLoading);
  }

  return (
    <CJField
      name="country"
      label={formatMessage(messages.label)}
      required
      htmlFor="country-input"
    >
      <SelectInput
        name="country"
        inputId="country-input"
        required={formatMessage(globalMessages.requiredError)}
        unpack
        placeholder={
          countriesQuery.isLoading ? (
            <Spinner size="button" />
          ) : (
            formatMessage(globalMessages.selectPlaceholder)
          )
        }
        options={mapOptions("name", "isoCode", countriesQuery.data)}
      />
    </CJField>
  );
};
