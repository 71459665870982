import { HoverPopover } from "@cjdev-internal/visual-stack-x/components/Popover/Popover.js";
import { Clickable } from "@cjdev-internal/visual-stack-x/Clickable.js";
import { Text } from "@cjdev-internal/visual-stack-x/Text.js";
import { Row } from "@cjdev-internal/visual-stack-x/Row.js";
import { useModal } from "@cjdev-internal/visual-stack-x/Modal.js";
import { MDIcon } from "@cjdev-internal/visual-stack-x/MDIcon.js";
import React from "react";
import { useIntl } from "hooks/index.js";

import messages from "./messages.js";
import { AuthenticateModal } from "../AuthenticateModal/index.js";
import DateTimeText from "../../../../components/DateTimeText/index.js";

export default ({ promotionalProperty }) => {
  const { formatMessage } = useIntl();
  const [mount, openModal, closeModal] = useModal();

  const openAuthenticateModal = () =>
    openModal(
      <AuthenticateModal
        closeModal={closeModal}
        property={promotionalProperty}
      />
    );

  return (
    <>
      {promotionalProperty.authenticationStatus === "FOUND" && (
        <>
          <Clickable onClick={openAuthenticateModal}>
            <Row gap="small" align="center">
              <MDIcon icon="link" size={20} color="#2bb179" />
              <Text color="link">{formatMessage(messages.connect)}</Text>
            </Row>
          </Clickable>
          {mount}
        </>
      )}
      {promotionalProperty.authenticationStatus === "NOT_FOUND" && (
        <HoverPopover
          placement={"bottom"}
          content={<Text>{formatMessage(messages.handleNotFound)}</Text>}
        >
          <Row gap="small" align="center">
            <MDIcon icon="alert" size={20} color="#ffb825" />
            <Text>{formatMessage(messages.connect)}</Text>
          </Row>
        </HoverPopover>
      )}
      {promotionalProperty.authenticationStatus === "AUTHENTICATED" && (
        <HoverPopover
          placement={"bottom"}
          content={
            <>
              {formatMessage(messages.authenticatedAt, {
                date: (
                  <DateTimeText
                    key={0}
                    date={promotionalProperty.authenticationDate}
                  />
                ),
              })}
            </>
          }
        >
          <Row gap="small" align="center">
            <MDIcon icon="check-decagram" size={20} color="#2bb179" />
            <Text>{formatMessage(messages.connected)}</Text>
          </Row>
        </HoverPopover>
      )}
      {promotionalProperty.authenticationStatus === "BROKEN" && (
        <HoverPopover
          placement={"bottom"}
          content={<Text>{formatMessage(messages.reconnectPopover)}</Text>}
        >
          <Clickable onClick={openAuthenticateModal}>
            <Row gap="small" align="center">
              <MDIcon icon="link-off" size={20} color="#ec9393" />
              <Text color="link">{formatMessage(messages.reconnect)}</Text>
            </Row>
          </Clickable>
          {mount}
        </HoverPopover>
      )}
    </>
  );
};
