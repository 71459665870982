import { Stack } from "@cjdev-internal/visual-stack-x/Stack";
import { Text } from "@cjdev-internal/visual-stack-x/Text";

export const InfluencerSignupHeader = ({ title, subtitle, gap, ...rest }) => {
  return (
    <Stack align="center" gap={gap || "small"} {...rest}>
      <Text size={18} weight="medium">
        {title}
      </Text>
      {subtitle ? <Text>{subtitle}</Text> : null}
    </Stack>
  );
};
