import { gql, useLazyQuery } from "@apollo/client";

export const IDLE = "IDLE";
export const LOADING = "LOADING";
export const FOUND = "FOUND";
export const NOT_FOUND = "NOT_FOUND";
export const ERROR = "ERROR";

export const useCheckSocialAccount = () => {
  const [getSocialAccount, { called, loading, data, error }] = useLazyQuery(
    SOCIAL_ACCOUNT,
    {
      fetchPolicy: "network-only",
    }
  );
  const status = loading
    ? LOADING
    : error
    ? ERROR
    : data?.socialAccount
    ? FOUND
    : called && !loading && !data?.socialAccount
    ? NOT_FOUND
    : IDLE;

  return {
    checkSocialAccount: async (socialPlatform, socialAccount) => {
      getSocialAccount({ variables: { socialPlatform, socialAccount } });
    },
    status,
  };
};

export const SOCIAL_ACCOUNT = gql`
  query socialAccount(
    $socialPlatform: SocialPlatform!
    $socialAccount: String!
  ) {
    socialAccount(
      socialPlatform: $socialPlatform
      socialAccount: $socialAccount
    ) {
      id
    }
  }
`;
