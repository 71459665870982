import { gql, useQuery } from "@apollo/client";
import { Row } from "@cjdev-internal/visual-stack-x/Row.js";
import { Button } from "@cjdev-internal/visual-stack-x/Button.js";
import { Text } from "@cjdev-internal/visual-stack-x/Text.js";
import { Stack } from "@cjdev-internal/visual-stack-x/Stack.js";
import { ExpandingInputButton } from "@cjdev-internal/visual-stack-x/ExpandingInputButton.js";
import { Pagination } from "@cjdev-internal/visual-stack-x/Pagination.js";
import { Select } from "@cjdev-internal/visual-stack-x/Select.js";
import { LoadingAnimation } from "@cjdev-internal/visual-stack-x/LoadingAnimation.js";
import { Spinner } from "@cjdev-internal/visual-stack-x/Spinner.js";
import {
  TableContainer,
  TableTitle,
  Table,
  THead,
  TBody,
  Th,
  Tr,
  Td,
} from "@cjdev-internal/visual-stack-x/Table.js";
import cookies from "js-cookie";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { PROMOTIONAL_PROPERTIES } from "../../paths.js";

import messages from "./messages.js";
import "./styles.css";
import Alert from "../../components/Alert/index.js";
import PromotionalPropertyRow from "./PromotionalPropertyRow/index.js";
import BlankSlate from "./BlankSlate/index.js";
import { ReconnectAuthenticationAlert } from "views/ViewPromotionalProperties/ReconnectAuthenticationAlert/index.js";
import { Link } from "components/Link/index.js";
import {
  useFeatures,
  useIntl,
  useNewNavigation,
  useUserContext,
} from "hooks/index.js";
import { withDialog } from "components/withDialog/index.js";
import { useNotification } from "hooks/useNotification.js";
import { rolesWithManagePermission, userHasPermissions } from "auth.js";

const TableView = ({ publisherId, user, renderAction, showEdit }) => {
  const { use2022Nav } = useNewNavigation();
  const { formatMessage } = useIntl();
  const [search, setSearch] = useState();
  const [status, setStatus] = useState("ACTIVE");
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const { error, data, loading } = useQuery(GET_PROPERTIES, {
    variables: { publisherId, search, status, limit, offset },
  });
  const { hasFeature } = useFeatures();
  const isEmpty =
    !R.isNil(data) && !loading && data.promotionalProperties.totalCount <= 0;
  const statusOptions = [
    {
      value: "ACTIVE",
      label: formatMessage(messages.STATUS_ACTIVE),
    },
    {
      value: "ARCHIVED",
      label: formatMessage(messages.STATUS_ARCHIVED),
    },
  ];

  const page = Math.floor(offset / limit) + 1;

  return (
    <div className="view-promotional-properties">
      {!use2022Nav ? (
        <Row justify="space-between">
          <Row gap="medium">{renderAction}</Row>
          <Row gap="medium">
            <div className="api-info render-center">
              <a
                href="https://developers.cj.com/graphql/reference/Promotional%20Properties"
                rel="noopener noreferrer"
                target="_blank"
              >
                {formatMessage(messages.learnMoreApi)}
              </a>
            </div>
          </Row>
        </Row>
      ) : null}
      <TableContainer>
        <Row
          className="title-toolbar-container"
          padding="medium-large"
          align="center"
          justify="space-between"
          childFlex="true"
        >
          {!use2022Nav && (
            <TableTitle>{formatMessage(messages.tableTitle)}</TableTitle>
          )}
          <Row className="table-toolbar" gap="medium" align="center">
            <ExpandingInputButton
              name="search"
              onChange={(newValue) => {
                setSearch(newValue || undefined);
                setOffset(0);
              }}
              placeholder={formatMessage(messages.placeholder)}
            />
            <Select
              className="status-select"
              name="status"
              value={getSelectedOption(statusOptions, status)}
              onChange={({ value }) => {
                setStatus(value);
              }}
              options={statusOptions}
            />
          </Row>
        </Row>
        <Table>
          <THead>
            <Tr>
              <Th>{formatMessage(messages.propertyName)}</Th>
              <Th>{formatMessage(messages.propertyId)}</Th>
              <Th>{formatMessage(messages.type)}</Th>
              {hasFeature("USER_CAN_AUTHENTICATE_IN_CIQ") && (
                <Th className="authentication-column">
                  {formatMessage(messages.authentication)}
                </Th>
              )}
              <Th>{formatMessage(messages.primaryModel)}</Th>
              <Th>{formatMessage(messages.numberOfModels)}</Th>
              <Th>{formatMessage(messages.lastUpdated)}</Th>
              <Th>{formatMessage(messages.status)}</Th>
              {showEdit && <Th>{formatMessage(messages.actions)}</Th>}
            </Tr>
          </THead>
          <TBody>
            {error ? (
              <Tr>
                <Td colSpan={9} className="centered-td">
                  <span className="error">{formatMessage(messages.error)}</span>
                </Td>
              </Tr>
            ) : (
              <>
                {loading && (
                  <Tr>
                    <Td colSpan={9} className="centered-td">
                      <Spinner />
                    </Td>
                  </Tr>
                )}
                {isEmpty && (
                  <Tr>
                    <Td>{formatMessage(messages.NO_DATA_AVAILABLE)}</Td>
                  </Tr>
                )}
                {!loading &&
                  data.promotionalProperties.resultList.map(
                    (promotionalProperty) => (
                      <PromotionalPropertyRow
                        promotionalProperty={promotionalProperty}
                        key={promotionalProperty.id}
                        showEdit={showEdit}
                      />
                    )
                  )}
                {hasFeature("USER_CAN_AUTHENTICATE_IN_CIQ") && !loading && (
                  <ReconnectAuthenticationAlert
                    properties={data.promotionalProperties.resultList}
                  />
                )}
              </>
            )}
          </TBody>
        </Table>
        {data && !R.isEmpty(data) && (
          <Pagination
            numberOfRows={data.promotionalProperties.totalCount}
            rowsPerPage={limit}
            page={page}
            onChange={({ page, rowsPerPage }) => {
              setOffset((page - 1) * rowsPerPage);
              setLimit(rowsPerPage);
            }}
            rowsPerPageTemplate={formatMessage(messages.rowsPerPageTemplate, {
              0: "{0}",
            })}
            totalRecordsTemplate={formatMessage(messages.totalRecordsTemplate, {
              0: "{0}",
            })}
          />
        )}
      </TableContainer>
    </div>
  );
};

const getSelectedOption = (options, value) =>
  R.find((option) => option.value === value, options);

export default () => {
  const { use2022Nav } = useNewNavigation();
  const { formatMessage } = useIntl();
  const { notification, setNotification } = useNotification();
  const [showPendingStatusAlert, setShowPendingStatusAlert] = useState(false);
  const { user } = useUserContext();
  const publisherId =
    user?.currentCompany?.id || parseInt(cookies.get("jsCompanyId"));

  useEffect(() => {
    setShowPendingStatusAlert(
      Boolean(window.sessionStorage.getItem("refreshAuthStatus"))
    );
  }, []);

  const { data: countData, loading: countLoading } = useQuery(
    GET_PROPERTIES_UNFILTERED_COUNT,
    {
      variables: { publisherId },
    }
  );

  const isEmpty =
    !R.isNil(countData) && countData.promotionalProperties.totalCount <= 0;
  const showCreateAndEdit = userHasPermissions(
    rolesWithManagePermission,
    user,
    publisherId
  );

  const ViewPromotionalProperties = (
    <>
      {notification && (
        <Alert
          closeType="icon"
          iconType="success"
          autoHide
          onHide={() => setNotification(null)}
        >
          {notification}
        </Alert>
      )}
      {showPendingStatusAlert && (
        <Alert
          closeType="icon"
          className="auth-status-alert"
          iconType="info"
          onHide={() => {
            setShowPendingStatusAlert(false);
            window.sessionStorage.removeItem("refreshAuthStatus");
          }}
        >
          <Stack gap="xl">
            <Text size={18} color="secondary">
              {formatMessage(messages.authenticationStatusTitle)}
            </Text>
            <Text>{formatMessage(messages.authenticationStatusBody)}</Text>
          </Stack>
        </Alert>
      )}
      {countLoading && (
        <div className="loading-container">
          <LoadingAnimation
            loadingMessage={formatMessage(
              messages.LOADING_PROMOTIONAL_PROPERTIES
            )}
          />
        </div>
      )}
      {isEmpty && !countLoading && (
        <BlankSlate
          renderAction={
            showCreateAndEdit ? (
              <>
                <Link to={`${PROMOTIONAL_PROPERTIES}/create`}>
                  <Button type="primary" className="create-property-button">
                    {formatMessage(messages.create)}
                  </Button>
                </Link>
              </>
            ) : null
          }
        />
      )}
      {!isEmpty && !countLoading && (
        <TableView
          renderAction={
            showCreateAndEdit ? (
              <>
                <Link to={`${PROMOTIONAL_PROPERTIES}/create`}>
                  <Button type="primary" className="create-property-button">
                    {formatMessage(messages.create)}
                  </Button>
                </Link>
              </>
            ) : null
          }
          publisherId={publisherId}
          user={user}
          showEdit={showCreateAndEdit}
        />
      )}
    </>
  );

  return use2022Nav
    ? ViewPromotionalProperties
    : withDialog(ViewPromotionalProperties, {
        title: formatMessage(messages.title),
        contentProps: { gap: "large" },
        actions: {
          close: () =>
            (window.location.href = process.env.REACT_APP_MEMBER_URL),
        },
      });
};

export const GET_PROPERTIES = gql`
  query getPromoProperties(
    $publisherId: ID!
    $search: String
    $status: PromotionalPropertyStatus
    $limit: Int
    $offset: Int
  ) {
    promotionalProperties(
      publisherId: $publisherId
      search: $search
      limit: $limit
      status: $status
      offset: $offset
    ) {
      resultList {
        publisherId
        id
        isPrimary
        name
        propertyTypeDetails {
          type
          ... on PromotionalPropertySocialMediaDetails {
            socialMediaHandle
            socialMediaPlatform
          }
        }
        promotionalModels {
          type
          isPrimary
          description
        }
        status
        updatedAt
        authenticationStatus
        authenticationDate
      }
      totalCount
    }
  }
`;

export const GET_PROPERTIES_UNFILTERED_COUNT = gql`
  query getPromoPropertiesUnfilteredCount($publisherId: ID!) {
    promotionalProperties(publisherId: $publisherId) {
      totalCount
    }
  }
`;
