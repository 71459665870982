import { Box } from "@cjdev-internal/visual-stack-x/Box.js";
import { LayoutSection } from "@cjdev-internal/visual-stack-x/LayoutSection";
import {
  ChoiceInput,
  Field,
  Form,
  TextArea,
  TextField,
} from "@cjdev-internal/visual-stack-x/legacy/Form";
import { Body, Header, Panel } from "@cjdev-internal/visual-stack-x/Panel.js";
import { Select } from "@cjdev-internal/visual-stack-x/Select.js";
import { TopNavLayout } from "@cjdev-internal/visual-stack-x/TopNavLayout.js";
import { Formik } from "formik";
import * as R from "ramda";
import React, { createContext, useContext } from "react";
import { useIntl, useNewNavigation } from "hooks/index.js";
import { ErrorNotification } from "../../ErrorNotification/index.js";
import PromotionalModels from "../PromotionalModels/index.js";
import validationSchema, { formatErrorMessages } from "./validationSchema.js";
import PropertyTags from "../PropertyTags/index.js";
import PropertyTypes from "../PropertyTypes/index.js";
import messages from "./messages.js";
import "./styles.css";
import { FormModalWithOptions } from "@platform/cj-platform-navigation";
import { useErrorNotification } from "hooks/useErrorNotification.js";

export const FormikContext = createContext({});

const getSelectedOption = (options, value) =>
  R.find((option) => option.value === value, options);

const typeLens = R.lensPath(["propertyTypeDetails", "type"]);
const getType = R.view(typeLens);
const mobileAppDownloadInformationLens = R.lensPath([
  "propertyTypeDetails",
  "mobileAppDownloadInformation",
]);

const browserExtensionDownloadInformationLens = R.lensPath([
  "propertyTypeDetails",
  "browserExtensionDownloadInformation",
]);

const transformMobileAppToAPIData = (values) => {
  const type = getType(values);
  if (type !== "MOBILE_APP")
    return R.dissocPath(
      ["propertyTypeDetails", "mobileAppDownloadInformation"],
      values
    );
  return R.over(
    mobileAppDownloadInformationLens,
    R.pipe(
      R.toPairs,
      R.map(([platform, url]) => ({
        mobileAppPlatform: R.toUpper(platform),
        mobileAppDownloadLink: url,
      }))
    )
  )(values);
};

const transformBrowserExtensionToAPIData = (values) => {
  const type = getType(values);
  if (type !== "BROWSER_EXTENSION")
    return R.dissocPath(
      ["propertyTypeDetails", "browserExtensionDownloadInformation"],
      values
    );
  return R.over(
    browserExtensionDownloadInformationLens,
    R.pipe(
      R.toPairs,
      R.map(([platform, url]) => ({
        browserExtensionBrowser: R.toUpper(platform),
        browserExtensionDownloadLink: url,
      }))
    )
  )(values);
};

const transformMobileAppToFormikData = (values) => {
  const type = getType(values);
  if (type !== "MOBILE_APP") return values;
  return R.over(
    mobileAppDownloadInformationLens,
    R.pipe(
      R.defaultTo([]),
      R.map(({ mobileAppDownloadLink, mobileAppPlatform }) => [
        R.toLower(mobileAppPlatform),
        mobileAppDownloadLink,
      ]),
      R.fromPairs
    )
  )(values);
};

const transformBrowserExtensionToFormikData = (values) => {
  const type = getType(values);
  if (type !== "BROWSER_EXTENSION") return values;
  return R.over(
    browserExtensionDownloadInformationLens,
    R.pipe(
      R.defaultTo([]),
      R.map(({ browserExtensionDownloadLink, browserExtensionBrowser }) => [
        R.toLower(browserExtensionBrowser),
        browserExtensionDownloadLink,
      ]),
      R.fromPairs
    )
  )(values);
};

export const defaultValues = {
  name: undefined,
  description: undefined,
  status: "ACTIVE",
  isPrimary: false,
  propertyTypeDetails: {
    type: undefined,
    websiteUrl: undefined,
    browserExtensionName: undefined,
    browserExtensionDownloadInformation: undefined,
    browserExtensionDescription: "",
    mobileAppName: undefined,
    servicesAndToolsMarketingSiteUrl: undefined,
    emailAddress: undefined,
    emailMailingListSize: undefined,
    emailOpenRate: undefined,
    socialMediaPlatform: undefined,
    socialMediaHandle: undefined,
    socialMediaCheck: undefined,
    paidDisplayAdsDescription: undefined,
    paidSearchEngineDescription: undefined,
    mobileAppDownloadInformation: undefined,
    otherDescription: undefined,
  },
  promotionalModels: [],
  tags: [],
};

const PromotionalPropertyForm = () => {
  const { formatMessage } = useIntl();
  const {
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    touched,
    values,
  } = useContext(FormikContext);

  const statusOptions = [
    {
      value: "ACTIVE",
      label: formatMessage(messages.statusActive),
    },
    {
      value: "ARCHIVED",
      label: formatMessage(messages.statusArchived),
    },
  ];

  return (
    <div className="promotional-property-form-wrapper">
      <Form>
        <Panel>
          <Body>
            <div data-id="pendo-property-header">
              <Header>{formatMessage(messages.header)}</Header>
              <Box paddingTop="medium" paddingBottom="medium">
                {formatMessage(messages.descriptiveText)}
              </Box>
            </div>
          </Body>
          <Body padding="none">
            <div data-id="pendo-property-type">
              <LayoutSection
                label={formatMessage(messages.propertyTypeTitle)}
                disableFormPageBlockAdjust
                startExpanded
              >
                <PropertyTypes />
              </LayoutSection>
            </div>
          </Body>
          <Body padding="none">
            <div data-id="pendo-promotional-models">
              <LayoutSection
                data-testid="promotional-models"
                label={formatMessage(messages.promotionalModelTitle)}
                disableFormPageBlockAdjust
                startExpanded
              >
                <PromotionalModels />
              </LayoutSection>
            </div>
          </Body>
          <Body padding="none">
            <div data-id="pendo-property-info">
              <LayoutSection
                label={formatMessage(messages.namePanelTitle)}
                disableFormPageBlockAdjust
                startExpanded
              >
                <TextField
                  required
                  label={formatMessage(messages.name)}
                  name="name"
                  help={formatMessage(messages.nameHelp)}
                  className="name-class"
                  error={touched.name && errors.name}
                  value={values.name || ""}
                  placeholder={formatMessage(messages.namePlaceholder)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <Field
                  label={formatMessage(messages.description)}
                  optional={false}
                  error={touched.description && errors.description}
                  help={formatMessage(messages.descriptionHelp)}
                >
                  <TextArea
                    trimmed
                    name="description"
                    value={values.description || ""}
                    placeholder={formatMessage(messages.descriptionPlaceholder)}
                    className={
                      touched.description && errors.description && "input-error"
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Field>
                <Field
                  help={formatMessage(messages.isPrimaryFieldHelp)}
                  className="primary-field"
                >
                  <ChoiceInput
                    type="checkbox"
                    name="isPrimary"
                    value="isPrimary"
                    label={formatMessage(messages.isPrimaryLabel)}
                    checked={values.isPrimary}
                    onChange={handleChange}
                  />
                </Field>
                {values.id && (
                  <Field label={formatMessage(messages.statusLabel)}>
                    <Select
                      name="status"
                      isSearchable={false}
                      value={getSelectedOption(statusOptions, values.status)}
                      onChange={({ value }) => {
                        setFieldValue("status", value);
                      }}
                      onBlur={() => {
                        setFieldTouched("status", true);
                      }}
                      options={statusOptions}
                    />
                  </Field>
                )}
              </LayoutSection>
            </div>
          </Body>
          <Body padding="none">
            <div data-id="pendo-tags">
              <LayoutSection
                label={formatMessage(messages.propertyTagsTitle)}
                disableFormPageBlockAdjust
                startExpanded
              >
                <PropertyTags />
              </LayoutSection>
            </div>
          </Body>
        </Panel>
      </Form>
    </div>
  );
};

export default ({
  onSubmit = () => {},
  isLoading,
  initialValues,
  onCancel,
  isEdit = false,
}) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { setErrorNotification } = useErrorNotification();
  const validation = validationSchema(intl);
  const { use2022Nav } = useNewNavigation();

  const title = isEdit ? messages.editFormTitle : messages.title;
  const isInitialValid = isEdit ? { isInitialValid: true } : {};

  return (
    <Formik
      initialValues={R.pipe(
        transformMobileAppToFormikData,
        transformBrowserExtensionToFormikData
      )(R.mergeDeepRight(defaultValues, initialValues))}
      validationSchema={validation}
      onSubmit={R.pipe(
        transformMobileAppToAPIData,
        transformBrowserExtensionToAPIData,
        onSubmit
      )}
      {...isInitialValid}
    >
      {(formikProps) => {
        const { handleSubmit, validateForm } = formikProps;

        const onFormSubmit = async () => {
          const errors = await validateForm();
          if (!R.isEmpty(errors)) {
            const errorMessages = formatErrorMessages(errors, (key) =>
              formatMessage(messages[key])
            );
            setErrorNotification(errorMessages);
          }
          handleSubmit();
        };

        const topNavProps = {
          title: formatMessage(title),
          contentProps: { gap: "small" },
          actions: {
            submit: {
              content: formatMessage(messages.submit),
              onClick: onFormSubmit,
            },
            cancel: {
              content: formatMessage(messages.cancel),
              onClick: onCancel,
            },
          },
        };

        return (
          <FormikContext.Provider value={formikProps}>
            <ErrorNotification />
            {use2022Nav ? (
              <FormModalWithOptions
                isOpen
                onRequestClose={onCancel}
                onSubmit={onFormSubmit}
                submitText={formatMessage(messages.submit)}
                title={formatMessage(title)}
              >
                <PromotionalPropertyForm />
              </FormModalWithOptions>
            ) : (
              <TopNavLayout {...topNavProps}>
                <PromotionalPropertyForm />
              </TopNavLayout>
            )}
          </FormikContext.Provider>
        );
      }}
    </Formik>
  );
};
