import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

export const GET_PROMO_PROPERTIES = gql`
  query getPromoProperties($publisherId: ID!) {
    promotionalProperties(publisherId: $publisherId, limit: 100) {
      resultList {
        id
        isPrimary
        propertyTypeDetails {
          type
          ... on PromotionalPropertySocialMediaDetails {
            socialMediaHandle
            socialMediaPlatform
          }
          ... on PromotionalPropertyWebsiteDetails {
            websiteUrl
          }
        }
      }
    }
  }
`;

export const usePromotionalProperties = (publisherId) => {
  const { data: cjProperties, error, loading } = useQuery(
    GET_PROMO_PROPERTIES,
    {
      variables: { publisherId },
    }
  );

  const properties = useMemo(() => {
    const typesWeCareAbout = ["SOCIAL_MEDIA", "WEBSITE"];
    return cjProperties?.promotionalProperties?.resultList
      .filter((p) => typesWeCareAbout.includes(p.propertyTypeDetails.type))
      .map((p) => {
        const type =
          p.propertyTypeDetails.type === "SOCIAL_MEDIA" ? "Social" : "Website";

        return {
          id: p.id,
          type,
          socialMediaPlatform: p.propertyTypeDetails.socialMediaPlatform?.toLowerCase(),
          socialMediaHandle: p.propertyTypeDetails.socialMediaHandle,
          url: p.propertyTypeDetails.websiteUrl,
          isPrimary: p.isPrimary,
          uuid: uuidv4(),
        };
      });
  }, [cjProperties]);

  return { data: properties, error, loading };
};
