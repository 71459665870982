export const resendEmail = (emailAddress, token) => {
  return fetch(
    `${process.env.REACT_APP_MEMBER_URL}/affapi/publishers/application-emails`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ resendToken: token }),
    }
  );
};
