import { defineMessages } from "react-intl";

export default defineMessages({
  connect: {
    id: "accounts.AuthenticationLink.connect",
    defaultMessage: "Connect",
  },
  reconnect: {
    id: "accounts.AuthenticationLink.reconnect",
    defaultMessage: "Reconnect",
  },
  connected: {
    id: "accounts.AuthenticationLink.connected",
    defaultMessage: "Connected",
  },
  authenticatedAt: {
    id: "accounts.AuthenticationLink.authenticatedAt",
    defaultMessage: "Authenticated at {date}",
  },
  handleNotFound: {
    id: "accounts.AuthenticationLink.handleNotFound",
    defaultMessage:
      "Your property was not found. Check that you have entered it correctly and try again.",
  },
  reconnectPopover: {
    id: "accounts.AuthenticationLink.reconnectPopover",
    defaultMessage:
      "Your property is no longer connected due to invalid authentication. Please re-authneticate by clicking the 'Reconnect' link.",
  },
});
