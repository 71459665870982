import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorBoundary } from "components/ErrorBoundary";
import {
  ApolloProvider,
  ErrorNotificationProvider,
  NotificationProvider,
  AuthenticationProvider,
  IntlProvider,
  UserProvider,
  NavigationProvider,
} from "../context";
import { HistoryProvider } from "context/HistoryContext";
import { useHistory } from "react-router-dom";
import { useUserApi } from "hooks";
import cookies from "js-cookie";

export const LegacyViewProvider = ({ children }) => {
  const queryClient = new QueryClient();
  const history = useHistory();
  const user = useUserApi();

  const companyId = parseInt(cookies.get("jsCompanyId"));
  const currentCompany = user?.companies?.find((c) => c.id === companyId);
  const preferredLanguage = currentCompany?.preferredLanguage || "en";

  return (
    <NavigationProvider use2022Nav={false}>
      <QueryClientProvider client={queryClient}>
        <ApolloProvider>
          <UserProvider user={user}>
            <IntlProvider locale={preferredLanguage}>
              <AuthenticationProvider>
                <HistoryProvider history={history}>
                  <NotificationProvider>
                    <ErrorNotificationProvider>
                      <ErrorBoundary>{children}</ErrorBoundary>
                    </ErrorNotificationProvider>
                  </NotificationProvider>
                </HistoryProvider>
              </AuthenticationProvider>
            </IntlProvider>
          </UserProvider>
        </ApolloProvider>
      </QueryClientProvider>
    </NavigationProvider>
  );
};
