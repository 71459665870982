import { useState, useCallback } from "react";

export const useLocalStorageState = (key) => {
  const [getItem, setItem] = useState(window.localStorage.getItem(key));

  const setLocalStorageItem = useCallback(
    (value) => {
      window.localStorage.setItem(key, value);
      setItem(value);
    },
    [key, setItem]
  );

  return [getItem, setLocalStorageItem];
};
