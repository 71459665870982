import { Td, Tr } from "@cjdev-internal/visual-stack-x/Table.js";
import { AuthenticateModal } from "views/ViewPromotionalProperties/PromotionalPropertyRow/AuthenticateModal/index.js";
import AuthenticationLink from "views/ViewPromotionalProperties/PromotionalPropertyRow/AuthenticationLink/index.js";
import { PROMOTIONAL_PROPERTIES } from "paths.js";
import * as R from "ramda";
import React from "react";
import { useModal } from "@cjdev-internal/visual-stack-x/Modal.js";
import { ActionsButton } from "./ActionsButton/index.js";
import DateText from "../../../components/DateText/index.js";
import Tag from "../../../components/Tag/index.js";
import messages from "./messages.js";
import "./styles.css";
import { useFeatures, useIntl, useHistory } from "hooks/index.js";

export default ({ promotionalProperty, showEdit }) => {
  const { navigateTo } = useHistory();
  const { formatMessage } = useIntl();
  const { hasFeature } = useFeatures();
  const hasUserCanAuthenticateInCiq = hasFeature(
    "USER_CAN_AUTHENTICATE_IN_CIQ"
  );
  const [mount, openModal, closeModal] = useModal();

  const openManageAuthModal = () =>
    openModal(
      <AuthenticateModal
        closeModal={closeModal}
        property={promotionalProperty}
      />
    );

  const propertyDetailsType = promotionalProperty.propertyTypeDetails.type;
  const primaryMethodName = R.find(
    (promotionalModel) => promotionalModel.isPrimary,
    promotionalProperty.promotionalModels
  )?.type;

  return (
    <Tr key={promotionalProperty.id} data-testid={promotionalProperty.id}>
      <Td>
        {promotionalProperty.name}
        {promotionalProperty.isPrimary && (
          <Tag>{formatMessage(messages.primary)}</Tag>
        )}
      </Td>
      <Td>{promotionalProperty.id}</Td>
      <Td>
        {formatMessage(messages[promotionalProperty.propertyTypeDetails.type])}
      </Td>
      {hasUserCanAuthenticateInCiq && (
        <Td className="authentication-column">
          {(propertyDetailsType === "SOCIAL_MEDIA" ||
            propertyDetailsType === "WEBSITE") && (
            <AuthenticationLink promotionalProperty={promotionalProperty} />
          )}
        </Td>
      )}
      <Td>
        {primaryMethodName ? formatMessage(messages[primaryMethodName]) : null}
      </Td>
      <Td>{promotionalProperty.promotionalModels.length}</Td>
      <Td>
        <DateText date={promotionalProperty.updatedAt} />
      </Td>
      <Td>{formatMessage(messages[`STATUS_${promotionalProperty.status}`])}</Td>
      {showEdit ? (
        <Td>
          <ActionsButton
            actions={[
              {
                label: formatMessage(messages.edit),
                onClick: () =>
                  navigateTo(
                    `${PROMOTIONAL_PROPERTIES}/edit/${promotionalProperty.id}`
                  ),
              },
              {
                label: formatMessage(messages.clone),
                onClick: () =>
                  navigateTo(
                    `${PROMOTIONAL_PROPERTIES}/copy/${promotionalProperty.id}`
                  ),
              },
              (propertyDetailsType === "SOCIAL_MEDIA" ||
                propertyDetailsType === "WEBSITE") &&
              hasUserCanAuthenticateInCiq
                ? {
                    label: formatMessage(messages.manageAuthentication),
                    onClick: openManageAuthModal,
                  }
                : undefined,
            ]}
            data-testid={`actions-dropdown-${promotionalProperty.id}`}
          ></ActionsButton>
          {mount}
        </Td>
      ) : null}
    </Tr>
  );
};
