import { defineMessages } from "react-intl";

export default defineMessages({
  missingToken: {
    id: "accounts.InfluencerSigup.missingToken",
    defaultMessage: "Missing token",
  },
  missingAdvertiserId: {
    id: "accounts.InfluencerSigup.missingAdvertiserId",
    defaultMessage: "Missing advertiserId",
  },
});
