import { defineMessages } from "react-intl";

export default defineMessages({
  letsAddProperties: {
    id: "accounts.InfluencerWizard.PropertyImport.letsAddProperties",
    defaultMessage: "Let's add some of your promotional accounts",
  },
  showOff: {
    id: "accounts.InfluencerWizard.PropertyImport.showOff",
    defaultMessage:
      "Your promotional properties are the social accounts and websites/blogs where you will be posting and generating content. Here is your chance to show the network and community what a great brand you are building for yourself.",
  },
  addProperties: {
    id: "accounts.InfluencerWizard.PropertyImport.addProperties",
    defaultMessage: "Add your promotional properties",
  },
  atLeastOneAccount: {
    id: "accounts.InfluencerWizard.PropertyImport.atLeastOneAccount",
    defaultMessage: "You must add at least 1 social account.",
  },
  changeLater: {
    id: "accounts.InfluencerWizard.PropertyImport.changeLater",
    defaultMessage: "You can always change or add more properties later.",
  },
  submit: {
    id: "accounts.InfluencerWizard.PropertyImport.submit",
    defaultMessage: "Submit",
  },
  socialAccounts: {
    id: "accounts.InfluencerWizard.PropertyImport.socialAccounts",
    defaultMessage: "Social Accounts",
  },
  blogOrWebsite: {
    id: "accounts.InfluencerWizard.PropertyImport.blogOrWebsite",
    defaultMessage: "Blog / Website",
  },
  addBlogOrWebsite: {
    id: "accounts.InfluencerWizard.PropertyImport.addBlogOrWebsite",
    defaultMessage: "Add Blog / Website",
  },
  checkUrlError: {
    id: "accounts.InfluencerWizard.PropertyImport.checkUrlError",
    defaultMessage:
      "{handle} user handle/URL cannot be found. You can continue and save your social account however no social metrics will show in your profile for this account.",
  },
  handleFound: {
    id: "accounts.InfluencerWizard.PropertyImport.handleFound",
    defaultMessage: "User handle/URL found.",
  },
  accountsSaveError: {
    id: "accounts.InfluencerWizard.PropertyImport.accountsSaveError",
    defaultMessage: "Accounts could not be saved because of an error.",
  },
  step1: {
    id: "accounts.InfluencerWizard.PropertyImport.step1",
    defaultMessage: "Step 1/3",
  },
  socialAccountBlank: {
    id: "accounts.InfluencerWizard.PropertyImport.socialAccountBlank",
    defaultMessage: "Social Account cannot be blank",
  },
  invalidUrl: {
    id: "accounts.InfluencerWizard.PropertyImport.invalidUrl",
    defaultMessage: "Invalid URL.",
  },
  blankUrl: {
    id: "accounts.InfluencerWizard.PropertyImport.blankUrl",
    defaultMessage: "URL cannot be blank.",
  },
  remove: {
    id: "accounts.InfluencerWizard.PropertyImport.remove",
    defaultMessage: "Remove",
  },
});
