import React from "react";

// date is an ISO string or a Date object
export default ({ date, timeZone }) => {
  const dateObject = typeof date === "string" ? new Date(date) : date;
  return (
    <div>
      {dateObject &&
        dateObject.toLocaleString(navigator.language, {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          timeZone: timeZone,
        })}
    </div>
  );
};
