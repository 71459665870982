import { Stack } from "@cjdev-internal/visual-stack-x/Stack";
import { InfluencerSignupLayout } from "components/InfluencerSignupLayout";
import { getQueryParam } from "utils/util";
import { InfluencerSignupForm } from "./InfluencerSignupForm";
import { InfluencerSignupHeader } from "components/InfluencerSignupHeader";
import messages from "./messages";
import { useIntl } from "react-intl";

export const InfluencerSignup = ({ language, onLanguageChange }) => {
  const { formatMessage } = useIntl();
  const token = getQueryParam("token");
  const advertiserId = getQueryParam("advertiserId");
  return (
    <InfluencerSignupLayout advertiserId={advertiserId} influencerToken={token}>
      <Stack gap="2xl">
        <InfluencerSignupHeader
          title={formatMessage(messages.start)}
          subtitle={formatMessage(messages.easy)}
        />
        <InfluencerSignupForm
          token={token}
          advertiserId={advertiserId}
          language={language}
          onLanguageChange={onLanguageChange}
        />
      </Stack>
    </InfluencerSignupLayout>
  );
};
