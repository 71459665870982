/*
 * Alternate version of the hook used on the Manage Promotional Properties page.  This version allows multiple requests to be in flight at the same time.
 */
import { gql, useApolloClient } from "@apollo/client";

export const FOUND = "FOUND";
export const NOT_FOUND = "NOT_FOUND";
export const ERROR = "ERROR";

export const useCheckSocialAccount = () => {
  const client = useApolloClient();

  return (platform, handle) => {
    return client
      .query({
        query: SOCIAL_ACCOUNT,
        variables: {
          socialPlatform: platform.toUpperCase(),
          socialAccount: handle,
        },
      })
      .then((data) => (data?.data?.socialAccount?.id ? FOUND : NOT_FOUND))
      .catch((e) => ERROR);
  };
};

const SOCIAL_ACCOUNT = gql`
  query socialAccount(
    $socialPlatform: SocialPlatform!
    $socialAccount: String!
  ) {
    socialAccount(
      socialPlatform: $socialPlatform
      socialAccount: $socialAccount
    ) {
      id
    }
  }
`;
