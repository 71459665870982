import { useQuery } from "@tanstack/react-query";
import { fetchPublisherProfile } from "api";

export const usePublisherProfile = (publisherId, enabled = true) => {
  const query = useQuery({
    queryKey: ["fetch publisher profile", publisherId],
    queryFn: () => fetchPublisherProfile(publisherId),
    enabled,
    refetchOnWindowFocus: false,
  });
  // work around bad design decision in React Query 4
  return {
    ...query,
    isLoading: query.isLoading && enabled,
  };
};
