import { Field } from "@cjdev-internal/visual-stack-x/legacy/Form";
import React from "react";

import messages from "./messages.js";
import "./styles.css";
import BrowserExtensionPropertyType from "./BrowserExtensionPropertyType/index.js";
import EmailPropertyType from "./EmailPropertyType/index.js";
import MobileAppPropertyType from "./MobileAppPropertyType/index.js";
import OtherPropertyType from "./OtherPropertyType/index.js";
import PaidDisplayAdsPropertyType from "./PaidDisplayAdsPropertyType/index.js";
import PaidSearchEngineMarketingPropertyType from "./PaidSearchEngineMarketingPropertyType/index.js";
import ServicesAndToolsPropertyType from "./ServicesAndToolsPropertyType/index.js";
import WebsitePropertyType from "./WebsitePropertyType/index.js";
import { useIntl } from "hooks/index.js";

import SocialPropertyTypeWithCiq from "./SocialPropertyTypeWithCiq/index.js";

export default () => {
  const { formatMessage } = useIntl();

  return (
    <div className="property-types field-array">
      <Field
        required
        label={formatMessage(messages.propertyTypeLabel)}
        help={formatMessage(messages.propertyTypeHelp)}
      />
      <WebsitePropertyType />
      <SocialPropertyTypeWithCiq />
      <EmailPropertyType />
      <MobileAppPropertyType />
      <BrowserExtensionPropertyType />
      <ServicesAndToolsPropertyType />
      <PaidDisplayAdsPropertyType />
      <PaidSearchEngineMarketingPropertyType />
      <OtherPropertyType />
    </div>
  );
};
